import { Tabs, Input, Select, Row, Col, AutoComplete, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ADS_CHANNEL,
  ADS_FORMAT,
  ADS_LOCATION,
  ADS_SEARCH_KEYWORD,
  ADS_LOADER,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  SPINNER,
  ADS_NO_DATA,
  ADS_DATA,
  ADS_HOME_STATE,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  CLEAR_STATE,
  CLEAR_BRANDS_FLITER,
  IS_META_STATUS,
  IS_GOOGLE_STATUS,
  BRAND_CHOSEN,
  API_HITS_COUNT,
  INPUT_VALUES,
  ACTIVE_KEY,
  TRIGGER_SEARCH,
  CHECKED_VALUES,
  ALL_BRANDS_LIST,
  BRAND_TAG_FOR_ALL,
  GOOGLE_BRANDS_LIST,
  META_BRANDS_LIST,
  LIVE_SEARCH,
  BRAND_TAG_FOR_META,
  BRAND_TAG_FOR_GOOGLE,
  IN_ACTIVE_ADS_COUNT,
  CLEAR_BRANDS_TAG_FLAG,
  MIX_GOOGLE_PAGINATION,
  MIX_META_PAGINATION,
  ACTIVE_ADS_COUNT,
} from '@utils/constants'
import axios from 'axios'
import Text from '@components/Text/text'
import { SearchOutlined } from '@ant-design/icons'
import { notify } from '@utils/notify'
import { axiosInstance } from '@services/requests'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import GoogleIcon from '@images/DiscoverAds/SearchBar/GoogleIcon.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CreateYourAccount from '@components/CreateYourAccount'
import AllIcon from '@images/DiscoverAds/SearchBar/AllIcon.png'
import { googleCountries, metaCountries } from '../../../data/discoverData'
import styles from './SearchBar.module.css'

const { TabPane } = Tabs
const { Option } = Select

const SearchBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const allOptionPermanent = useSelector(state => state?.discoverAds?.allBrandsList)
  const googleOptionPermanent = useSelector(state => state?.discoverAds?.googleBrandsList)
  const metaOptionPermanent = useSelector(state => state?.discoverAds?.metaBrandsList)
  const brandsTagForAllOrignal = useSelector(state => state?.discoverAds?.homePageBrands)
  const brandsTagForGoogleOriginal = useSelector(state => state?.discoverAds?.brandsTagForGoogle)
  const brandsTagForMetaOriginal = useSelector(state => state?.discoverAds?.brandsTagForMeta)
  const apiHitsCounter = useSelector(state => state?.UnAuthDiscoverAds?.apiHitsCounter)
  const searchKeyword = useSelector(state => state?.discoverAds?.searchKeyword)
  const triggerSearch = useSelector(state => state?.discoverAds?.triggerSeach)
  const activeKey = useSelector(state => state?.discoverAds?.activeKey)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const inputValues = useSelector(state => state?.discoverAds?.inputValues)
  const location = useSelector(state => state?.discoverAds?.location)
  const adsFormats = useSelector(state => state?.discoverAds?.formats)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const moreDataLoader = useSelector(state => state?.discoverAds?.moreDataLoader)
  const [currentPath, setCurrentPath] = useState('')
  const [metaOptions, setMetaOptions] = useState([])
  const [googleOptions, setGoogleOptions] = useState([])
  const [allOptions, setAllOptions] = useState([])
  const [signUpModal, setSignUpModal] = useState(false)

  const getIP = async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      return data
    } catch (e) {
      return null
    }
  }

  const handleOpenModal = () => {
    setSignUpModal(true)
  }

  const handleCloseModal = () => {
    if (currentPath !== '/discover-ads-landing-page') {
      dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
      dispatch({ type: INPUT_VALUES, payload: '' })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
      dispatch({ type: ADS_FORMAT, payload: '' })
      dispatch({ type: ADS_LOCATION, payload: '' })
    }
    setSignUpModal(false)
  }
  const handleSignUpModal = () => {
    setSignUpModal(true)
  }

  const handleKeywordSearchForMeta = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter options based on input
    const filteredOptions = metaOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))
    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
    setMetaOptions(filteredOptions)
  }

  const handleInputChangeForMeta = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    const filteredOptions = metaOptionPermanent
      .filter(suggestion => suggestion.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))

    const options = []

    // Conditionally add "Search this exact phrase" option
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <Text
              style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}
              onClick={() => dispatch({ type: BRAND_CHOSEN, payload: false })}
            >
              Search this exact phrase
            </Text>
          </div>
        ),
        value: `${value}`,
      })
    }

    // Conditionally add the divider if there are advertiser matches
    if (filteredOptions.length > 0) {
      options.push({
        label: <Divider style={{ cursor: 'pointer', margin: '5px 0' }} />,
        value: 'divider',
        disabled: true,
      })
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1
            style={{
              fontSize: '18px',
              color: '#f16434',
              fontWeight: 'bold',
              margin: '4px 0',
              cursor: 'text',
            }}
          >
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setMetaOptions(options)
  }

  const handleKeywordSearchForAll = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
    const filteredOptions = allOptionPermanent
      .filter(suggestion => suggestion?.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.value,
        googleId: suggestion?.googleId,
        metaId: suggestion?.metaId,
      }))
    setAllOptions(filteredOptions)
    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
  }
  const handleInputChangeForAll = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter Google brand suggestions based on the input
    const filteredOptions = allOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        googleId: suggestion?.googleId,
        metaId: suggestion?.metaId,
      }))

    const options = []

    // Conditionally add "Search this exact phrase" option
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <Text style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}>
              Search this exact phrase
            </Text>
          </div>
        ),
        value: `${value}`,
      })

      // Conditionally add the divider if there are advertiser matches
      if (filteredOptions.length > 0) {
        options.push({
          label: <Divider style={{ cursor: 'pointer', margin: '5px 0' }} />, // Added cursor: 'pointer' for pointer style
          value: 'divider',
          disabled: true,
        })
      }
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1
            style={{
              fontSize: '18px',
              color: '#f16434',
              fontWeight: 'bold',
              margin: '4px 0',
              cursor: 'text',
            }}
          >
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setAllOptions(options)
  }

  const handleKeywordSearchForGoogle = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
    // Filter options based on input
    const filteredOptions = googleOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.value,
        id: suggestion?.id,
      }))

    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
    setGoogleOptions(filteredOptions)
  }

  const handleInputChangeForGoogle = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter Google brand suggestions based on the input
    const filteredOptions = googleOptionPermanent
      .filter(suggestion => suggestion.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))

    const options = []

    // Conditionally add "Search this exact phrase" option
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <div style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}>
              Search this exact phrase
            </div>
          </div>
        ),
        value: `${value}`,
      })

      // Conditionally add the divider only if there are advertiser matches
      if (filteredOptions.length > 0) {
        options.push({
          label: <Divider style={{ margin: '5px 0' }} />, // Adjusted space around the divider
          value: 'divider',
          disabled: true,
        })
      }
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1 style={{ fontSize: '18px', color: '#f16434', fontWeight: 'bold', margin: '4px 0' }}>
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setGoogleOptions(options)
  }

  const handleSelectFormat = selectedValues => {
    dispatch({ type: ADS_FORMAT, payload: selectedValues })

    dispatch({
      type: ADS_FORMAT,
      payload: selectedValues.includes('All') ? 'All' : selectedValues,
    })
  }
  const handleSelectChannels = async selectedValues => {
    if (currentPath !== '/') {
      setSignUpModal(true)
      if (selectedValues === '1') {
        dispatch({ type: ADS_CHANNEL, payload: 'Google' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      } else if (selectedValues === '2') {
        dispatch({ type: ADS_CHANNEL, payload: 'Meta' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      } else if (selectedValues === '3') {
        dispatch({ type: ADS_CHANNEL, payload: 'All' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      }
    } else {
      dispatch({ type: ADS_NO_DATA, payload: false })
      if (selectedValues === '1') {
        dispatch({ type: ADS_CHANNEL, payload: 'Google' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      } else if (selectedValues === '2') {
        dispatch({ type: ADS_CHANNEL, payload: 'Meta' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      } else if (selectedValues === '3') {
        dispatch({ type: ADS_CHANNEL, payload: 'All' })
        dispatch({ type: BRAND_CHOSEN, payload: false })
      }
      if (searchKeyword && currentPath.pathname !== '/') {
        dispatch({ type: TRIGGER_SEARCH, payload: true })
      }
      const newMetaOptions = metaOptionPermanent?.map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))

      const newGoogleOptions = googleOptionPermanent?.map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))
      const newAllOptions = allOptionPermanent?.map(suggestion => ({
        value: suggestion?.value,
        googleId: suggestion?.googleId,
        metaId: suggestion?.metaId,
      }))

      setMetaOptions(newMetaOptions)
      setGoogleOptions(newGoogleOptions)
      setAllOptions(newAllOptions)
      dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
      dispatch({ type: ADS_FORMAT, payload: '' })
      dispatch({ type: ADS_LOCATION, payload: '' })
      dispatch({ type: ADS_DATA, payload: [] })
      dispatch({ type: ADS_HOME_STATE, payload: true })
    }
  }

  const onChangeLocation = value => {
    dispatch({ type: ADS_LOCATION, payload: value })
  }
  const clearState = () => {
    dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
    dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
  }
  const handleAdsApi = async () => {
    if (apiHitsCounter < 1) {
      dispatch({ type: CHECKED_VALUES, payload: ['Active', 'Inactive'] })
      dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: 0 })
      dispatch({ type: ACTIVE_ADS_COUNT, payload: 0 })
      dispatch({ type: LIVE_SEARCH, payload: false })
      dispatch({
        type: CLEAR_STATE,
        payload: [],
      })
      dispatch({ type: SPINNER, payload: true })
      dispatch({ type: ADS_LOADER, payload: true })
      const brandStatusData = {
        brand_name: searchKeyword,
      }
      const brandStatus = await axiosInstance.post('save/brand-status/', brandStatusData)
      dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
      dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
      const data = await getIP()
      try {
        let response = []

        if (selectedChannel === 'Meta') {
          response = await axiosInstance.get(
            `ads-preview/competitors-prod-v1/?search_term=${searchKeyword}&num=${20}&creative_format=${
              adsFormats.length > 0 ? adsFormats.toString().toLowerCase().replace(/s$/, '') : 'all'
            }&channels=[${selectedChannel}]&ad_reached_countries=["${
              location === '0000' ? '' : location
              // eslint-disable-next-line
            }"]&is_brand=${brandStatus?.data?.page_id ? true : false}&advertiser_id=${
              brandStatus?.data?.page_id
            }&ip_address=${data?.ip}`
          )
          dispatch({ type: IS_META_STATUS, payload: response?.data?.is_meta })
          dispatch({ type: API_HITS_COUNT, payload: response?.data?.result?.facebook_ads?.count })
          if (response?.data?.is_meta) {
            dispatch({ type: LIVE_SEARCH, payload: true })
          }
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
          history.push('/discover-ads-landing-page')
        } else if (selectedChannel === 'Google') {
          let paginationCounnt = 30
          if (adsFormats === 'Videos' || adsFormats === 'all') {
            paginationCounnt = 7
          }
          response = await axiosInstance.get(
            `ads-preview/competitors-prod-v1/?search_term=${searchKeyword}&num=${paginationCounnt}&creative_format=${
              adsFormats === 'All' ? '' : adsFormats.toString().toLowerCase().replace(/s$/, '')
            }&channels=[${selectedChannel}]&region=${
              location === '0000' ? '' : location
              // eslint-disable-next-line
            }&is_brand=${brandStatus?.data?.google_advertiser_id ? true : false}&page_id=${
              brandStatus?.data?.google_advertiser_id
            }&ip_address=${data?.ip}`
          )
          dispatch({ type: IS_GOOGLE_STATUS, payload: response?.data?.is_facebook })
          dispatch({ type: API_HITS_COUNT, payload: response?.data?.result?.facebook_ads?.count })
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
          if (response?.data?.is_facebook) {
            dispatch({ type: LIVE_SEARCH, payload: true })
          }
          history.push('/discover-ads-landing-page')
        } else if (selectedChannel === 'All') {
          response = await axiosInstance.get(
            `ads-preview/competitors-prod-v1/?channels=[All]&search_term=${searchKeyword}&is_brand=${
              // eslint-disable-next-line
              brandStatus?.data?.google_advertiser_id || brandStatus?.data?.page_id ? true : false
            }&num=${20}&page_id=${brandStatus?.data?.google_advertiser_id}&advertiser_id=${
              brandStatus.data.page_id
            }&ip_address=${data?.ip}&ad_reached_countries=["${
              location === '0000' ? '' : location
            }"]`
          )
          dispatch({ type: API_HITS_COUNT, payload: response?.data?.result?.facebook_ads?.count })
          history.push('/discover-ads-landing-page')
        }
        dispatch({ type: ADS_LOADER, payload: false })
        const formattedData = response?.data?.result?.facebook_ads?.data
        if (response?.data?.is_meta_or_google) {
          dispatch({ type: LIVE_SEARCH, payload: true })
          const googleResults = response?.data?.google_ads?.facebook_ads?.data || []
          const metaResults = response?.data?.meta_ads?.facebook_ads?.data || []
          const maxLength = Math.max(googleResults.length, metaResults.length)
          const mixData = []

          for (let i = 0; i < maxLength; i += 1) {
            if (i < googleResults?.length) {
              mixData.push(googleResults[i])
            }
            if (i < metaResults.length) {
              mixData.push(metaResults[i])
            }
          }
          if (mixData.length > 0) {
            dispatch({ type: ADS_NO_DATA, payload: false })
            dispatch({
              type: ADS_DATA,
              payload: mixData,
            })
            if (
              response?.data?.meta_ads?.facebook_ads?.paging?.next &&
              mixData.length > 0 &&
              selectedChannel === 'All'
            ) {
              dispatch({
                type: MIX_META_PAGINATION,
                payload: response?.data?.meta_ads?.facebook_ads?.paging?.next,
              })
              dispatch({
                type: HAS_MORE,
                payload: true,
              })
            }
            if (
              response?.data?.google_ads?.facebook_ads?.paging?.next &&
              mixData.length > 0 &&
              selectedChannel === 'All'
            ) {
              dispatch({
                type: MIX_GOOGLE_PAGINATION,
                payload: response?.data?.google_ads?.facebook_ads?.paging?.next,
              })
              if (
                response?.data?.google_ads?.facebook_ads?.paging?.next ||
                response?.data?.meta_ads?.facebook_ads?.paging?.next
              )
                dispatch({
                  type: HAS_MORE,
                  payload: true,
                })
              else {
                dispatch({
                  type: HAS_MORE,
                  payload: false,
                })
              }
            }
          }
          if (mixData?.length === 0) {
            dispatch({ type: ADS_NO_DATA, payload: true })
          }
        } else {
          if (formattedData.length > 0) {
            dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
            dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
            dispatch({
              type: ADS_DATA,
              payload: formattedData,
            })
            dispatch({ type: ADS_NO_DATA, payload: false })
          }
          if (
            response?.data?.result?.facebook_ads?.paging?.next &&
            formattedData.length > 0 &&
            selectedChannel === 'Meta'
          ) {
            dispatch({
              type: ADS_PAGINATION,
              payload: response?.data?.result?.facebook_ads?.paging?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          }
          if (
            response?.data?.result?.facebook_ads?.paging?.next &&
            formattedData.length > 0 &&
            selectedChannel === 'All'
          ) {
            dispatch({
              type: ADS_PAGINATION,
              payload: response?.data?.result?.facebook_ads?.paging?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          }
          if (
            response?.data?.result?.facebook_ads?.serpapi_pagination?.next &&
            formattedData.length > 0 &&
            selectedChannel === 'Google'
          ) {
            dispatch({
              type: ADS_PAGINATION,
              payload: response?.data?.result?.facebook_ads?.serpapi_pagination?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          } else if (
            response?.data?.result?.facebook_ads?.paging?.next &&
            formattedData.length > 0 &&
            selectedChannel === 'Google'
          ) {
            dispatch({
              type: ADS_PAGINATION,
              payload: response?.data?.result?.facebook_ads?.paging?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          }
          if (formattedData?.length === 0) {
            dispatch({ type: ADS_NO_DATA, payload: true })
          }
        }

        dispatch({ type: SPINNER, payload: false })
      } catch (error) {
        if (
          error.response.status === 429 &&
          error.response.data.msg === 'Daily search limit exceeded.'
        ) {
          setSignUpModal(true)
          dispatch({ type: TRIGGER_SEARCH, payload: true })
        }

        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
    }
  }

  const handleSearchButton = () => {
    clearState()
    if (inputValues.trim() !== '') {
      dispatch({ type: CHECKED_VALUES, payload: ['Active', 'Inactive'] })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: searchKeyword })
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({
        type: HAS_MORE,
        payload: false,
      })
      if (apiHitsCounter < 1) {
        handleAdsApi()
      } else {
        setSignUpModal(true)
      }
    } else {
      notify('Search Keyword cannot be empty', 'error')
    }
  }

  const getbrands = async () => {
    try {
      const response = await axiosInstance.get('/save/brands-v1/?is_combined=true')
      const newAllOptions = response.data?.all_brands?.map(suggestion => ({
        value: suggestion?.brand_name,
        googleId: suggestion?.google_advertiser_id,
        metaId: suggestion?.meta_page_id,
      }))
      dispatch({ type: ALL_BRANDS_LIST, payload: newAllOptions })
      setAllOptions(newAllOptions)
      const newGoogleOptions = response.data?.google_brands?.map(suggestion => ({
        label: suggestion?.brand_name,
        value: suggestion?.brand_name,
        id: suggestion?.google_advertiser_id,
      }))
      dispatch({ type: GOOGLE_BRANDS_LIST, payload: newGoogleOptions })
      setGoogleOptions(newGoogleOptions)
      const newMetaOptions = response.data?.meta_brands?.map(suggestion => ({
        label: suggestion?.brand_name,
        value: suggestion?.brand_name,
        id: suggestion?.meta_page_id,
      }))
      dispatch({ type: META_BRANDS_LIST, payload: newMetaOptions })
      setMetaOptions(newMetaOptions)
    } catch (error) {
      // console.log('🚀 ~ searchSuggestions ~ error:', error)
    }
  }

  useEffect(() => {
    if (
      allOptionPermanent.length <= 0 ||
      googleOptionPermanent.length <= 0 ||
      metaOptionPermanent.length <= 0
    ) {
      getbrands()
    } else {
      setAllOptions(allOptionPermanent)
      setGoogleOptions(googleOptionPermanent)
      setMetaOptions(metaOptionPermanent)
    }
    // eslint-disable-next-line
  }, [allOptionPermanent, googleOptionPermanent, metaOptionPermanent])

  const handleSignupClick = () => {
    setSignUpModal(true)
  }
  useEffect(() => {
    if (triggerSearch) {
      handleAdsApi()
    }
    // eslint-disable-next-line
  }, [triggerSearch])

  useEffect(() => {
    setCurrentPath(window.location.pathname)
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className={styles.searchBar}>
        <Row align="middle" justify="space-between">
          <Col flex="auto">
            <Tabs
              // activeKey={activeKey}
              defaultActiveKey={activeKey}
              className="search-tabs"
              onChange={key => {
                if (!loading && !moreDataLoader) {
                  // eslint-disable-next-line
                  setSignUpModal(currentPath === '/discover-ads-landing-page' ? true : false)
                  dispatch({ type: ACTIVE_KEY, payload: key })
                  handleSelectChannels(key)
                }
              }}
            >
              <TabPane
                tab={
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={AllIcon} alt="all-icon" height={24} style={{ marginTop: '1px' }} />
                    <span
                      style={{
                        color: activeKey === '3' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '3' ? 'bold' : 'normal', // Set bold for active tab
                        marginTop: '2.85px',
                      }}
                      className={styles.tabTitle}
                    >
                      All Ads
                    </span>
                  </div>
                }
                key="3"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
              <TabPane
                tab={
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={GoogleIcon} alt="google-icon" width={23} height={23} />
                    <span
                      style={{
                        color: activeKey === '1' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '1' ? 'bold' : 'normal', // Set bold for active tab
                      }}
                      className={styles.tabTitle}
                    >
                      Google Ads
                    </span>
                  </div>
                }
                key="1"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
              <TabPane
                tab={
                  <div style={{ display: 'flex' }}>
                    <img src={MetaIcon} alt="meta-icon" height={24} style={{ marginTop: '1px' }} />
                    <span
                      style={{
                        color: activeKey === '2' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '2' ? 'bold' : 'normal', // Set bold for active tab
                        marginTop: '2.85px',
                      }}
                      className={styles.tabTitle}
                    >
                      Meta Ads
                    </span>
                  </div>
                }
                key="2"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
            </Tabs>
          </Col>
        </Row>

        <Row gutter={12} className={styles.parentDiv}>
          <Col span={selectedChannel === 'All' ? 20 : 10} className={styles.inputContainer}>
            {
              // eslint-disable-next-line
              selectedChannel === 'Meta' ? (
                <div className={styles.input} id="MetaAutoComplete">
                  <AutoComplete
                    getPopupContainer={() => document.getElementById('MetaAutoComplete')}
                    options={metaOptions}
                    onSelect={value => {
                      handleKeywordSearchForMeta(value) // Handle the selected value
                      dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                    }}
                    onSearch={value => handleInputChangeForMeta(value)}
                    style={{ width: '100%' }}
                    value={inputValues}
                  >
                    <Input
                      allowClear
                      id="keywordSearchInput"
                      className={styles.inputText}
                      placeholder="Search by keyword, brand, or URL."
                      prefix={
                        <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                      }
                      onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                    />
                  </AutoComplete>
                </div>
              ) : // eslint-disable-next-line
              selectedChannel === 'Google' ? (
                <div className={styles.input} id="GoogleAutoComplete">
                  <AutoComplete
                    getPopupContainer={() => document.getElementById('GoogleAutoComplete')}
                    options={googleOptions}
                    onSelect={value => {
                      handleKeywordSearchForGoogle(value) // Handle the selected value
                      dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                    }}
                    onSearch={value => handleInputChangeForGoogle(value)}
                    style={{ width: '100%' }}
                    value={inputValues}
                  >
                    <Input
                      allowClear
                      id="keywordSearchInput"
                      className={styles.inputText}
                      placeholder="Search by keyword, brand, or URL."
                      prefix={
                        <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                      }
                      onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                    />
                  </AutoComplete>
                </div>
              ) : selectedChannel === 'All' ? (
                <div className={styles.input} id="AllAutoComplete">
                  <AutoComplete
                    getPopupContainer={() => document.getElementById('AllAutoComplete')}
                    options={allOptions}
                    onSelect={value => {
                      handleKeywordSearchForAll(value) // Handle the selected value
                      dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                    }}
                    onSearch={value => handleInputChangeForAll(value)}
                    style={{ width: '100%' }}
                    value={inputValues}
                  >
                    <Input
                      allowClear
                      id="keywordSearchInput"
                      className={styles.inputText}
                      placeholder="Search by keyword, brand, or URL."
                      prefix={
                        <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                      }
                      onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                    />
                  </AutoComplete>
                </div>
              ) : (
                ''
              )
            }
          </Col>
          {selectedChannel === 'All' ? (
            ''
          ) : (
            <>
              <Col span={5} style={{ position: 'relative' }} id="UnAuthInputs">
                <Select
                  disabled={selectedChannel === 'All'}
                  id="location"
                  showSearch
                  value={location || undefined}
                  placeholder={
                    selectedChannel === 'Google' ? 'Location: Anywhere' : 'Language: All'
                  }
                  optionFilterProp="children"
                  onChange={onChangeLocation}
                  dropdownClassName="ads-preview-dropdown"
                  getPopupContainer={() => document.getElementById('UnAuthInputs')}
                  style={{
                    display: 'block',
                    fontSize: 14,
                    textAlign: 'left',
                  }}
                  filterOption={(input, option) => {
                    const label = option.children[1] // Access the label part of the children
                    return label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                >
                  {selectedChannel === 'Meta' &&
                    metaCountries.map(country => (
                      <Option
                        key={country.value}
                        value={country.value}
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          lineHeight: '16px',
                        }}
                      >
                        {country.flag ? (
                          <img
                            src={country.flag}
                            alt="flag"
                            width={24}
                            height={24}
                            style={{ marginRight: 8 }}
                          />
                        ) : (
                          ''
                        )}

                        {country.label}
                      </Option>
                    ))}

                  {selectedChannel === 'Google' &&
                    googleCountries.map(country => (
                      <Option
                        key={country.value}
                        value={country.value}
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          lineHeight: '16px',
                        }}
                      >
                        {country.flag ? (
                          <img
                            src={country.flag}
                            alt="flag"
                            width={24}
                            height={24}
                            style={{ marginRight: 8 }}
                          />
                        ) : (
                          ''
                        )}
                        {country.label}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={5} id="UnAuthInputs">
                {
                  <Select
                    disabled={selectedChannel === 'All'}
                    placeholder="Ad Formats: All formats"
                    style={{
                      display: 'block',
                      fontSize: 14,
                      borderColor: '#E8EBEC !important',
                      textAlign: 'left',
                    }}
                    dropdownClassName="ads-preview-dropdown"
                    onChange={handleSelectFormat}
                    showArrow
                    value={adsFormats || undefined}
                    showSearch={false}
                    getPopupContainer={() => document.getElementById('UnAuthInputs')}
                    optionLabelProp="label"
                  >
                    <Option value="All">
                      <div className={styles.options}>
                        <span className={styles.checkbox}>All</span>
                      </div>
                    </Option>

                    {selectedChannel === 'Google' && (
                      <Option value="Text">
                        <div className={styles.options}>
                          {/* <img src={ImageIcon} alt="imageIcon" /> */}
                          <span className={styles.checkbox}>Text</span>
                        </div>
                      </Option>
                    )}
                    <Option value="Videos">
                      <div className={styles.options}>
                        {/* <img src={ImageIcon} alt="imageIcon" /> */}
                        <span className={styles.checkbox}>Videos</span>
                      </div>
                    </Option>
                    <Option value="Images">
                      <div className={styles.options}>
                        {/* <img src={ImageIcon} alt="imageIcon" /> */}
                        <span className={styles.checkbox}>Images</span>
                      </div>
                    </Option>
                  </Select>
                }
              </Col>
            </>
          )}

          <Col span={selectedChannel === 'All' ? 4 : 4}>
            <button
              type="submit"
              className={styles.searchButton}
              onClick={handleSearchButton}
              disabled={loading || moreDataLoader}
            >
              SEARCH
            </button>
          </Col>
        </Row>
        {currentPath === '/' && (
          <>
            {' '}
            <div style={{ display: 'flex', marginTop: 32 }}>
              <Text size={14}>
                {/* eslint-disable-next-line */}
                <span
                  style={{ cursor: 'pointer', color: '#226CE0', textDecoration: 'underline' }}
                  onClick={handleSignupClick}
                >
                  Signup
                </span>
                <span style={{ color: '#BEBFC0' }}> and get 3 free searches per day!</span>
              </Text>
            </div>
          </>
        )}
      </div>

      {signUpModal ? (
        <CreateYourAccount isModalVisible={signUpModal} onCancel={handleCloseModal} />
      ) : (
        ''
      )}
    </>
  )
}
export default SearchBar
