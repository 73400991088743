import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { axiosInstance } from '@services/requests'
import { notify } from '@utils/notify'
import { updateLocalStorage } from '@utils/helperFunctions'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../../store/actions'

const ResetPasswordVerify = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const userId = queryParams.get('user_id')
  const userToken = queryParams.get('user_token')

  const history = useHistory()
  const handleVerifyPassword = async () => {
    try {
      const response = await axiosInstance.get(
        `/accounts/verify-reset-email/?user_id=${userId}&user_token=${userToken}`
      )
      history.push('/reset-password')
      localStorage.setItem('forget-user-email', response.data.email)
    } catch (error) {
      if (error.response.status === 400) {
        if (error.response.data.non_field_errors[0] === 'Invalid or expired token.') {
          notify('Token expired', 'error')
          history.push('/login')
        }
      } else {
        notify('Something went wrong', 'error')
        history.push('/login')
      }
    }
  }
  useEffect(() => {
    if (userId && userToken) {
      handleVerifyPassword()
    }
    // eslint-disable-next-line
  }, [userId, userToken])
  return <></>
}
export default ResetPasswordVerify
