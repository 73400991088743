import React from 'react'
import DiscoverAdsFeature from './discover-ads-feature'

const DiscoverAds = () => {
  const temp = 0
  return (
    <div style={{ padding: 25 }}>
      <DiscoverAdsFeature />
    </div>
  )
}

export default DiscoverAds
