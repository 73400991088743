import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Text from '@components/Text/text'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { RequestService } from '@services/requests'

import { FOLLOWED_BRANDS_LIST } from '@utils/constants'
import { Spin } from 'antd'
import styled from 'styled-components'
import AdsCard from '../AdsCard'
import styles from './brandsDetail.module.css'

const BrandsDetail = () => {
  const dispatch = useDispatch()
  const [showButton, setShowButton] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [filteredDiscoverData, setFilteredDiscoverData] = useState([])
  const [boardsList, setBoardsList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const history = useHistory()
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const mediaType = useSelector(state => state?.discoverAds?.mediaType)
  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  useEffect(() => {
    if (followedBrandsList.length <= 0) {
      getFollowedBrandsList()
    }
    // eslint-disable-next-line
  }, [followedBrandsList])
  const fetchSpecificBrand = async () => {
    try {
      setIsLoading(true)
      const response = await RequestService.get(
        `save/list-brands/?meta_page_id=${specificBrandDetail?.pageId}&google_page_id=${specificBrandDetail?.googleId}&media_type=${mediaType}`
      )
      const adsData = response.data?.result?.facebook_ads?.data || []
      const uniqueAdss = adsData.reduce((acc, current) => {
        // Skip if both ads_grader_video_url and ads_grader_image_url are null or empty
        if (
          (current.ads_grader_video_url === null || current.ads_grader_video_url === '') &&
          (current.ads_grader_image_url === null || current.ads_grader_image_url === '')
        ) {
          return acc
        }

        // Check if an item with the same ads_grader_video_url or ads_grader_image_url exists in the accumulator
        const duplicateByVideo = acc.find(
          item =>
            item.ads_grader_video_url && item.ads_grader_video_url === current.ads_grader_video_url
        )

        const duplicateByImage =
          !duplicateByVideo &&
          acc.find(
            item =>
              item.ads_grader_image_url &&
              item.ads_grader_image_url === current.ads_grader_image_url
          )

        // Push to accumulator only if no duplicates are found
        if (!duplicateByVideo && !duplicateByImage) {
          acc.push(current)
        }

        return acc
      }, [])
      if (Array.isArray(uniqueAdss)) {
        const formattedData = uniqueAdss.map(ad => {
          const isFollowed = followedBrandsList.some(
            brand =>
              brand.google_advertiser_id === ad.advertiser_id ||
              brand.meta_page_id === ad.additional_info?.page_id
          )

          return {
            ...ad,
            is_followed: isFollowed,
          }
        })
        setFilteredDiscoverData(formattedData)
      }
      setHasMore(Boolean(response.data?.result?.facebook_ads?.paging?.next))
      setNextUrl(response.data?.result?.facebook_ads?.paging?.next || null)
    } catch (error) {
      console.error('Error fetching brand details:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchMoreData = async () => {
    if (!nextUrl) return
    try {
      setIsFetchingMore(true)
      const response = await RequestService.get(nextUrl)
      const newAdsData = response.data?.result?.facebook_ads?.data || []
      const uniqueAdss = newAdsData.reduce((acc, current) => {
        // Skip if both ads_grader_video_url and ads_grader_image_url are null or empty
        if (
          (current.ads_grader_video_url === null || current.ads_grader_video_url === '') &&
          (current.ads_grader_image_url === null || current.ads_grader_image_url === '')
        ) {
          return acc
        }

        // Check if an item with the same ads_grader_video_url or ads_grader_image_url exists in the accumulator
        const duplicateByVideo = acc.find(
          item =>
            item.ads_grader_video_url && item.ads_grader_video_url === current.ads_grader_video_url
        )

        const duplicateByImage =
          !duplicateByVideo &&
          acc.find(
            item =>
              item.ads_grader_image_url &&
              item.ads_grader_image_url === current.ads_grader_image_url
          )

        // Push to accumulator only if no duplicates are found
        if (!duplicateByVideo && !duplicateByImage) {
          acc.push(current)
        }

        return acc
      }, [])

      if (Array.isArray(uniqueAdss)) {
        const formattedData = uniqueAdss.map(ad => {
          const isFollowed = followedBrandsList.some(
            brand =>
              brand.google_advertiser_id === ad.advertiser_id ||
              brand.meta_page_id === ad.additional_info?.page_id
          )

          return {
            ...ad,
            is_followed: isFollowed,
          }
        })
        // setFilteredDiscoverData(formattedData)
        setFilteredDiscoverData(prevData => [...prevData, ...formattedData])
      }
      // setFilteredDiscoverData(formattedData)
      setHasMore(Boolean(response.data?.result?.facebook_ads?.paging?.next))
      setNextUrl(response.data?.result?.facebook_ads?.paging?.next || null)
    } catch (error) {
      console.error('Error fetching more data:', error)
    } finally {
      setIsFetchingMore(false)
    }
  }

  const handleShowModal = index => setSelectedIndex(index)
  const handlePrevious = () =>
    setSelectedIndex(prev => (prev > 0 ? prev - 1 : filteredDiscoverData.length - 1))
  const handleNext = () =>
    setSelectedIndex(prev => (prev < filteredDiscoverData.length - 1 ? prev + 1 : 0))

  useEffect(() => {
    fetchSpecificBrand()
    // eslint-disable-next-line
  }, [specificBrandDetail?.page_id])
  const ButtonContainer = styled.span`
    position: fixed;
    bottom: 32px;
    right: 32px;
    transform: translate(50%, 50%);
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 1;
    background: #f16434;
    border-radius: 5rem;
    // transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
    display: 'flex';
    // &:hover {
    //   opacity: 1;
    // }
  `

  const scrollToTop = () => {
    const targetDiv = document.getElementById('report')

    // if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' })
    setShowButton(false)
    // }
  }

  window.addEventListener('wheel', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  window.addEventListener('keydown', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })
  return (
    <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Text
          size={14}
          onClick={() => history.push('/dashboard/brands')}
          cursor
          className={styles.learningTop}
        >
          Brands
        </Text>
        <div className={styles.slash}> /</div>
        <Text className={styles.catagory}>{specificBrandDetail?.name}</Text>
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: 150 }}>
          <Spin />
        </div>
      ) : (
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={filteredDiscoverData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            isFetchingMore && (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Spin />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
          scrollThreshold="35%"
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 1,
              750: 2,
              900: 2,
              1400: 4,
              1800: 4,
              2200: 6,
              2500: 7,
            }}
          >
            <Masonry columnsCount={3} gutter="20px">
              {filteredDiscoverData.map((item, index) => (
                <AdsCard
                  followBrandsMainFlow
                  // eslint-disable-next-line
                  key={index}
                  adsData={item}
                  onShowModal={() => handleShowModal(index)}
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  selectedData={filteredDiscoverData[selectedIndex]}
                  isFirst={selectedIndex === 0}
                  isLast={selectedIndex === filteredDiscoverData.length - 1}
                  boardsList={boardsList}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      )}
      {filteredDiscoverData.length > 0 && showButton ? (
        <ButtonContainer onClick={scrollToTop}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </ButtonContainer>
      ) : (
        ''
      )}
    </div>
  )
}

export default BrandsDetail
