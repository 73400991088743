import React, { useCallback, useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { baseURL, RequestService } from '@services/requests'
import User from '@services/user'
import useFetch from 'use-http'
import { Spin } from 'antd'
import { fetchMetaUserInfo } from '@services/fetchMetaUserInfo'
import { FacebookFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { passwordRules } from '@utils/validation'
import { SecretService } from '@services/secret'
import { notify } from '@utils/notify'
import axios from 'axios'
import { useRollbar } from '@rollbar/react'
import { updateLocalStorage } from '@utils/helperFunctions'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import MetaEmailLoginModal from '@components/MetaEmailLoginModal'
import { updateUserData, updateSelectedCustomer, updateErrorCustomer } from '../../../store/actions'
import styles from '../../../components/SignInWithGoogleButton/styles.module.scss'

const FacebookSSOForMainFlow = () => {
  const rollbar = useRollbar()
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const [metaEmaiilModal, setMetaEmailModal] = useState(false)
  const [metaAccessToken, setMetaAccessToken] = useState('')
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const handleOpenModal = () => {
    setMetaEmailModal(true)
  }

  const handleCloseModal = () => {
    setMetaEmailModal(false)
  }
  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  // Handle regular email login

  // Generate report for selected customer
  const retrieveReport = async selectedCustomer => {
    getProfile()
    getCountry()
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }
  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved) => {
    try {
      const report = await retrieveReport(selectedCustomer)
      const reportPayload = {
        customerId: String(selectedCustomer.id),
        parentId: selectedCustomer.resource_name?.split('/')[1] || '',
        uuid: report.unique_id,
        currency: selectedCustomer.currency_code || 'USD',
        customerName: selectedCustomer.descriptive_name,
      }
      const reportToken = SecretService.encode(reportPayload)
      localStorage.setItem('selectAccount', JSON.stringify(reportToken))

      if (!isLeadSaved) {
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }
  const handleNoCustomersFallback = async leadForm => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
      } else {
        // eslint-disable-next-line
        if (!leadForm) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }
  const handleMetaLoginSuccess = async token => {
    localStorage.setItem('domain_flow', true)
    if (token?.accessToken) {
      try {
        setMetaAccessToken(token.accessToken)
        setGoogleUserLoading(true)
        const res = await fetchMetaUserInfo(token.accessToken)
        setGoogleUserLoading(false)
        if (res.status === 200) {
          const userData = { ...res.data, isGoogleUser: true }
          localStorage.setItem('profilePic', res?.data?.user?.profile?.profile_picture)
          notify(t('notification.loginSuccess'), 'success')
          updateLocalStorage(userData)
          dispatch(updateUserData(userData))

          // Redirect if report data exists in localStorage
          if (
            localStorage.getItem('copied_report_unique_id') &&
            localStorage.getItem('copied_report_customer_id')
          ) {
            history.push('/report-dashboard')
            return
          }

          const isLeadSaved = userData?.user?.is_lead_save
          localStorage.setItem('leadForm', JSON.stringify(isLeadSaved))

          if (isLeadSaved) {
            history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
          } else {
            // Fetch customers list
            const customersResponse = await (async () => {
              const customersResponseUnSorted = await fetchCustomersList()
              return customersResponseUnSorted?.data?.length > 0
                ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
                : []
            })()

            if (customersResponse?.length > 0) {
              const selectedCustomer = customersResponse[0]
              dispatch(updateSelectedCustomer(selectedCustomer))
              localStorage.setItem('customer', JSON.stringify(selectedCustomer))
              await handleCustomerSuccess(selectedCustomer, isLeadSaved)
            } else {
              await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
            }
          }
        } else if (
          res?.data?.non_field_errors[0] ===
          'Your Facebook account does not have an email associated.'
        ) {
          setMetaEmailModal(true)
        } else {
          notify(t('notification.somethingWentWrong'), 'error')
        }
      } catch (error) {
        console.error('Google login error:', error)
        notify(t('notification.somethingWentWrong'), 'error')
      } finally {
        setGoogleUserLoading(false)
      }
    }
  }

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const reportId = currentUrl.searchParams.get('reportId')
    const customerId = currentUrl.searchParams.get('customerId')

    if (reportId && customerId) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
  }, [])

  return (
    <>
      {googleUserLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <FacebookLogin
          appId="1476244186650338"
          autoLoad={false}
          scope="public_profile,email"
          callback={handleMetaLoginSuccess}
          authType="reauthenticate"
          prompt="select_account"
          render={renderProps => (
            <button type="button" onClick={renderProps.onClick} className={styles.googleBtn}>
              <div className={styles.googleIconWrapper}>
                <img
                  className={styles.googleIcon}
                  src="https://www.freepnglogos.com/uploads/facebook-logo-icon/facebook-logo-icon-file-facebook-icon-svg-wikimedia-commons-4.png"
                  alt="Google Brand"
                />
              </div>
              <p className={styles.btnText}> SIGN IN WITH FACEBOOK</p>
            </button>
          )}
        />
      )}
      {metaEmaiilModal ? (
        <MetaEmailLoginModal
          visible={metaEmaiilModal}
          onClose={handleCloseModal}
          // onLogin={handleLogin}
          token={metaAccessToken}
          mainFlow
        />
      ) : (
        ''
      )}
    </>
  )
}

export default FacebookSSOForMainFlow
