import {
  UN_AUTH_ACTIVE_KEY,
  UN_AUTH_ADS_CHANNEL,
  UN_AUTH_LOCATION,
  UN_AUTH_FORMATS,
  ADS_HOME_STATE,
  API_HITS_COUNT,
} from '@utils/constants'

const initialState = {
  UnAuthActiveKey: '3',
  channels: 'All',
  location: [],
  formats: [],
  homeState: false,
  apiHitsCounter: 0,
}

export const UnAuthDiscoverAdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UN_AUTH_ACTIVE_KEY:
      return {
        ...state,
        UnAuthActiveKey: action.payload,
      }
    case ADS_HOME_STATE:
      return { ...state, homeState: action.payload }
    case API_HITS_COUNT:
      return { ...state, apiHitsCounter: action.payload }
    case UN_AUTH_ADS_CHANNEL:
      return { ...state, channels: action.payload }
    case UN_AUTH_LOCATION:
      return { ...state, location: action.payload }
    case UN_AUTH_FORMATS:
      return { ...state, formats: action.payload }
    default:
      return state
  }
}
