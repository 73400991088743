import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import BoardsFeature from './boards-feature'

const Boards = () => {
  const [temp, setTemp] = useState()
  return (
    <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
      <BoardsFeature />
    </div>
  )
}

export default Boards
