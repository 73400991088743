import React, { useState, useEffect, useCallback } from 'react'
import { Select, Typography, Modal, Divider, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import useFetch from 'use-http'
import { useDispatch } from 'react-redux'
import { updateLocalStorage } from '@utils/helperFunctions'
import { notify } from '@utils/notify'
import { useRollbar } from '@rollbar/react'
import { SecretService } from '@services/secret'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import GoogleLogin from 'react-google-login'
import { baseURL, axiosInstance, RequestService } from '@services/requests'
import axios from 'axios'
import EmailLoginPage from '@components/EmailLogin/loginPage'
import User from '@services/user'
import FacebookSSOForLoginFlow from '@components/Authentication/FacebookSSO'
import { updateErrorCustomer, updateSelectedCustomer, updateUserData } from '../../store/actions'
import styles from './CreateYourAccount.module.css'

const { Option } = Select
const { Title, Text } = Typography

const CreateYourAccount = ({ isModalVisible, onCancel, register }) => {
  const rollbar = useRollbar()
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const dispatch = useDispatch()

  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const [isLoading, setIsLoading] = useState(false)
  const [googleUserLoading, setGoogleUserLoading] = useState(false)

  const { t } = useTranslation()
  const onFailure = e => console.info(e)
  const history = useHistory()
  const { post, response: loginResponse } = useFetch(baseURL)
  const googleClientId = process.env.REACT_APP_GOOGLE_PROJECT_CLIENT_ID
  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  const retrieveReport = async selectedCustomer => {
    getProfile()
    getCountry()
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }

  // Handle successful customer selection and report generation
  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved) => {
    try {
      const report = await retrieveReport(selectedCustomer)
      const reportPayload = {
        customerId: String(selectedCustomer.id),
        parentId: selectedCustomer.resource_name?.split('/')[1] || '',
        uuid: report.unique_id,
        currency: selectedCustomer.currency_code || 'USD',
        customerName: selectedCustomer.descriptive_name,
      }
      const reportToken = SecretService.encode(reportPayload)
      localStorage.setItem('selectAccount', JSON.stringify(reportToken))

      if (!isLeadSaved) {
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }

  // Handle fallback when no customers found
  const handleNoCustomersFallback = async leadFormStatus => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      } else {
        // eslint-disable-next-line
        if (!leadFormStatus) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }

  // Main flow for Google login success
  const handleGoogleLoginSuccess = async payload => {
    setGoogleUserLoading(true)
    try {
      const res = await fetchGoogleUserInfo(payload.code)
      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', userData?.user?.profile?.profile_picture)
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))
        notify(t('notification.loginSuccess'), 'success')

        const customersResponse = await (async () => {
          const customersResponseUnSorted = await fetchCustomersList()
          return customersResponseUnSorted?.data?.length > 0
            ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
            : []
        })()

        if (customersResponse?.length > 0) {
          const selectedCustomer = customersResponse[0]
          dispatch(updateSelectedCustomer(selectedCustomer))
          localStorage.setItem('customer', JSON.stringify(selectedCustomer))

          // Generate a report for the selected customer
          await handleCustomerSuccess(selectedCustomer, userData?.user?.is_lead_save)
        } else {
          await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const reportId = currentUrl.searchParams.get('reportId')
    const customerId = currentUrl.searchParams.get('customerId')

    if (reportId && customerId) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
  }, [])
  return (
    <Modal title={null} open={isModalVisible} footer={null} onCancel={onCancel} centered>
      <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onCancel}
          aria-label="Close"
        >
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div>
      <div style={{ padding: '10px' }}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Title level={4}>Create your FREE Account</Title>
          <div
            style={{
              background: '#ffeac5' /* text-align: center; */,
              height: 50,
              borderRadius: '4rem',
            }}
          >
            <Text style={{ marginTop: 12, display: 'inline-table' }}>
              Signup today & get 3 free searches per day!
            </Text>
          </div>
        </div>

        <>
          {googleUserLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.googleButtonContainer}>
              <GoogleLogin
                clientId={googleClientId}
                render={props => (
                  <button
                    type="button"
                    onClick={props.onClick}
                    // disabled={props.disabled || disableGoogleButton}
                    className={styles.googleButton}
                    href="#continue-with-google"
                  >
                    <div className={styles.googleImageContainer}>
                      <img
                        src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png"
                        width={20}
                        height={20}
                        alt="google"
                      />
                    </div>
                    <span className={styles.googleButtonText}>{t('continueWithGoogle')}</span>
                  </button>
                )}
                buttonText="Login with Google"
                onSuccess={handleGoogleLoginSuccess}
                onFailure={onFailure}
                scope="profile email https://www.googleapis.com/auth/adwords"
                accessType="offline"
                responseType="code"
                prompt="select_account"
              />
            </div>
          )}

          <div style={{ marginTop: 20 }}>
            <FacebookSSOForLoginFlow />
          </div>
          <Divider>OR</Divider>
          <EmailLoginPage register={register} />
        </>
      </div>
    </Modal>
  )
}

export default CreateYourAccount
