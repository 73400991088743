import React from 'react'
import { Modal, Button, Card } from 'antd'
import Text from '@components/Text/text'
import CrownIcon from '@images/Crown.png'
import TickIcon from '@images/Tick.png' // Replace this with the actual file name
import { CloseOutlined } from '@ant-design/icons'
import styles from './UpgradeToPro.module.css'

const UpgradeToPro = ({ isModalVisible, onCancel }) => {
  const tenp = 0
  return (
    <>
      <Modal title={null} open={isModalVisible} footer={null} centered>
        <Card bordered={false}>
          <div className={styles.crossButton}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
                margin: '11.37px',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
              onClick={onCancel}
              aria-label="Close"
            >
              <CloseOutlined className={styles.closeButton} />
            </button>
          </div>
          <div style={{ textAlign: 'left' }}>
            <img src={CrownIcon} alt="crown-icon" />
          </div>
          <Text isBold size={20} style={{ marginTop: '20', textAlign: 'left' }}>
            Unlock Pro!
          </Text>
          <Text
            size={16}
            style={{ width: 272, color: '#939395', lineHeight: '160%', marginTop: 16 }}
          >
            Upgrade to pro and get unlimited features unlocked in one go!
          </Text>
          <div style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
              <img src={TickIcon} alt="tick-icon" width={18} />
              <Text style={{ marginLeft: 10 }}>Unlimited searches</Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
              <img src={TickIcon} alt="tick-icon" width={18} />
              <Text style={{ marginLeft: 10 }}>Save, download and export ads</Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
              <img src={TickIcon} alt="tick-icon" width={18} />
              <Text style={{ marginLeft: 10 }}>Access to all insights</Text>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 27 }}>
            <Button
              type="primary"
              size="large"
              style={{
                background: 'linear-gradient(90deg, #6c63ff, #9177ff)',
                border: 'none',
                color: '#fff',
                borderRadius: '26px',
              }}
            >
              ⭐ Upgrade to Pro
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default UpgradeToPro
