import React, { useState } from 'react'
import { bucketBaseURL } from '@services/requests'
import { useSelector } from 'react-redux'
import styles from './categoryButon.module.css'

const CategoryButton = ({ categryData, onClick, clearState }) => {
  const clearBrandsTagFlag = useSelector(state => state?.discoverAds?.clearBrandsTagFlag)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const moreDataLoader = useSelector(state => state?.discoverAds?.moreDataLoader)
  const isDisabled = loading || moreDataLoader
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <div>
        <button
          onClick={onClick}
          type="button"
          className={styles.button}
          style={{
            display: 'flex',
            position: 'relative',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
          disabled={loading || moreDataLoader}
        >
          {categryData?.page_profile_logo && (
            <img
              src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${categryData?.page_profile_logo}`}
              alt="category-logo"
              width={24}
              height={24}
              style={{ borderRadius: '24px' }}
            />
          )}
          <span
            style={{
              paddingLeft: 8,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {categryData?.brand_name}
          </span>
        </button>
        {clearBrandsTagFlag && (
          <button
            disabled={loading || moreDataLoader}
            type="submit"
            onClick={clearState}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              position: 'absolute',
              top: 1,
              right: -6,
              background: '#f16434',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: 18,
              height: 18,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 14,
            }}
          >
            ×
          </button>
        )}
      </div>
    </div>
  )
}

export default CategoryButton
