/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Collapse, message, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  AD_RELEVANCE_SECTION,
  CLICK_THROUGH_RATE_SECTION,
  COST_EVALUATION_SECTION,
  GOOGLE_ADS_BEST_PRACTICES_SECTION,
  IMPRESSION_SHARE_SECTION,
  KEYWORDS_MATCH_TYPE_SECTION,
  PERFORMANCE_SECTION,
  QUALITY_SCORE_SECTION,
  WASTED_SPEND_SECTION,
  ADS_BEDGET_COST_CACULATOR,
  ADS_PERFORMANCE_GRADE,
  INPUT_VALUES,
  ADS_LOCATION,
  ADS_DATA,
  ACTIVE_KEY,
  BRAND_CHOSEN,
  ADS_SEARCH_KEYWORD,
  EMPTY_ADS_PREVIEW_DATA,
  CLEAR_BRANDS_TAG_FLAG,
} from 'utils/constants'
import tokenService from '@services/tokenService'
import { Text } from '@components'
import { useTranslation } from 'react-i18next'
import Calculator from 'pages/calculator'
import AdsPreview from 'pages/google-ads-preview'
import { paginatedNext, updateCurrentReport } from 'store/actions'
import Benchmark from '@pages/benchmark'
import LearningCenter from '@pages/learning-center'
import SaveAds from '@pages/save-ads'
import Boards from '@pages/boards'
import AgAlertsPage from 'pages/ag-alerts'
import { LS_KEY, AG_AC_TOKEN } from '@utils/constants'
import { notify } from '@utils/notify'
import CustomersSelect from '@components/Select/customersSelect'
import { fetchRoleInfo } from '@services/fetchRoleInfo'
import SampleReport from '@components/ReportPageTemporary/sampleReport'
import Questionnaire from '@components/popover/popover'
import { fetchQuestionnaireQuestions } from '@services/fetchQuestionnaireQuestions'
import AlertIcon from '@images/Alert.png'
import CampaignIcon from '@images/CampaignIcon.png'

import { RequestService } from 'services/requests'
import SearchBar from 'pages/discover-ads'
import BoardsDetail from '@pages/boards/boards-feature/BoardsDetail'
import FollowingBrands from '@pages/discover-ads/brands'
import BrandsDetail from '@components/DiscoverAds/BrandsDetail'
import { scrollToElement, sortByCreatedAt } from '../../utils/helperFunctions'
import ReportPageTemporary from '../../components/ReportPageTemporary/index'
import { fetchReports } from '../../services/fetchReports'
import reportImg from '../../images/report.png'
import consultingImg from '../../images/consulting.svg'
import reportsIcon from '../../images/Sidebar/Reports.png'
import benchmarksIcon from '../../images/Sidebar/Benchmarks.png'
import calculatorIcon from '../../images/Sidebar/Calculator.png'
import consultingIcon from '../../images/Sidebar/Consulting Services.png'
import suggestIcon from '../../images/suggestIcon.svg'
import learningIcon from '../../images/Sidebar/LearningCenter.png'
import creativeIcon from '../../images/Sidebar/Creative.png'
import campaignIcon from '../../images/Sidebar/CampaignIcon.png'

import styles from './dashboard.module.scss'
import ReportHeader from '../../components/Report/ReportHeader'
import SideDrawerCollapse from '../../components/SideDrawerCollapse'
import ConnectGoogleAccountModal from './Connect-account-modal'
// import { pathname } from 'pages/report-demo/demo-data'

const { Panel } = Collapse

const generateRoles = ['Admin', 'Account Manager', 'Owner']

const Dashboard = () => {
  const isCustomers = localStorage.getItem('isCustomers')
  const isInviterCustomers = localStorage.getItem('isInviterCustomers')
  const session = localStorage.getItem('np_ads_grader:session')
  const parsedData = JSON.parse(session)
  const provider = parsedData?.user
  let isGooglePresent = false
  if (provider?.social_accounts) {
    isGooglePresent = provider?.social_accounts.some(account => account?.provider === 'google')
  }
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const customer = useSelector(state => state.customer)
  const updatedReport = useSelector(state => state?.report)
  const isGenerateReport = useSelector(state => state?.generateReport)
  const sampleReport = useSelector(state => state?.sampleReport?.sampleReport)
  const [selectedTab, setSelectedTab] = useState('')
  const [reports, setReports] = useState([])
  const [currentReport, setCurrentReport] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(customer?.id ?? '')
  const [showGenerateButton, setShowGenerateButton] = useState(false)
  const [generateReportLoading, setGenerateReportLoading] = useState(false)
  const [currentSection, setCurrentSection] = useState(null)
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isBenchmarkOpen, setIsBenchmarkOpen] = useState(false)
  const [isSaveAdsOpen, setIsSaveAdsOpen] = useState(false)
  const [isBoardsOpen, setIsBoardsOpen] = useState(false)
  const [isBoardsDetailOpen, setIsBoardsDetailOpen] = useState(false)
  const [isBrandsDetailOpen, setIsBrandsDetailOpen] = useState(false)
  const [isFollowBrandsOpen, setIsFollowBrandsOpen] = useState(false)
  const [isAdsPreviewOpen, setIsAdsPreviewOpen] = useState(false)
  const [isLearningCenterOpen, setIsLearningCenterOpen] = useState(false)
  const [questionnaireData, setQuestionnaireData] = useState([])
  const [allQuestionnaireData, setAllQuestionnaireData] = useState([])
  const [boardDetail, setBoardDetail] = useState(false)
  const [brandsDetail, setBrandsDetail] = useState(false)
  const [tempTest, setTempTest] = useState(true)
  const [open, setOpen] = useState(['1'])
  const [campaignOpen, setCampaignOpen] = useState(['2'])
  const [trueAnswered, setTrueAnswered] = useState([])
  const [loadError, setLoadError] = useState(false)

  const token = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
  const history = useHistory()
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const dispatch = useDispatch()

  const [isAlert, setIsAlert] = useState(false)
  const [pannelItemClick, setIpannelItemClick] = useState('')
  const accessToken = tokenService.getLocalAccessToken()
  const sections = [
    { label: `${t('report.adsperformancegrade')}`, key: ADS_PERFORMANCE_GRADE },
    { label: `${t('report.performanceMetrics')}`, key: PERFORMANCE_SECTION },
    { label: `${t('report.tabs.costEvolution.title')}`, key: COST_EVALUATION_SECTION },
    { label: `${t('report.tabs.spend.title')}`, key: WASTED_SPEND_SECTION },
    { label: `${t('report.tabs.adRelevance.title')}`, key: AD_RELEVANCE_SECTION },
    { label: `${t('report.tabs.qualityScore.title')}`, key: QUALITY_SCORE_SECTION },
    { label: `${t('report.tabs.adComparisons.ctr')}`, key: CLICK_THROUGH_RATE_SECTION },
    { label: `${t('report.tabs.keywordsMatchType.title')}`, key: KEYWORDS_MATCH_TYPE_SECTION },
    { label: `${t('report.tabs.impShare.title')}`, key: IMPRESSION_SHARE_SECTION },
    { label: `${t('report.tabs.googleAdsBest.title')}`, key: GOOGLE_ADS_BEST_PRACTICES_SECTION },
    // { label: `${t('report.tabs.adsCalculator')}`, key: ADS_BEDGET_COST_CACULATOR },
  ]
  const currentPath = window.location.pathname
  const getUniqueIDFromURL = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const reportId = url.searchParams.get('reportId')
    const customerId = url.searchParams.get('customerId')
    return { reportId, customerId }
  }
  useEffect(() => {
    const inviteFromString = localStorage.getItem('inviteFrom')
    const inviteFrom = JSON?.parse(inviteFromString)
    const isBoardPrivate = inviteFrom?.isBoardPrivate
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessTokens = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    if (inviteFormEmail) {
      if (accessTokens) {
        if (userEmail !== inviteFormEmail && isBoardPrivate) {
          // message.info('Please login to authorized account to see the board.')
          message.open({
            type: 'info',
            content: 'Please login to authorized account to see the board.',
            duration: 10, // Keep it open until manually closed
            style: {
              marginTop: '8.5vh',
            },
            // onClose: handleClose, // close handler
          })
          history.push('/dashboard/boards')
        } else {
          // eslint-disable-next-line
          if (userEmail === inviteFormEmail && !isBoardPrivate) {
            const { reportId, customerId } = getUniqueIDFromURL()
            if ((reportId, customerId)) {
              localStorage.setItem('copied_report_unique_id', reportId)
              localStorage.setItem('copied_report_customer_id', customerId)
            }
            // history.push('/report-dashboard')
            history.push('/dashboard')
          } else if (userEmail === inviteFormEmail && isBoardPrivate) {
            history.push('/dashboard/boards')
          } else {
            history.push('/report-dashboard')
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [history])
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const [firstTimeRenderflag, setFirstTimeRenderflag] = useState(true)

  useEffect(() => {
    if (token && customer?.id && firstTimeRenderflag) {
      setIsLoading(true)
      setReports([])
      setCurrentReport(null)
      setCurrentSection(null)
      fetchReports(customer?.id)
        .then(res => {
          setShowGenerateButton(false)
          setIsLoading(false)
          if (res.status === 200) {
            const sortedData = sortByCreatedAt(res?.data?.results)
            setReports(sortedData)
            setSelectedTab('')
            setLoadError(false)
            setTempTest(false)
            if (sortedData?.length) {
              fetchRoleInfo(selectedUserId).then(r => {
                if (generateRoles.includes(r?.data?.role?.name) || !r?.data?.is_invited_user) {
                  setShowGenerateButton(true)
                }
              })
            }
          } else if (res.status >= 500) {
            setSelectedTab('')
            setLoadError(true)
          } else {
            // console.log('reports error~: ', res)
          }
        })
        .catch(error => {
          setIsLoading(false)
        })
      setFirstTimeRenderflag(false)
    } else if (!accessToken) {
      history.push('/login')
    }
    // eslint-disable-next-line
  }, [token, history, customer])
  useEffect(() => {
    if (reports?.length) {
      setSelectedTab(reports[0]?.id)
      setCurrentReport(reports[0])
    }
  }, [reports])
  useEffect(() => {
    if (sampleReport === true) {
      setIsLoading(false)
    }
  }, [sampleReport])
  useEffect(() => {
    if (updatedReport) {
      setCurrentReport(updatedReport)
    }
  }, [updatedReport])

  const handleSelectUser = customerId => {
    setSelectedUserId(customerId)
  }

  const handleSectionClick = sectionId => {
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    setIsFollowBrandsOpen(false)
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsCalculatorOpen(false)
    setCampaignOpen([])
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
    setIsLearningCenterOpen(false)
    setIsSaveAdsOpen(false)
    setIsBoardsOpen(false)
    history.push('/dashboard')
    setTimeout(() => {
      const el = document.getElementById(sectionId)
      scrollToElement(el)
      setCurrentSection(sectionId)
    }, 500)
  }
  const handleBenchMark = e => {
    setIsFollowBrandsOpen(false)
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    e.preventDefault()
    setOpen([])
    setCampaignOpen([])
    history.push('/dashboard/benchmarks')
    setIsBenchmarkOpen(true)
    setIsCalculatorOpen(false)
    setIsAdsPreviewOpen(false)
    setIsLearningCenterOpen(false)
    setIsSaveAdsOpen(false)
    setIsBoardsOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }
  const handleCalculator = e => {
    setIsFollowBrandsOpen(false)
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    e.preventDefault()
    setOpen([])
    setCampaignOpen([])
    history.push('/dashboard/calculator')
    setIsCalculatorOpen(true)
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
    setIsSaveAdsOpen(false)
    setIsBoardsOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }
  const handleAdsPreview = e => {
    // e.preventDefault()
    setOpen([])
    history.push('/dashboard/creative-library')
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsAdsPreviewOpen(true)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
    setIsSaveAdsOpen(false)
    setIsBoardsOpen(false)
    setIsFollowBrandsOpen(false)
  }
  const handleSaveAds = e => {
    // e.preventDefault()
    setOpen([])
    history.push('/dashboard/save-ads')
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsSaveAdsOpen(true)
    setIsBoardsOpen(false)
    setIsAdsPreviewOpen(false)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
    setIsFollowBrandsOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }
  const handleFollowingBrands = e => {
    // e.preventDefault()
    setOpen([])
    history.push('/dashboard/brands')
    setIsFollowBrandsOpen(true)
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsBoardsOpen(false)
    setIsSaveAdsOpen(false)
    setIsAdsPreviewOpen(false)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }

  const handleBoardsOpen = e => {
    // e.preventDefault()
    setOpen([])
    history.push('/dashboard/boards')
    setIsFollowBrandsOpen(false)
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsBoardsOpen(true)
    setIsSaveAdsOpen(false)
    setIsAdsPreviewOpen(false)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }
  const handleLearningCenter = e => {
    e.preventDefault()
    setOpen([])
    setCampaignOpen([])
    setIsFollowBrandsOpen(false)
    history.push('/dashboard/learning-center')
    setIsBoardsDetailOpen(false)
    setIsBrandsDetailOpen(false)
    setIsLearningCenterOpen(true)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
    setIsBoardsOpen(false)
    setIsSaveAdsOpen(false)
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
  }

  useEffect(() => {
    if (currentPath === '/dashboard') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/calculator') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(false)
      setIsCalculatorOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/benchmarks') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(true)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/creative-library') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsAdsPreviewOpen(true)
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/learning-center') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/learning-center/search-feature') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/learning-center/knowledge') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/learning-center/videos') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/learning-center/trainings') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/save-ads') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsSaveAdsOpen(true)
      setIsBoardsOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsLearningCenterOpen(false)
    } else if (currentPath === '/dashboard/boards') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(true)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsBoardsOpen(true)
      setIsSaveAdsOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsLearningCenterOpen(false)
    } else if (currentPath === '/dashboard/learning-center/trainings') {
      setIsFollowBrandsOpen(false)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(true)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
    } else if (currentPath === '/dashboard/boards/detail') {
      setIsFollowBrandsOpen(false)
      setOpen([])
      // setCampaignOpen([])
      setBoardDetail(true)
      setIsBrandsDetailOpen(false)
      setBrandsDetail(false)
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
      setIsBoardsDetailOpen(true)
    } else if (currentPath === '/dashboard/brands/detail') {
      setIsFollowBrandsOpen(false)
      setIsBrandsDetailOpen(true)
      setOpen([])
      // setCampaignOpen([])
      setBoardDetail(false)
      setBrandsDetail(true)
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
      setIsBoardsDetailOpen(false)
    } else if (currentPath === '/dashboard/brands') {
      setIsFollowBrandsOpen(true)
      setIsBoardsDetailOpen(false)
      setIsBrandsDetailOpen(false)
      setOpen([])
      setBrandsDetail(false)
      setBoardDetail(false)
      setIsBoardsOpen(false)
      setIsSaveAdsOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsLearningCenterOpen(false)
    }
  }, [currentPath])
  useEffect(() => {
    if (currentPath === '/dashboard/boards/detail') {
      history.push('/dashboard/boards')
      setOpen([])
      setIsBrandsDetailOpen(false)
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsBoardsOpen(true)
      setIsSaveAdsOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsLearningCenterOpen(false)
      setIsFollowBrandsOpen(false)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (currentPath === '/dashboard/brands/detail') {
      history.push('/dashboard/brands')
      setOpen([])
      setBoardDetail(false)
      setBrandsDetail(false)
      setIsBoardsOpen(false)
      setIsFollowBrandsOpen(true)
      setIsSaveAdsOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsLearningCenterOpen(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleOpenConsulting = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      // Base URL with common parameterssetCampaignOpen
      let url =
        'https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting'
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `&cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
      }

      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log('🚀 ~ getBenchmarks ~ e:', e)
    }
  }

  useEffect(() => {
    const leadTest = JSON.parse(localStorage.getItem('lead_form'))
    const loginThroughWebLinkLocalstorage = JSON.parse(localStorage.getItem('loginThroughWebLink'))
    if (loginThroughWebLinkLocalstorage) {
      if (leadTest) {
        history.push('/dashboard')
      }
      const selectaccountFromLocalstorage = JSON.parse(localStorage.getItem('selectAccount'))
      if (selectaccountFromLocalstorage) {
        if (selectaccountFromLocalstorage) {
          const customerFromLocalStorage = JSON.parse(localStorage.getItem('customer'))
          if (customerFromLocalStorage) {
            const generatingFromLocalstorage = JSON.parse(localStorage.getItem('report-generating'))
            if (!generatingFromLocalstorage && !leadTest) {
              history.push(`/report-generating/${selectaccountFromLocalstorage}`)
            }
          }
        }
      } else {
        // eslint-disable-next-line
        if (!JSON.parse(localStorage.getItem('errorCustomer')) && !leadTest) {
          history.push('/select-account')
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isCalculatorOpen, isBenchmarkOpen, isAdsPreviewOpen])

  useEffect(() => {
    if (isGenerateReport === true) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isGenerateReport])
  useEffect(() => {
    if (!selectedTab && !isLoading && !loadError) {
      dispatch(updateCurrentReport({ currentReport: true }))
    } else {
      dispatch(updateCurrentReport({ currentReport: false }))
    }
    // eslint-disable-next-line
  }, [selectedTab, isLoading, loadError])
  useEffect(() => {
    fetchQuestionnaireQuestions()
      .then(res => {
        const data = res?.data?.should_show_questionnaire
        if (JSON.parse(localStorage.getItem('showPopover')) === null) {
          localStorage.setItem('showPopover', data ?? false)
        }
        setQuestionnaireData(
          // eslint-disable-next-line
          res?.data?.questions?.filter(item => {
            // eslint-disable-next-line
            return item?.is_answered === false
          })
        )
        setTrueAnswered(
          // eslint-disable-next-line
          res?.data?.questions?.filter(item => {
            // eslint-disable-next-line
            return item?.is_answered === true
          })
        )
        setAllQuestionnaireData(res?.data?.questions)
      })
      .catch(error => {
        // console.log(error)
      })
  }, [])
  const pannelItem = [
    'Alerts Overview',
    // 'Alerts Setup / Edits',
    // 'Alerts Formula',
    // 'Manage Email Alerts',
  ]
  const capmaignPanelItem = [
    'Creative Library',
    // 'Alerts Setup / Edits',
    // 'Alerts Formula',
    // 'Manage Email Alerts',
  ]
  const handleButtonAlert = index => {
    // Handle button click based on the index or identifier
    setIsBenchmarkOpen(false)
    setIsCalculatorOpen(false)
    setIsAlert(true)
    setIpannelItemClick(index)
  }
  const handleButtonPreview = index => {
    // Handle button click based on the index or identifier
    setIsAdsPreviewOpen(true)
    setIsBenchmarkOpen(false)
    setIsCalculatorOpen(false)
    setIsAlert(false)
    setIpannelItemClick(index)
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
  }

  const handleConnect = () => {
    // history.push('/login')
    notify('Please contact to Admin to unlock', 'warn')
    // setIsModalVisible(false)
  }
  const reportTabsSwitch = () => {
    if (
      !isGooglePresent &&
      (isInviterCustomers === 'false' || !isInviterCustomers) &&
      (isCustomers === 'false' || !isCustomers)
    ) {
      setIsModalVisible(true)
    } else {
      setOpen(prevOpen => (prevOpen.includes('1') ? [] : ['1']))
    }
  }
  useEffect(() => {
    if (
      currentPath === '/dashboard' &&
      !isGooglePresent &&
      (isInviterCustomers === 'false' || !isInviterCustomers) &&
      (isCustomers === 'false' || !isCustomers)
    ) {
      setIsModalVisible(true)
    }
    // eslint-disable-next-line
  }, [currentPath])
  return (
    <>
      <div className={styles.container}>
        {/* {!sampleReport && ( */}
        <div className={styles.sidebar}>
          <div className={styles.sidebarTop}>
            <div id="creativeLibraryDropdown">
              <Collapse
                accordion
                expandIconPosition="end"
                bordered={false}
                style={{
                  marginTop: 0,
                  background:
                    isAdsPreviewOpen ||
                    isSaveAdsOpen ||
                    isBoardsOpen ||
                    isBrandsDetailOpen ||
                    isFollowBrandsOpen ||
                    isBoardsDetailOpen
                      ? '#fff'
                      : '#F1F3F4',
                }}
                defaultActiveKey={['2']}
                activeKey={campaignOpen}
                onChange={() => setCampaignOpen(prevOpen => (prevOpen.includes('2') ? [] : ['2']))}
              >
                <Panel
                  header={
                    <div style={{ display: 'flex', gap: 18, paddingTop: 4 }}>
                      <img src={campaignIcon} alt="campaign-icon" />{' '}
                      <Text isBold>Creative Library</Text>
                    </div>
                  }
                  key="2"
                >
                  <div
                    className={`${styles.campaignReportsAccordion} customScrollbar`}
                    style={{
                      height: '50%',
                      overflow: 'auto',
                      background:
                        isAdsPreviewOpen ||
                        isSaveAdsOpen ||
                        isBoardsOpen ||
                        isBoardsDetailOpen ||
                        isBrandsDetailOpen ||
                        isFollowBrandsOpen
                          ? '#fff'
                          : '#F1F3F4',
                      // paddingBottom: '20px',
                    }}
                  >
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background:
                          isAdsPreviewOpen ||
                          isSaveAdsOpen ||
                          isBoardsOpen ||
                          isBoardsDetailOpen ||
                          isBrandsDetailOpen ||
                          isFollowBrandsOpen
                            ? '#fff'
                            : '#F1F3F4',
                        color: isAdsPreviewOpen ? '#F16434' : '#67696B',
                        fontWeight: isAdsPreviewOpen && 'bold',
                        padding: '1rem 0px 1rem 0px',
                        width: '100%',
                      }}
                      onClick={e => handleAdsPreview(e)}
                    >
                      Discover Ads
                    </button>
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background:
                          isAdsPreviewOpen ||
                          isSaveAdsOpen ||
                          isBoardsOpen ||
                          isBoardsDetailOpen ||
                          isBrandsDetailOpen ||
                          isFollowBrandsOpen
                            ? '#fff'
                            : '#F1F3F4',
                        color: isSaveAdsOpen ? '#F16434' : '#67696B',
                        fontWeight: isSaveAdsOpen && 'bold',
                        padding: '0rem 0px 1rem 0px',
                        width: '100%',
                      }}
                      onClick={e => handleSaveAds(e)}
                    >
                      Saved Ads
                    </button>
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background:
                          isAdsPreviewOpen ||
                          isSaveAdsOpen ||
                          isBoardsOpen ||
                          isBoardsDetailOpen ||
                          isBrandsDetailOpen ||
                          isFollowBrandsOpen
                            ? '#fff'
                            : '#F1F3F4',
                        color: isBoardsOpen || isBoardsDetailOpen ? '#F16434' : '#67696B',
                        fontWeight: (isBoardsOpen || isBoardsDetailOpen) && 'bold',
                        padding: '0rem 0px 1rem 0px',
                        width: '100%',
                      }}
                      onClick={e => handleBoardsOpen(e)}
                    >
                      Boards
                    </button>
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background:
                          isAdsPreviewOpen ||
                          isSaveAdsOpen ||
                          isBoardsOpen ||
                          isBoardsDetailOpen ||
                          isBrandsDetailOpen ||
                          isFollowBrandsOpen
                            ? '#fff'
                            : '#F1F3F4',
                        color: isBrandsDetailOpen || isFollowBrandsOpen ? '#F16434' : '#67696B',
                        fontWeight: (isBrandsDetailOpen || isFollowBrandsOpen) && 'bold',
                        padding: '0rem 0px 1rem 0px',
                        width: '100%',
                      }}
                      onClick={e => handleFollowingBrands(e)}
                    >
                      Followed Brands
                    </button>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div id="reportCollapse" className={styles.collapse} style={{ background: '#E6E9EC' }}>
              <div
                style={{
                  color: '#939395',
                  fontSize: 10,
                  background: '#E6E9EC',
                  paddingLeft: 31,
                  paddingTop: 6,
                  paddingBottom: 6,
                }}
              >
                TRACK
              </div>
              <Collapse
                accordion
                expandIconPosition="end"
                bordered={false}
                style={{
                  background:
                    !isCalculatorOpen &&
                    !isBenchmarkOpen &&
                    !isAdsPreviewOpen &&
                    !isLearningCenterOpen &&
                    !isSaveAdsOpen &&
                    !isBoardsOpen &&
                    !isBrandsDetailOpen &&
                    !isFollowBrandsOpen &&
                    !isBrandsDetailOpen
                      ? '#fff'
                      : '#F1F3F4',
                  marginTop: -10,
                  border: 'none',
                }}
                activeKey={open}
                onChange={() => reportTabsSwitch()}
              >
                <Panel
                  id="dashboard-collapse"
                  header={
                    <div style={{ display: 'flex', paddingTop: 4, gap: 8, border: 'none' }}>
                      <img
                        src={reportsIcon}
                        style={{ marginLeft: 8, marginTop: -5, border: 'none' }} // Adjust margin as needed
                        height={32}
                        alt="Reports logo"
                      />
                      <div
                        className={`${styles.reportHeader} ${
                          reports?.includes(selectedTab) && styles.active
                        } `}
                        style={{ border: 'none' }}
                      >
                        {t('reports')}
                      </div>
                    </div>
                  }
                  onChange={() => setOpen(prev => [1])}
                  key="1"
                >
                  <div
                    className={`${styles.reportsAccordion} customScrollbar`}
                    style={{
                      marginTop: -7,
                      height: '50%',
                      overflow: 'auto',
                      paddingBottom: '20px',
                      paddingLeft: 59,
                    }}
                  >
                    {sections.map(section => (
                      <button
                        type="button"
                        key={section.key}
                        className={`${styles.sectionNameCollapse} ${
                          currentSection === section.key &&
                          !isCalculatorOpen &&
                          !isBenchmarkOpen &&
                          !isAdsPreviewOpen &&
                          !isLearningCenterOpen &&
                          styles.active
                        }`}
                        onClick={() => handleSectionClick(section.key)}
                        disabled={isLoading}
                      >
                        {section.label}
                      </button>
                    ))}
                  </div>
                </Panel>
                <div
                  style={{
                    color: '#939395',
                    fontSize: 10,
                    background: '#E6E9EC',
                    paddingLeft: 31,
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                >
                  PLAN
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      background: isCalculatorOpen ? '#fff' : '#F1F3F4',
                      borderTop: '1px solid #d9d9d9',
                      gap: 8,
                    }}
                  >
                    <img
                      src={calculatorIcon}
                      style={{ marginTop: 13, marginLeft: 24 }}
                      height={32}
                      alt="Calculator logo"
                    />
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background: isCalculatorOpen ? '#fff' : '#F1F3F4',
                        color: '#26282C',
                        fontWeight: isCalculatorOpen && 'bold',
                        padding: '1rem 0px 1rem 0rem',
                      }}
                      onClick={e => handleCalculator(e)}
                    >
                      {/* eslint-disable-next-line */}
                      {t('report.tabs.adsCalculator')}
                    </button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      background: isBenchmarkOpen ? '#fff' : '#F1F3F4',
                      borderTop: '1px solid #d9d9d9',
                      gap: 8,
                    }}
                  >
                    <img
                      src={benchmarksIcon}
                      style={{ marginTop: 10, marginLeft: 24 }}
                      height={32}
                      alt="Benchmarks logo"
                    />
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background: isBenchmarkOpen ? '#fff' : '#F1F3F4',
                        color: '#26282C',
                        fontWeight: isBenchmarkOpen && 'bold',
                        padding: '1rem 0px 1rem 0rem',
                        width: '100%',
                      }}
                      onClick={e => handleBenchMark(e)}
                    >
                      {/* eslint-disable-next-line */}
                      {t('benchmark.buttonText')}
                    </button>
                  </div>

                  <div
                    style={{
                      color: '#939395',
                      fontSize: 10,
                      background: '#E6E9EC',
                      paddingLeft: 31,
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                  >
                    LEARN
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      background: isLearningCenterOpen ? '#fff' : '#F1F3F4',
                      borderTop: '1px solid #d9d9d9',
                      gap: 17,
                    }}
                  >
                    <img
                      src={learningIcon}
                      style={{ marginTop: 19, marginLeft: 30 }}
                      height={19}
                      alt="Learning center logo"
                    />
                    <button
                      type="button"
                      className={styles.sectionName}
                      style={{
                        background: isLearningCenterOpen ? '#fff' : '#F1F3F4',
                        color: '#202020',
                        fontWeight: isLearningCenterOpen && 'bold',
                        padding: '1rem 0px 1rem 0rem',
                        width: '100%',
                      }}
                      onClick={e => handleLearningCenter(e)}
                    >
                      {/* eslint-disable-next-line */}
                      {t('report.tabs.learningCenter')}
                    </button>
                  </div>
                </div>
              </Collapse>
              {/* Alerts collapse  */}
              {/* <SideDrawerCollapse
                panelHeader="Under/Over Spend Alerts"
                pannelItem={pannelItem}
                handlePanelClick={handleButtonAlert}
                icon={AlertIcon}
              /> */}
              {/* <Collapse
                accordion
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel header={<div>Under/Over Spend Alerts</div>} key="1">
                  <div
                    className={`${styles.reportsAccordion} customScrollbar`}
                    style={{
                      height: '50%',
                      overflow: 'auto',
                      paddingBottom: '20px',
                    }}
                  >
                    <button
                      type="button"
                      className={`${styles.sectionName} ${styles.active}`}
                      // onClick={() => handlePanelClick()}
                      disabled={isLoading || !reports?.length}
                    >
                      sectionLable
                    </button>
                  </div>
                </Panel>
              </Collapse> */}
            </div>
            <div
              style={{
                color: '#939395',
                fontSize: 10,
                background: '#E6E9EC',
                paddingLeft: 31,
                paddingTop: 6,
                paddingBottom: 6,
              }}
            >
              HIRE & HELP
            </div>

            <div className={styles.consulting2}>
              <span
                className={styles.textWithLogo}
                style={{ paddingLeft: 22 }}
                // href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting"
                // target="_blank"
                // rel="noreferrer"
              >
                <img src={consultingIcon} alt="Consulting Services logo" />
                <button
                  className={styles.belowReportButton}
                  type="button"
                  onClick={handleOpenConsulting}
                >
                  {t('consultingServicesButtonText')}
                </button>
              </span>
            </div>
            <div className={styles.consulting2}>
              <span
                className={styles.textWithLogo}
                style={{ paddingLeft: 22 }}
                // href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting"
                // target="_blank"
                // rel="noreferrer"
              >
                <img src={suggestIcon} alt="Suggest Feature logo" />
                <button
                  className={styles.belowReportButton}
                  type="button"
                  onClick={() => window.open('https://adsgrader.feedbear.com/', '_blank')}
                >
                  {t('featureSuggestButton')}
                </button>
              </span>
            </div>
            <div className={styles.belowReportsAccordion} style={{ display: 'none' }}>
              <span
                className={styles.textWithLogo}
                // href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting"
                // target="_blank"
                // rel="noreferrer"
              >
                <img src={consultingIcon} alt="Consulting Services logo" />
                <button
                  className={styles.belowReportButton}
                  type="button"
                  onClick={handleOpenConsulting}
                >
                  {t('consultingServicesButtonText')}
                </button>
              </span>
            </div>
          </div>
        </div>
        {/*  )} */}
        <div
          id="scrollableDiv"
          className={`${styles.reportContainer} customScrollbar`}
          style={{
            background:
              isAdsPreviewOpen ||
              isSaveAdsOpen ||
              isBoardsOpen ||
              isBoardsDetailOpen ||
              isFollowBrandsOpen ||
              brandsDetail
                ? '#F1F3F4'
                : '',
          }}
        >
          <div id="report">
            {/* eslint-disable-next-line */}
            {selectedTab === 'Dashboard' ? (
              <div>{t('dashboardText')}</div>
            ) : // eslint-disable-next-line
            isCalculatorOpen ? (
              <div ref={containerRef}>
                <Calculator />
              </div>
            ) : // eslint-disable-next-line
            isBenchmarkOpen ? (
              <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
                <Benchmark />
              </div>
            ) : // eslint-disable-next-line
            isLearningCenterOpen ? (
              <div
                ref={containerRef}
                style={{ padding: '10px 10px 3.7rem 10px', background: '#F9FAFB' }}
              >
                <LearningCenter />
              </div>
            ) : // eslint-disable-next-line
            isAlert ? (
              <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
                <AgAlertsPage pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            isAdsPreviewOpen ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <SearchBar pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            isSaveAdsOpen ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <SaveAds pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            isBoardsOpen ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <Boards pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            boardDetail ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <BoardsDetail pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            isFollowBrandsOpen ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <FollowingBrands pannelItemClicked={pannelItemClick} />
              </div>
            ) : // eslint-disable-next-line
            brandsDetail ? (
              <div
                ref={containerRef}
                style={{
                  padding: '10px 10px 3.7rem 10px',
                }}
              >
                <BrandsDetail pannelItemClicked={pannelItemClick} />
              </div>
            ) : (
              <div ref={containerRef}>
                {JSON.parse(localStorage.getItem('showPopover')) &&
                  window.location.pathname === '/dashboard' &&
                  questionnaireData.length > 0 &&
                  JSON.parse(localStorage.getItem('isCustomers')) && (
                    <Questionnaire
                      data={questionnaireData}
                      wholeData={allQuestionnaireData}
                      trueOnly={trueAnswered}
                    />
                  )}
                {!isLoading && (
                  <>
                    {/* {JSON.parse(localStorage.getItem('showPopover')) &&
                  window.location.pathname === '/dashboard' &&
                  questionnaireData.length > 0 && (
                    <Questionnaire data={questionnaireData} wholeData={allQuestionnaireData} />
                  )} */}
                    <ReportHeader
                      reports={reports}
                      setCurrentReport={setCurrentReport}
                      currentReport={currentReport}
                    />
                  </>
                )}
                {selectedTab && !isLoading && (
                  <>
                    <ReportPageTemporary
                      selectedReportData={currentReport}
                      setCurrentSection={setCurrentSection}
                    />
                  </>
                )}
                {!selectedTab && !isLoading && loadError && (
                  <div className={styles.selectReport}>
                    <h1 className={styles.selectReportHeading}>{t('loadReportErrorText')}</h1>
                    {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                  </div>
                )}
                {!selectedTab && !isLoading && !loadError && !sampleReport && !tempTest && (
                  <div className={styles.selectReport}>
                    <h1 className={styles.selectReportHeading}>{t('noReportText')}</h1>
                    {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                  </div>
                )}
                {sampleReport && (
                  <div className={`${styles.selectReport} ${styles.selectReport_sample}`}>
                    <SampleReport />
                    {/* <h1 className={styles.selectReportHeading}>{t('noReportText')}</h1> */}
                    {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                  </div>
                )}
                {isLoading && !sampleReport && (
                  <div className={styles.loadingContainer}>
                    <Spin />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ConnectGoogleAccountModal
          visible={isModalVisible}
          onClose={handleClose}
          onConnect={handleConnect}
          detail="reports"
        />
      )}
    </>
  )
}

export default Dashboard
