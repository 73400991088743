import React from 'react'
import { Button, Col, Input, Row, Space } from 'antd'
import { Text } from '@components'

import GoogleAdsImageImcon from '@images/HomePage/GoogleAdsImage.png'
import styles from './RunningGoogleAds.module.css'
import InputProceed from '../InputProceedNew'

const RunningGoogleAds = () => {
  const temp = 0
  return (
    <>
      <Row justify="center" align="middle">
        <Col span={10}>
          <Text
            className={styles.keyFeatures}
            style={{ fontSize: 56, fontWeight: 700, lineHeight: '1.2' }}
          >
            Running <span style={{ color: '#F16434' }}> Google Ads?</span>
          </Text>
          <Text style={{ display: 'block', marginTop: 16, fontSize: 20, color: '#5A5A5A' }}>
            Have a quick Google ads analysis and see how you can improve your ads.
          </Text>

          <div style={{ marginTop: 24, width: 446 }}>
            <InputProceed />
          </div>
        </Col>
        <Col span={6}>
          <img src={GoogleAdsImageImcon} alt="Google Ads" />
        </Col>
      </Row>
    </>
  )
}
export default RunningGoogleAds
