import React from 'react'
import GoogleIcon from '@images/DiscoverAds/SearchBar/GoogleIcon.png'
import FacebookIcon from '@images/DiscoverAds/SearchBar/fb.png'
import InstaIcon from '@images/DiscoverAds/SearchBar/insta.png'
import MessengerIcon from '@images/DiscoverAds/SearchBar/messenger.png'
import ImageIcon from '@images/DiscoverAds/Modal/DetailsCard/imgIcon.png'
import AudienceIcon from '@images/DiscoverAds/SearchBar/audience.png'
import Text from '@components/Text/text'
import { Card, Col, Row, Space, Spin, Tag, Tooltip } from 'antd'
import { useSelector } from 'react-redux'

const AdInfo = ({ adsData }) => {
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const scoresAndIndustry = useSelector(state => state?.discoverAds?.scoreAndIndustry)
  const scoreAndIndustryLoader = useSelector(state => state?.discoverAds?.scoreAndIndustryLoader)
  return (
    <div
      title="Details Section"
      style={{ marginTop: '20px', border: '1px solid #DFE3E4', background: 'white' }}
    >
      <Text isBold size={18} style={{ padding: 16 }}>
        Details Section
      </Text>
      <div style={{ borderBottom: '1px solid #DFE3E4' }} />
      <Row gutter={16}>
        <Col span={6} style={{ padding: 24 }}>
          <Text isBold>Platforms</Text>
          <Space style={{ marginTop: 14 }}>
            {
              // eslint-disable-next-line
              adsData?.additional_info?.publisher_platforms?.map((platform, key) => {
                return (
                  // eslint-disable-next-line
                  <div key={key}>
                    {platform === 'facebook' && <img src={FacebookIcon} alt="facebook-icon" />}
                    {platform === 'instagram' && <img src={InstaIcon} alt="insta-icon" />}
                    {platform === 'google' && <img src={GoogleIcon} alt="google-icon" />}
                    {platform === 'audience_network' && (
                      <img src={AudienceIcon} alt="audience-network-icon" />
                    )}
                    {platform === 'messenger' && <img src={MessengerIcon} alt="messenger-icon" />}
                  </div>
                )
              })
            }
          </Space>
        </Col>
        <Col span={6} style={{ borderLeft: '1px solid #DFE3E4' }}>
          <div style={{ padding: 24 }}>
            <Text isBold>Format</Text>
            <div style={{ display: 'flex', marginTop: 14 }}>
              <img src={ImageIcon} alt="img-icon" />
              {selectedChannel === 'Google' ? (
                <Text style={{ marginLeft: '6px' }}>
                  {adsData?.video_url === '' ? 'Image' : 'Video'}{' '}
                </Text>
              ) : (
                <Text style={{ marginLeft: '6px' }}>
                  {adsData?.image_url !== null ? 'Image' : 'Video'}{' '}
                </Text>
              )}
            </div>
          </div>
        </Col>
        <Col span={12} style={{ borderLeft: '1px solid #DFE3E4' }}>
          <div style={{ padding: 24 }}>
            <Text isBold>Industry</Text>
            <div style={{ marginTop: 14 }}>
              {scoreAndIndustryLoader ? (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              ) : (
                <Tag
                  style={{
                    borderRadius: 48,
                    padding: 10,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {scoresAndIndustry?.industry}
                </Tag>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AdInfo
