// Main flow
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Spin, Space, Divider } from 'antd'
import { SecretService } from '@services/secret'
import { useTranslation } from 'react-i18next'
import GoogleLogin from 'react-google-login'
import useFetch from 'use-http'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { useHistory } from 'react-router-dom'
import { baseURL, RequestService } from '@services/requests'
import { notify } from '@utils/notify'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import EmailLoginPage from '@components/EmailLogin/loginPage'
import { updateLocalStorage } from '@utils/helperFunctions'
import SignInWithGoogleButton from '@components/SignInWithGoogleButton'
import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import { useDispatch } from 'react-redux'
import { useRollbar } from '@rollbar/react'
import axios from 'axios'
import Footer from '../../components/Footer'
import { updateSelectedCustomer, updateUserData, updateErrorCustomer } from '../../store/actions'
import FacebookSSOForMainFlow from './SSOWithFaceook/SSO'
import styles from './login.module.scss'

const googleClientId = process.env.REACT_APP_GOOGLE_PROJECT_CLIENT_ID

const LoginPage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const history = useHistory()
  const { post, response: loginResponse, loading } = useFetch(baseURL)
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onFailure = e => console.info(e)

  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
  }, [rollbar])

  const retrieveReport = async selectedCustomer => {
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }

  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved) => {
    try {
      const report = await retrieveReport(selectedCustomer)
      const reportPayload = {
        customerId: String(selectedCustomer.id),
        parentId: selectedCustomer.resource_name?.split('/')[1] || '',
        uuid: report.unique_id,
        currency: selectedCustomer.currency_code || 'USD',
        customerName: selectedCustomer.descriptive_name,
      }
      const reportToken = SecretService.encode(reportPayload)
      localStorage.setItem('selectAccount', JSON.stringify(reportToken))

      if (!isLeadSaved) {
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }
  const handleNoCustomersFallback = async leadFormStatus => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
      } else {
        // eslint-disable-next-line
        if (!leadFormStatus) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }

  const handleGoogleLoginSuccess = async payload => {
    localStorage.setItem('domain_flow', true)
    setGoogleUserLoading(true)
    try {
      const res = await fetchGoogleUserInfo(payload.code)
      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', userData?.user?.profile?.profile_picture)
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))
        notify(t('notification.loginSuccess'), 'success')

        // Always generate a report upon successful login
        const customersResponse = await (async () => {
          const customersResponseUnSorted = await fetchCustomersList()
          return customersResponseUnSorted?.data?.length > 0
            ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
            : []
        })()
        if (customersResponse?.length > 0) {
          const selectedCustomer = customersResponse[0]
          dispatch(updateSelectedCustomer(selectedCustomer))
          localStorage.setItem('customer', JSON.stringify(selectedCustomer))

          // Generate a report for the selected customer
          await handleCustomerSuccess(selectedCustomer, userData?.user?.is_lead_save)
        } else {
          await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const reportId = currentUrl.searchParams.get('reportId')
    const customerId = currentUrl.searchParams.get('customerId')

    if (reportId && customerId) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
  }, [])

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <LogoAdsGrader />
        <Text className={styles.title} size={40} color="white">
          {t('login.title')}
        </Text>

        <Row className={styles.row}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            <Card className={styles.loginCard}>
              <Space direction="vertical" size={16}>
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <Text color="darkerGray">{t('login.description.part1')}</Text>
                    <Text size={12} color="primary">
                      {t('login.description.part2')}
                      <strong> {t('login.description.part3')}</strong>
                    </Text>
                    {googleUserLoading ? (
                      <div style={{ textAlign: 'center' }}>
                        <Spin />
                      </div>
                    ) : (
                      <GoogleLogin
                        clientId={googleClientId}
                        render={({ onClick, disabled }) => (
                          <SignInWithGoogleButton onClick={onClick} disabled={disabled} />
                        )}
                        buttonText="Login with Google"
                        onSuccess={handleGoogleLoginSuccess}
                        onFailure={onFailure}
                        scope="profile email https://www.googleapis.com/auth/adwords"
                        accessType="offline"
                        responseType="code"
                        prompt="select_account"
                      />
                    )}

                    <FacebookSSOForMainFlow />
                    <Divider>OR</Divider>
                    <EmailLoginPage mainFlow />
                  </>
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  )
}

export default LoginPage
