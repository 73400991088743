import React from 'react'
import { bucketBaseURL } from '@services/requests'
import { useDispatch } from 'react-redux'
import {
  PAGE_ID_AND_ADVERTISER_ID,
  TRIGGER_SEARCH,
  INPUT_VALUES,
  BRAND_CHOSEN,
  ADS_SEARCH_KEYWORD,
} from '@utils/constants'
import styles from './BrandTags.module.css'

const BrandTags = ({ image, text, isFirstRow, categryData }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
    dispatch({ type: TRIGGER_SEARCH, payload: true })
    dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
    dispatch({ type: BRAND_CHOSEN, payload: true })
  }
  return (
    <div
      style={{
        marginLeft: isFirstRow ? '20px' : '0', // Add margin for the first row
        display: 'flex',
      }}
    >
      <button
        onClick={handleClick}
        type="button"
        className={styles.button}
        style={{ display: 'flex' }}
      >
        {image && (
          <img
            src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${image}`}
            alt={`${image}-logo`}
            width={20}
            height={20}
            style={{ borderRadius: '50%' }} // Ensure the logo is circular
          />
        )}

        <span>{text}</span>
      </button>
    </div>
  )
}

export default BrandTags
