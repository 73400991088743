import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Input, Button, message } from 'antd'
import * as Yup from 'yup'
import { axiosInstance } from '@services/requests'
import Modal from '@components/Modal'
import Text from '@components/Text/text'
import { notify } from '@utils/notify'
import styles from './ResetPassword.module.css'

const ResetPassword = ({ onNavigate }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const showModal = () => {
    setOpenModal(true)
  }
  const validationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email.').required('Email is required.'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true)
    const data = {
      email: values.email,
    }
    try {
      const res = await axiosInstance.post('/accounts/forgot-password/', data)
      if (res.status === 200) {
        if (res?.data?.message === 'User with this email does not exist.') {
          notify('Email does not exist', 'error')
        } else {
          setIsLoading(false)
          showModal()
        }
      }
    } catch (e) {
      setIsLoading(false)
      notify('Please try again later. ', 'error')
    }
  }
  const handleCancel = () => {
    setOpenModal(false)
  }
  return (
    <>
      <div style={{ maxWidth: 400, margin: '0 auto', padding: '2rem' }}>
        <Text style={{ textAlign: 'center', marginBottom: 20 }}>
          Enter your email and we&apos;ll send you a link to reset your password.
        </Text>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors, touched, isSubmitting }) => (
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                Email <span className={styles.requiredAsterisk}>*</span>
              </div>
              <Input
                name="email"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                status={errors.email && touched.email ? 'error' : ''}
              />
              {errors.email && touched.email && (
                <div
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    fontSize: '0.85rem',
                    marginTop: '-0.5rem',
                  }}
                >
                  {errors.email}
                </div>
              )}
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
        <div style={{ textAlign: 'right', marginTop: '2rem' }}>
          <Text size={14}>
            Back to
            <span
              role="button"
              tabIndex="0"
              className={styles.registerHere}
              onClick={() => onNavigate('login')}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  // handleNavigation('register')
                }
              }}
            >
              &nbsp;Login
            </span>
          </Text>
        </div>
      </div>
      <Modal open={openModal} onCancel={handleCancel} />
    </>
  )
}

export default ResetPassword
