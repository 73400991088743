import { Text } from '@components'
import { axiosInstance } from '@services/requests'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ADS_DATA,
  ADS_LOADER,
  TEMP_COUNT,
  HAS_MORE,
  ADS_PAGINATION,
  CLEANED_DATA_COUNT,
  ADS_SEARCH_KEYWORD,
  CLEAR_BRANDS_FLITER,
  MORE_DATA_LOADER,
  INPUT_VALUES,
  ADS_FORMAT,
  ADS_LOCATION,
  EMPTY_ADS_PREVIEW_DATA,
  CHECKED_VALUES,
  BRANDS_TAGS_FOR_META,
  BRANDS_TAGS_FOR_GOOGLE,
  BRANDS_TAGS_DATA,
  ADS_NO_DATA,
  LIVE_SEARCH,
  BRAND_TAG_FOR_ALL,
  BRAND_TAG_FOR_GOOGLE,
  BRAND_TAG_FOR_META,
  CLEAR_BRANDS_TAG_FLAG,
  PAGE_ID_AND_ADVERTISER_ID,
  TRIGGER_SEARCH,
  BRAND_CHOSEN,
} from '@utils/constants'
import CreateYourAccount from '@components/CreateYourAccount'
import CategoryButton from '@components/DiscoverAds/CategoryButton'
import SearchResultsError from '@components/GoogleAdsPreview/ErrorStates/SearchResultsError'
import { Checkbox, Spin } from 'antd'
import metaStyles from './discoverAdsFeature.module.css'
import AdsCard from '../AdsCard/index'
import SearchBar from '../SearchBar'

const DiscoverAdsFeature = () => {
  const activeCountRedux = useSelector(state => state?.discoverAds?.activeAdsCount)
  const InActiveCountRedux = useSelector(state => state?.discoverAds?.inActiveAdsCount)
  const isLiveSearch = useSelector(state => state?.discoverAds?.isLiveSearch)
  const apiHitsCounter = useSelector(state => state?.UnAuthDiscoverAds?.apiHitsCounter)
  const [signUpModal, setSignUpModal] = useState(false)
  const [registerModal, setRegisterUpModal] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [newDataSpin, setNewDataSpin] = useState(false)
  const dispatch = useDispatch()
  const clearBrandsTagFlag = useSelector(state => state?.discoverAds?.clearBrandsTagFlag)
  const brandsTagForAll = useSelector(state => state?.discoverAds?.brandTagForAll)
  const brandsTagForAllOrignal = useSelector(state => state?.discoverAds?.homePageBrands)
  const brandsTagForGoogleOriginal = useSelector(state => state?.discoverAds?.brandsTagForGoogle)
  const brandsTagForMetaOriginal = useSelector(state => state?.discoverAds?.brandsTagForMeta)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const isMetaStatus = useSelector(state => state?.discoverAds?.isMetaStatus)
  const isGoogleStatus = useSelector(state => state?.discoverAds?.isGoogleStatus)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)
  const [filteredDiscoverData, setFilteredDiscoverData] = useState([])
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const adsPreviewData = useSelector(state => state?.discoverAds?.adsPreviewData)
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const adsPagination = useSelector(state => state?.discoverAds?.adsPagination)
  const hasMore = useSelector(state => state?.discoverAds?.hasMore)
  const boards = useSelector(state => state?.discoverAds?.boards)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const tempCount = useSelector(state => state?.discoverAds?.tempCount)
  const adsNoData = useSelector(state => state?.discoverAds?.adsNoData)
  const brandsFilter = useSelector(state => state?.discoverAds?.brandsFilter)
  const searchSuggestions = useSelector(state => state?.discoverAds?.searchSuggestions)
  const discoverAdsPageBrands = useSelector(state => state?.discoverAds?.homePageBrands)
  const brandsTagForMeta = useSelector(state => state?.discoverAds?.brandsTagForMeta)
  const brandsTagForGoogle = useSelector(state => state?.discoverAds?.brandsTagForGoogle)
  const checkedValues = useSelector(state => state?.discoverAds?.checkedValues)
  const [activeCount, setActiveCount] = useState()
  const [inactiveCount, setInActiveCount] = useState()
  const handleSignupClick = () => {
    setRegisterUpModal(true)
  }
  const handleOpenModal = () => {
    setSignUpModal(true)
  }
  useEffect(() => {
    if (brandsTagForAllOrignal) {
      dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
      dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
      dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    }
    // eslint-disable-next-line
  }, [brandsTagForAllOrignal, brandsTagForGoogleOriginal, brandsTagForMetaOriginal])
  useEffect(() => {
    if (searchKeyword && !loading) {
      const uniqueAds = filteredDiscoverData.reduce((acc, current) => {
        const duplicate = acc.find(
          item => item?.additional_info?.page_name === current?.additional_info?.page_name
        )
        if (!duplicate) {
          acc.push(current)
        }
        return acc
      }, [])

      const updatedBrandTags = uniqueAds.map(ad => ({
        brand_name: ad?.additional_info?.page_name, // Use the page_name as brand_name
        page_profile_logo: ad?.additional_info?.ads_grader_page_profile_picture_url,
      }))

      dispatch({ type: BRAND_TAG_FOR_ALL, payload: updatedBrandTags })
      dispatch({ type: BRAND_TAG_FOR_META, payload: updatedBrandTags })
      dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: updatedBrandTags })
    }

    // eslint-disable-next-line
  }, [searchKeyword, filteredDiscoverData])
  const handleCloseModal = () => {
    if (currentPath !== '/discover-ads-landing-page') {
      // dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
      dispatch({ type: INPUT_VALUES, payload: '' })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    }
    setSignUpModal(false)
    setRegisterUpModal(false)
  }
  const handleSignUpModal = () => {
    setSignUpModal(true)
    setRegisterUpModal(true)
  }
  const handleCheckboxChange = selectedValues => {
    if (checkedValues?.length === 0) {
      return
    }
    if (selectedValues?.length > 0) {
      dispatch({ type: CHECKED_VALUES, payload: selectedValues })
    }
    if (checkedValues?.length === 2) {
      setFilteredDiscoverData(adsPreviewData)
    } else if (checkedValues[0] === 'Active') {
      setFilteredDiscoverData(
        adsPreviewData.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        )
      )
    } else if (checkedValues[0] === 'Inactive') {
      setFilteredDiscoverData(
        adsPreviewData.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        )
      )
    }
  }
  useEffect(() => {
    if (clearBrandsTagFlag) {
      if (selectedChannel === 'All') {
        dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForAll })
        dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForAll })
      } else if (selectedChannel === 'Meta') {
        dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForMeta })
        dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForMeta })
      } else if (selectedChannel === 'Google') {
        dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForGoogle })
        dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForGoogle })
      }
    }
    // eslint-disable-next-line
  }, [clearBrandsTagFlag])

  useEffect(() => {
    if (adsPreviewData.length > 0) {
      setFilteredDiscoverData(adsPreviewData)
    } else if (adsPreviewData.length === 0) {
      setFilteredDiscoverData([])
    }
    // eslint-disable-next-line
  }, [adsPreviewData])

  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : filteredDiscoverData.length - 1))
  }

  const handleNext = () => {
    setSelectedIndex(prevIndex => (prevIndex < filteredDiscoverData.length - 1 ? prevIndex + 1 : 0))
  }

  const handleCategoryClick = categryData => {
    if (apiHitsCounter < 1) {
      dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
      dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
      setFilteredDiscoverData(
        filteredDiscoverData.filter(
          item => item?.additional_info?.page_name === categryData?.brand_name
        )
      )

      dispatch({
        type: BRAND_TAG_FOR_ALL,
        payload: brandsTagForAllOrignal?.discover_ads.filter(
          item => item?.brand_name === categryData?.brand_name
        ),
      })

      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      setSignUpModal(true)
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    }
  }
  const handleCategoryClickForMeta = categryData => {
    if (apiHitsCounter < 1) {
      dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
      dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
      setFilteredDiscoverData(
        filteredDiscoverData.filter(
          item => item?.additional_info?.page_name === categryData?.brand_name
        )
      )

      dispatch({
        type: BRAND_TAG_FOR_META,
        payload: brandsTagForMetaOriginal.filter(
          item => item?.brand_name === categryData?.brand_name
        ),
      })
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
      setSignUpModal(true)
    }
  }
  const handleCategoryClickForGoogle = categryData => {
    if (apiHitsCounter < 1) {
      dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
      dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
      setFilteredDiscoverData(
        filteredDiscoverData.filter(
          item => item?.additional_info?.page_name === categryData?.brand_name
        )
      )

      dispatch({
        type: BRAND_TAG_FOR_GOOGLE,
        payload: brandsTagForGoogleOriginal.filter(
          item => item?.brand_name === categryData?.brand_name
        ),
      })
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      setSignUpModal(true)
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    }
  }

  const fetchMoreData = async () => {
    // if (selectedChannel === 'Meta') {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    try {
      const previousDataLength = filteredDiscoverData.length
      setNewDataSpin(true)
      let response = []
      if (selectedChannel === 'Meta') {
        if (isMetaStatus) {
          response = await axiosInstance.get(
            `/ads-preview/competitors-prod/?next_url_meta=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isMetaStatus) {
          response = await axiosInstance.get(`${adsPagination}`)
        }
      } else if (selectedChannel === 'Google') {
        if (isGoogleStatus) {
          response = await axiosInstance.get(
            `/ads-preview/competitors-prod/?next_url=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isGoogleStatus) {
          response = await axiosInstance.get(`${adsPagination}`)
        }
      } else if (selectedChannel === 'All') {
        response = await axiosInstance.get(`${adsPagination}`)
      }

      // dispatch({ type: ADS_LOADER, payload: false })
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      const formattedData = response?.data.result?.facebook_ads
      if (formattedData?.data?.length > 0) {
        dispatch({
          type: ADS_DATA,
          payload: formattedData?.data,
        })
      }
      if (formattedData?.data?.length > 0) {
        dispatch({ type: TEMP_COUNT, payload: tempCount + 20 })
      }
      const newItemsLength = formattedData?.data.length

      // Extract only the new items using slicing
      const newItems = filteredDiscoverData.slice(
        previousDataLength,
        previousDataLength + newItemsLength
      )

      // You can now use `newItems` to print or log the newly fetched ads
      if (newItems.length > 0) {
        setFilteredDiscoverData(newItems)
      }
      if (selectedChannel === 'Meta') {
        if (formattedData.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      } else if (selectedChannel === 'Google') {
        if (response?.data.result?.facebook_ads?.serpapi_pagination?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data.result?.facebook_ads?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else if (response?.data.result?.facebook_ads?.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      } else if (selectedChannel === 'All') {
        if (formattedData.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,

            payload: formattedData.paging?.next,
          })

          dispatch({
            type: HAS_MORE,

            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,

            payload: false,
          })
        }
      }
      // handleCheckboxChange()
    } catch (error) {
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      // dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      // dispatch({ type: ADS_NO_DATA, payload: true })
    }
    // }
  }

  // start of Google Ads data
  const cleanedDataCount = useSelector(state => state?.adsPreview?.cleanedDataCount)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const adsDatas = useSelector(state => state?.adsPreview?.adsPreviewData)

  const filterAndSaveData = () => {
    const cleanedData = adsDatas.filter(data => {
      if (data.format === 'image' || data.format === 'text') {
        return data.image
      } // eslint-disable-next-line
      else {
        return data.video_link
      }
    })
    const uniqueImagesOrIframes = new Set(cleanedData.map(data => data.image || data.iframe_url))
    const uniqueData = Array.from(uniqueImagesOrIframes).map(item =>
      cleanedData.find(data => data.image === item || data.iframe_url === item)
    )

    if (cleanedData.length < 100 && adsFormats !== 'Videos' && cleanedDataCount < 100) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    } else if (adsFormats === 'Videos' && cleanedDataCount < 20) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    }
  }

  useEffect(() => {
    if (adsDatas[0]?.advertiser) {
      filterAndSaveData()
    }
    // eslint-disable-next-line
  }, [adsDatas])
  // end of google ads data
  const [showButton, setShowButton] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  const containerRef = useRef(null)

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
      setShowBackButton(scrollLeft > 0)
      setShowNextButton(scrollLeft < scrollWidth - clientWidth - 1) // -1 for rounding errors
    }
  }

  useEffect(() => {
    checkScrollPosition()
    window.addEventListener('resize', checkScrollPosition)
    return () => window.removeEventListener('resize', checkScrollPosition)
  }, [filteredDiscoverData]) // Re-check when data changes

  const scroll = direction => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 2
      containerRef.current.scrollBy({
        left: direction === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const options = [
    { label: 'Active', value: 'Active', count: isLiveSearch ? activeCount : activeCountRedux },
    {
      label: 'Inactive',
      value: 'Inactive',
      count: isLiveSearch ? inactiveCount : InActiveCountRedux,
    },
  ]
  const handleCheckboxCount = () => {
    const activeCountLoop = adsPreviewData.filter(
      item =>
        item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
    ).length

    const inactiveCountLoop = adsPreviewData.filter(
      item =>
        item?.additional_info?.is_active === false || item?.additional_info?.is_active === 'false'
    ).length
    setActiveCount(activeCountLoop)
    setInActiveCount(inactiveCountLoop)
  }
  const test = () => {
    if (filteredDiscoverData.length === 0) {
      dispatch({ type: ADS_NO_DATA, payload: true })
    } else {
      dispatch({ type: ADS_NO_DATA, payload: false })
    }
  }
  useEffect(() => {
    test()
    // eslint-disable-next-line
  }, [filteredDiscoverData])
  useEffect(() => {
    handleCheckboxCount()
    // eslint-disable-next-line
  }, [adsPreviewData])
  const handleBrandTagsData = async () => {
    try {
      const response = await axiosInstance.get('/save/brand-tags/')
      dispatch({ type: BRANDS_TAGS_DATA, payload: response?.data })
      const filteredBrandsForMeta = response?.data?.discover_ads
        .filter(entry => entry.meta_page_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          meta_page_id: entry?.meta_page_id,
          page_profile_logo: entry?.page_profile_logo,
        }))

      const filteredBrandsForGoogle = response?.data?.discover_ads
        .filter(entry => entry.google_advertiser_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          google_advertiser_id: entry?.google_advertiser_id,
          page_profile_logo: entry?.page_profile_logo,
        }))
      dispatch({ type: BRANDS_TAGS_FOR_META, payload: filteredBrandsForMeta })
      dispatch({ type: BRANDS_TAGS_FOR_GOOGLE, payload: filteredBrandsForGoogle })
    } catch (error) {
      // console.log('🚀 ~ DriveSuccess ~ error:', error)
    }
  }
  useEffect(() => {
    if (discoverAdsPageBrands.length <= 0) {
      handleBrandTagsData()
    }
    // eslint-disable-next-line
  }, [discoverAdsPageBrands])
  useEffect(() => {
    if (checkedValues.length > 0) {
      handleCheckboxChange()
    }
    // eslint-disable-next-line
  }, [checkedValues, adsPreviewData])
  useEffect(() => {
    setCurrentPath(window.location.pathname)
    // eslint-disable-next-line
  }, [])
  const clearState = () => {
    dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({ type: HAS_MORE, payload: false })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
    dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
  }
  useEffect(() => {
    if (brandsTagForAllOrignal.length <= 0) {
      handleBrandTagsData()
    }
    // eslint-disable-next-line
  }, [brandsTagForAllOrignal])
  return (
    <div id="scrollDiv">
      <Text className={metaStyles.heading}>Discover Ads</Text>
      <div
        style={{
          marginTop: 20,
          position: 'sticky',
          top: '-1px',
          zIndex: 99,
        }}
      >
        <SearchBar />
      </div>
      {currentPath !== '/' && (
        <>
          <div
            style={{
              marginTop: 17,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
            }}
          >
            <Text style={{ color: '#939395' }}>
              You’re using a public version of Ads Grader with{' '}
              <span style={{ color: '#67696B', fontWeight: 'bold' }}> 1 free search </span>
              available. | Register to
              <span style={{ fontWeight: 'bold', color: '#FF5E29' }}> unlock 3 free searches </span>
              per day.
            </Text>
            <div style={{ marginLeft: 17 }}>
              <button
                type="submit"
                onClick={handleSignupClick}
                className={metaStyles.createMyAccount}
              >
                CREATE MY ACCOUNT
              </button>
            </div>
          </div>
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
        {/* Brands Section */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1, // Allow Brands to take up remaining space
            overflow: 'hidden',
          }}
        >
          <Text style={{ fontSize: 14, marginRight: 9, color: '#939395' }}>Brands:</Text>
          {showBackButton && (
            <LeftCircleOutlined
              onClick={() => scroll('back')}
              style={{
                cursor: 'pointer',
                marginRight: 10,
                fontSize: '27px',
                color: '#f16434',
              }}
            />
          )}
          <div
            ref={containerRef}
            style={{
              display: 'flex',
              gap: 16,
              overflowX: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              flex: 1,
            }}
            onScroll={checkScrollPosition}
          >
            {
              // eslint-disable-next-line
              selectedChannel === 'All' ? (
                <>
                  {' '}
                  {brandsTagForAll?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClick(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' ? (
                <>
                  {brandsTagForMeta?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClickForMeta(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : selectedChannel === 'Google' ? (
                <>
                  {brandsTagForGoogle?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClickForGoogle(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : (
                ''
              )
            }
          </div>
          {/* {brandsFilter ? (
                <Text onClick={clearState} className={metaStyles.clearFilter}>
                  Clear
                </Text>
              ) : (
                ''
              )} */}

          {showNextButton && (
            <div>
              <RightCircleOutlined
                onClick={() => scroll('next')}
                style={{
                  cursor: 'pointer',
                  marginLeft: 10,
                  marginRight: 10,
                  color: '#f16434',
                  fontSize: '27px',
                  marginTop: '2px',
                }}
              />
            </div>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: 14, marginRight: 9, color: '#939395' }}>Filters: </span>
          <div
            style={{
              background: 'white',
              padding: '5px 18px',
              border: ' 1px solid var(--primary-tint-raisin-black-10, #E9E9EA)',
            }}
          >
            <Checkbox.Group
              options={options.map(option => ({
                label: (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span style={{ color: '#26282C', fontSize: 12 }}>{option.label}</span>
                    <div
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '3.801px 5.068px',
                        fontSize: '12px',
                        color: '#939395',
                        borderRadius: '23.441px',
                        border: '0.634px solid #EBEBEC',
                        background: '#FFF',
                      }}
                    >
                      {option.count}
                    </div>
                  </div>
                ),
                value: option.value,
                // disabled: option.disabled,
              }))}
              value={checkedValues}
              onChange={() => (currentPath !== '/' ? setSignUpModal(true) : handleCheckboxChange)}
              style={{ display: 'flex', gap: 16 }}
            />
          </div>
        </div>
      </div>
      {
        // eslint-disable-next-line
        loading ? (
          <div style={{ textAlign: 'center', marginTop: 150 }}>
            <Spin />
          </div>
        ) : (
          <>
            {filteredDiscoverData.length > 0 ? (
              <InfiniteScroll
                style={{ overflow: 'hidden' }}
                dataLength={filteredDiscoverData.length} // This is the length of the data currently rendered
                next={fetchMoreData} // Function to be called when user reaches bottom
                hasMore={hasMore && !brandsFilter} // Set to false if no more data is available
                loader={
                  newDataSpin ? (
                    <div
                      style={{
                        marginTop: 150,
                        textAlign: 'center',
                      }}
                    >
                      <Spin />
                    </div>
                  ) : (
                    ''
                  )
                } // Loader component when more data is being loaded
                scrollableTarget="scrollableDiv"
                scrollThreshold="35%"
              >
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    350: 1,
                    750: 2,
                    900: 2,
                    1400: 4,
                    1800: 4,
                    2200: 6,
                    2500: 7,
                  }}
                >
                  <Masonry columnsCount={7} gutter="20px">
                    {filteredDiscoverData?.map((item, index) => (
                      <AdsCard
                        adsData={item}
                        onShowModal={() => handleShowModal(index)}
                        onPrevious={handlePrevious}
                        onNext={handleNext}
                        selectedData={filteredDiscoverData[selectedIndex]}
                        isFirst={selectedIndex === 0}
                        isLast={selectedIndex === filteredDiscoverData.length - 1}
                        boardsList={boardsList}
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </InfiniteScroll>
            ) : (
              ''
            )}
          </>
        )
      }
      {adsNoData && !loading ? <SearchResultsError /> : ''}
      {signUpModal ? (
        <CreateYourAccount isModalVisible={signUpModal} onCancel={handleCloseModal} />
      ) : (
        ''
      )}
      {registerModal ? (
        <CreateYourAccount isModalVisible={registerModal} onCancel={handleCloseModal} register />
      ) : (
        ''
      )}
    </div>
  )
}

export default DiscoverAdsFeature
