import React from 'react'
import { Modal, Button, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import styles from './ResendEmail.module.css'

const { Text } = Typography

const ReSendEmail = ({ visible, onClose, onConnect, loading }) => {
  const temp = 0
  return (
    <Modal
      title={
        <>
          <div
            style={{
              textAlign: 'center',
              marginTop: 30,
              fontSize: 24,
              fontWeight: '600',
            }}
          >
            Verification Link Expired
          </div>
          <div className={styles.divider} />
        </>
      }
      visible={visible}
      footer={null}
      centered
    >
      <Text style={{ display: 'block', marginBottom: 20, textAlign: 'center' }}>
        The email verification link has expired. Please click below to resend a new verification
        email.
      </Text>
      <Button
        type="primary"
        className={styles.searchButton}
        onClick={onConnect}
        loading={loading}
        disabled={loading}
      >
        Resend Verification Email
      </Button>
      {/* <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onClose}
        >
          <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} />
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div> */}
    </Modal>
  )
}

export default ReSendEmail
