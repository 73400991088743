export const apiRoutes = {
  login: {
    url: '/accounts/login/',
    method: 'POST',
  },
  register: {
    url: '/accounts/register/',
    method: 'POST',
  },
  customers: {
    url: 'customers/',
    method: 'GET',
  },
  reports: {
    url: 'inviter-customer-reports/',
    method: 'GET',
  },
  forgotPassword: {
    url: '/accounts/password-reset/',
    method: 'POST',
  },
  changePassword: {
    url: '/accounts/password-change/',
    method: 'POST',
  },
  logout: {
    url: '/accounts/logout/',
    method: 'POST',
  },
  passwordResetConfirm: {
    url: '/accounts/password-reset-confirm/',
    method: 'POST',
  },
  googleAuth: {
    url: '/auth/login/',
    method: 'POST',
  },
  metaAuth: {
    url: 'facebook/login/',
    method: 'POST',
  },
  generatePassword: {
    url: '/accounts/password-set/',
    method: 'POST',
  },
  renewAccessToken: {
    url: '/auth/token/refresh/',
    method: 'POST',
  },
  invitedUsersList: {
    url: '/accounts/invitees/',
    method: 'GET',
  },
  deleteInvited: {
    url: id => `/accounts/invitee-delete/${id}`,
    method: 'DELETE',
  },
  deactivateAccount: {
    url: '/accounts/soft-delete/',
    method: 'DELETE',
  },
  acceptInvite: {
    url: '/accounts/accept-invite/',
    method: 'GET',
  },
  generateReport: {
    url: '/reports/',
    method: 'POST',
  },
  roles: {
    url: '/accounts/choices_data/',
    method: 'GET',
  },
  inviters: {
    url: '/accounts/inviter_customers/',
    method: 'GET',
  },
  profileUpload: {
    url: '/accounts/upload_profile_picture/',
    method: 'PUT',
  },
  roleInfo: {
    url: id => `accounts/role_info/?customer_id=${id}`,
    method: 'GET',
  },

  getQuestions: {
    url: `questionnaire/get-questions/`,
    method: 'GET',
  },
  questionsResponse: {
    url: `questionnaire/question-response/`,
    method: 'POST',
  },
  skipQuestions: {
    url: `questionnaire/skip-questionnaire/`,
    method: 'POST',
  },

  reportDashboard: {
    url: `report-share/`,
    method: 'GET',
  },
  dataToHubspot: {
    url: 'accounts/hubspot-reference/',
    method: 'POST',
  },
  learnigCenter: {
    url: 'learning-center/links/',
    method: 'GET',
  },
  extraInsights: {
    url: 'reports-app/extra-insights/',
    method: 'GET',
  },
}
