import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import AdsCard from '@components/DiscoverAds/AdsCard'
import { RequestService } from '@services/requests'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import {
  API_DATA_BOARDS_LIST,
  FETCH_SPECTFIC_BOARD_DATA,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
} from '@utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BoardsDetailsSection = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const singleBoardID = localStorage.getItem('board_detail_id')
  const dispatch = useDispatch()
  const fetchSpecificBoardData = useSelector(state => state?.discoverAds?.fetchSpecificBoardData)
  const [boardsList, setBoardsList] = useState([])
  const [boardsTagsFilter, setBoardsTagsFilter] = useState(fetchSpecificBoardData)
  const [filteredData, setFilteredData] = useState([])
  const fetchSpecificBoardsList = async () => {
    try {
      setLoading(true)
      const response = await RequestService.get(`/create/board-ad-detail/${singleBoardID}/`)
      dispatch({ type: FETCH_SPECTFIC_BOARD_DATA, payload: response?.data?.boards[0]?.ads })
      dispatch({
        type: FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
        payload: response?.data?.boards[0],
      })
      // setBoardsTagsFilter(response?.data?.boards[0]?.ads)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log(':rocket: ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }

  useEffect(() => {
    hanldeDiscoverAdsBoard()
    fetchSpecificBoardsList()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (fetchSpecificBoardData?.length > 0) {
      const sortedData = fetchSpecificBoardData.sort((a, b) => b.is_pinned - a.is_pinned)
      setFilteredData(sortedData)
    }
    // eslint-disable-next-line
  }, [fetchSpecificBoardData])

  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : filteredData.length - 1))
  }

  const handleNext = () => {
    setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : 0))
  }
  useEffect(() => {
    if (fetchSpecificBoardData.length <= 0) {
      history.push('/dashboard/boards')
    }
    // eslint-disable-next-line
  }, [fetchSpecificBoardData])
  return (
    <>
      {fetchSpecificBoardData.length > 0 && (
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            750: 2,
            900: 2,
            1400: 4,
            1800: 4,
            2200: 6,
            2500: 7,
          }}
        >
          <Masonry columnsCount={3} gutter="20px">
            {filteredData?.map((item, index) => (
              <AdsCard
                adsData={item}
                onShowModal={() => handleShowModal(index)}
                onPrevious={handlePrevious}
                onNext={handleNext}
                selectedData={filteredData[selectedIndex]}
                isFirst={selectedIndex === 0}
                isLast={selectedIndex === filteredData.length - 1}
                boardsList={boardsList}
                isAdPinned={item?.is_pinned}
                boardDetailRoute="true"
                savedAdsFlow="true"
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </>
  )
}
export default BoardsDetailsSection
