/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Button, Spin } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { updateSelectedCustomer } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useRollbar } from '@rollbar/react'
import { useForm } from 'react-hook-form'
import { LS_KEY } from 'utils/constants'
import { dataSendToHubspot } from 'services/dataToHubspot'
import { sessionStorage } from '../../services/session-storage'

import 'react-phone-number-input/style.css'
import styles from './styles.module.scss'
import { useUtmParamsStore } from '../../hooks/use-utm-params-store'
import { useRedirectStore } from '../../hooks/use-redirect-store'

// eslint-disable-next-line react/prop-types
const LoginForm = ({
  adSpend,
  adsGraderScore,
  wastedSpendTotal,
  impressionShare,
  currencyCode,
  amount,
  googleAdsCustomerId,
  reportId,
  reportUniqueId,
  location,
}) => {
  const session = localStorage.getItem('np_ads_grader:session')
  const parsedData = JSON.parse(session)
  const provider = parsedData?.user
  let isGooglePresent = false
  if (provider?.social_accounts) {
    isGooglePresent = provider?.social_accounts.some(account => account?.provider === 'google')
  }
  const domainFlow = localStorage.getItem('domain_flow')
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const { reportToken } = useParams()
  const { t } = useTranslation()
  const [country, setCountry] = useState('')

  const { utms, clearUtmStore } = useUtmParamsStore()
  const $form = useRef(null)
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user?.access_token
  const history = useHistory()
  const dispatch = useDispatch()
  const numbersArray = Array.from({ length: 26 }, (_, index) => index + 1)
  const numbersArray2 = Array.from({ length: 22 }, (_, index) => index + 1)
  const region = localStorage.getItem('region')
  const {
    register,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, ...formState },
  } = useForm()
  const [isLoading, setIsLoading] = useState(true)
  const setRedirect = useRedirectStore({ autoRedirect: false })
  const fullReportURL = `${window.location.protocol}//${window.location.hostname}/ads-report/${reportToken}`
  const languageChange = localStorage.getItem('i18nextLng')
  useEffect(() => {
    trigger()
  }, [trigger, languageChange])

  const handleDataToHubspot = async data => {
    if (data) {
      await dataSendToHubspot(data)
    }
  }
  const rollbar = useRollbar()
  const handleFormSubmit = async formData => {
    rollbar.info('Form data', formData)
    if (window.location.host === 'adsgrader.com') {
      const customerFromLocalStorage = localStorage.getItem('customer')
      if (customerFromLocalStorage) {
        const secondPart = localStorage.getItem('customer')
        const manageStatus = localStorage.getItem('customer')
        const secondPartData = JSON.parse(secondPart)
        const manageStatusData = JSON.parse(manageStatus)
        const resourceName = secondPartData.resource_name
        const parts = resourceName.split('/')
        const desiredNumber = parts[1]
        const managerStatus = manageStatusData?.manager

        const dataForHubspot = {
          ...formData,
          'cf-page-url': `${location}/report-generating`,
          page_name: 'Generate report',
          'google-ads-parent-customer-id': desiredNumber,
          'google-ads-customer-manager': managerStatus,
        }
        localStorage.setItem('leadForm', JSON.stringify(false))
        handleDataToHubspot(JSON.stringify(dataForHubspot))

        // if (result) {
        const response = await fetch(
          'https://ag-sf-reports-a74db936893c.herokuapp.com/data/report/webhook/',
          {
            method: 'POST',
            body: JSON.stringify(dataForHubspot),
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        localStorage.setItem('report-generating', true)
        if (!shareBoardLink) {
          if (domainFlow && isGooglePresent) {
            history.push('/dashboard')
          } else {
            history.push('/dashboard/creative-library')
          }
        } else if (shareBoardLink) {
          history.push('/dashboard/boards')
        }
      } else {
        const dataForHubspot = {
          ...formData,
          'cf-page-url': `${location}/report-generating`,
          page_name: 'Generate report',
        }
        localStorage.setItem('leadForm', JSON.stringify(false))
        handleDataToHubspot(JSON.stringify(dataForHubspot))

        // if (result) {
        const response = await fetch(
          'https://ag-sf-reports-a74db936893c.herokuapp.com/data/report/webhook/',
          {
            method: 'POST',
            body: JSON.stringify(dataForHubspot),
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        localStorage.setItem('report-generating', true)
        if (!shareBoardLink) {
          if (domainFlow && isGooglePresent) {
            history.push('/dashboard')
          } else {
            history.push('/dashboard/creative-library')
          }
        } else if (shareBoardLink) {
          history.push('/dashboard/boards')
        }
      }
    } else {
      localStorage.setItem('leadFormData', JSON.stringify(formData))
      localStorage.setItem('report-generating', true)
      if (!shareBoardLink) {
        if (domainFlow && isGooglePresent) {
          history.push('/dashboard')
        } else {
          history.push('/dashboard/creative-library')
        }
      } else if (shareBoardLink) {
        history.push('/dashboard/boards')
      }
    }
    // api call to save user lead form to backend

    const response = await fetch('https://np-ads-grader-api.herokuapp.com/accounts/user-lead/', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }
  const handleBRFormSubmit = async formData => {
    rollbar.info('Brazil Form data', formData)
    const BRFormData = formData
    if (window.location.host === 'adsgrader.com') {
      const customerFromLocalStorage = localStorage.getItem('customer')
      if (customerFromLocalStorage) {
        const secondPart = localStorage.getItem('customer')
        const manageStatus = localStorage.getItem('customer')
        const secondPartData = JSON.parse(secondPart)
        const manageStatusData = JSON.parse(manageStatus)
        const resourceName = secondPartData.resource_name
        const parts = resourceName.split('/')
        const desiredNumber = parts[1]
        const managerStatus = manageStatusData?.manager

        const dataForHubspot = {
          ...formData,
          'cf-page-url': `${location}/report-generating`,
          page_name: 'Generate report',
          'google-ads-customer-id': googleAdsCustomerId,
          'google-ads-parent-customer-id': desiredNumber,
          'google-ads-customer-manager': managerStatus,
        }
        localStorage.setItem('leadForm', JSON.stringify(false))
        handleDataToHubspot(JSON.stringify(dataForHubspot))

        // if (result) {
        const response = await fetch(
          'https://ag-sf-reports-a74db936893c.herokuapp.com/data/report/webhook/',
          {
            method: 'POST',
            body: JSON.stringify(dataForHubspot),
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        localStorage.setItem('report-generating', true)
        if (!shareBoardLink) {
          if (domainFlow && isGooglePresent) {
            history.push('/dashboard')
          } else {
            history.push('/dashboard/creative-library')
          }
        } else if (shareBoardLink) {
          history.push('/dashboard/boards')
        }
      } else {
        const dataForHubspot = {
          ...formData,
          'cf-page-url': `${location}/report-generating`,
          page_name: 'Generate report',
        }
        localStorage.setItem('leadForm', JSON.stringify(false))
        handleDataToHubspot(JSON.stringify(dataForHubspot))

        // if (result) {
        const response = await fetch(
          'https://ag-sf-reports-a74db936893c.herokuapp.com/data/report/webhook/',
          {
            method: 'POST',
            body: JSON.stringify(dataForHubspot),
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        localStorage.setItem('report-generating', true)
        if (!shareBoardLink) {
          if (domainFlow && isGooglePresent) {
            history.push('/dashboard')
          } else {
            history.push('/dashboard/creative-library')
          }
        } else if (shareBoardLink) {
          history.push('/dashboard/boards')
        }
      }
    } else {
      localStorage.setItem('leadFormData', JSON.stringify(formData))
      localStorage.setItem('report-generating', true)
      if (!shareBoardLink) {
        if (domainFlow && isGooglePresent) {
          history.push('/dashboard')
        } else {
          history.push('/dashboard/creative-library')
        }
      } else if (shareBoardLink) {
        history.push('/dashboard/boards')
      }
    }
    // api call to save user lead form to backend
    const response = await fetch('https://np-ads-grader-api.herokuapp.com/accounts/user-lead/', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }

  const getCountry = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      sessionStorage.set('country', data.country)
      setCountry(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      // eslint-disable-next-line no-console

      rollbar.error('IPINFO error in Login Page', e)
    }
    // eslint-disable-next-line
  }, [])
  const [showAdditionalFields, setShowAdditionalFields] = useState(false)
  const returnUtmMedium = _utmMedium => {
    switch (_utmMedium) {
      case 'event':
        return 'Tradeshow'
      case 'smpd':
        return 'Paid Social'
      case 'ref':
        return 'Referral Traffic'
      case 'sem':
        return 'Paid Search'
      case 'pm':
        return 'Paid Media'
      case 'social':
        return 'Social Media'
      case 'email':
        return 'Email'
      case 'spartner':
        return 'Strategic Partner'
      case 'rpartner':
        return 'Referral Partner'
      case '3p':
        return 'Third Party'
      case 'push':
        return 'Push Notification'
      case 'ubs':
        return 'Ubersuggest'
      case 'emref':
        return 'Employee Referral'
      case 'atp':
        return 'Answer the Public'
      default:
        return 'Direct Traffic'
    }
  }

  useEffect(() => {
    if (user) {
      setValue('cf-fname', user?.user?.first_name)
      setValue('cf-lname', user?.user?.last_name)
      setValue('email', user?.user?.email)
      setValue('cf-phone', user?.user?.profile?.phone_number)
      setValue('cf-url', user?.user?.profile?.website)
    }
    if (JSON.parse(localStorage.getItem('url'))) {
      setValue('cf-url', JSON.parse(localStorage.getItem('url')).email)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    getCountry()
  }, [getCountry])

  useEffect(() => {
    // if (region !== 'BR') {
    const UTMData = localStorage.getItem('ads_grader_saved_utms')
    if (UTMData) {
      const jsonData = UTMData.substring(UTMData.indexOf('{'), UTMData.lastIndexOf('}') + 1)

      // Parsing the JSON into an object
      const dataObject = JSON.parse(jsonData)

      const terms = dataObject.utm_term
      const content = dataObject.utm_content
      const source = dataObject.utm_source
      const medium = dataObject.utm_medium
      const campaign = dataObject.utm_campaign
      const ref = dataObject.utm_ref || dataObject.cf_ref
      const refExt = dataObject.utm_ref_ext || dataObject.cf_ref_ext

      if (
        terms !== null ||
        content !== null ||
        source !== null ||
        medium !== null ||
        campaign !== null ||
        ref !== null ||
        refExt !== null
      ) {
        setValue('cf-utm-source', source || '')
        setValue('cf-utm-medium', medium || '')
        setValue('cf-utm-content', content || '')
        setValue('cf-utm-term', terms || '')
        setValue('cf-ref', ref || '')
        setValue('cf-utm-campaign', campaign || '')
        setValue('cf-ref-ext', refExt || '')
        setValue('cf-utm-recent-source', source || '')
        setValue('cf-utm-recent-medium', medium || '')
        setValue('cf-utm-recent-content', content || '')
        setValue('cf-utm-recent-term', terms || '')
        setValue('cf-utm-recent-campaign', campaign || '')
      }
    }

    // }
  }, [setValue, country, region])
  useEffect(() => {
    // setValue('google-ads-customer-manager', googleAdsCustomerManager)
    setValue('cf-form-type', 'adsgrader')
    if (currencyCode) {
      setValue('currency-code', currencyCode)
    }
    if (amount) {
      setValue('currency-amount', amount)
    }
    if (adSpend) {
      setValue('ad-spend-month', adSpend)
    }
    if (adsGraderScore) {
      setValue('ads-grader-score', adsGraderScore)
    }
    if (impressionShare) {
      setValue('impression-share', impressionShare)
    }
    if (country) {
      setValue('cf-timezone', country?.timezone)
      setValue('cf-ip-city', country?.city)
      setValue('cf-ip-region', country?.region)
      setValue('cf-lang-geo', country?.country.toLowerCase())
    }
    if (wastedSpendTotal) {
      setValue('wasted-spend-total', wastedSpendTotal)
    }
    if (googleAdsCustomerId) {
      setValue('google-ads-customer-id', googleAdsCustomerId)
    }
    if (reportId) {
      setValue('report-id', reportId)
    }
    if (reportUniqueId) {
      setValue('report-unique-id', reportUniqueId)
    }

    // setValue('google-ads-customer-manager', googleAdsCustomerManager)
  }, [
    // timeZone,
    adSpend,
    country,
    region,
    adsGraderScore,
    impressionShare,
    wastedSpendTotal,
    setValue,
    currencyCode,
    amount,
    googleAdsCustomerId,
    reportId,
    reportUniqueId,
  ])

  useEffect(() => {
    if (fullReportURL) {
      setValue('cf-url-report-ads-grader', fullReportURL)
    }
  }, [fullReportURL, setValue, region])
  const handleMonthlyBudgetChange = e => {
    if (e.target.value === 'Under $750' || e.target.value === '$750 to $1,500') {
      setShowAdditionalFields(true)
    } else {
      setShowAdditionalFields(false)
    }
  }
  const firstNamehandleKeyPress = e => {
    const charCode = e.which || e.keyCode
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault()
    }
  }
  const lastNamehandleKeyPress = e => {
    const charCode = e.which || e.keyCode
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault()
    }
  }

  return (
    <>
      {
        // eslint-disable-next-line
        isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : country?.country === 'BR' ? (
          <form onSubmit={handleSubmit(handleBRFormSubmit)}>
            <Row gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder="Nome"
                  aria-invalid={!!(errors && errors['cf-fname'])}
                  {...register('cf-fname', {
                    required: t('required'),
                    pattern: {
                      value: /^[^0-9]+$/,
                    },
                  })}
                  onKeyPress={firstNamehandleKeyPress}
                />

                {errors && errors['cf-fname'] ? (
                  <span className={styles.errorText}>{errors['cf-fname'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder="Sobrenome"
                  aria-invalid={!!(errors && errors['cf-lname'])}
                  {...register('cf-lname', {
                    required: t('required'),
                    pattern: {
                      value: /^[^0-9]+$/,
                    },
                  })}
                  onKeyPress={lastNamehandleKeyPress}
                />

                {errors && errors['cf-lname'] ? (
                  <span className={styles.errorText}>{errors['cf-lname'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  type="email"
                  placeholder="Email"
                  aria-invalid={!!errors && errors.email}
                  {...register('email', {
                    required: t('required'),
                    pattern: {
                      value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: t('errors.emailPattern'),
                    },
                  })}
                />

                {errors && errors.email ? (
                  <span className={styles.errorText}>{errors && errors.email.message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder="Site"
                  aria-invalid={!!(errors && errors['cf-url'])}
                  {...register('cf-url', {
                    required: t('required'),
                    pattern: {
                      value: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                      message: t('errors.urlPattern'),
                    },
                  })}
                />
                {errors && errors['cf-url'] ? (
                  <span className={styles.errorText}>{errors['cf-url'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <PhoneInputWithCountry
                  className={styles.phoneField}
                  placeholder="Telefone"
                  defaultCountry={country?.country}
                  name="cf-phone"
                  control={control}
                  international="true"
                  rules={{
                    required: t('required'),
                    validate: {
                      isValid: v => isPossiblePhoneNumber(v) === true || t('errors.phonePattern'),
                    },
                  }}
                />

                {errors && errors['cf-phone'] ? (
                  <span className={styles.errorText}>{errors['cf-phone'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Porte da Empresa"
                  aria-invalid={!!(errors && errors['cf-employee-numbers'])}
                  {...register('cf-employee-numbers', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    Porte da Empresa
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Até 2 funcionários">Até 2 funcionários</option>
                  <option value="De 3 à 25 funcionários">De 3 à 25 funcionários</option>
                  <option value="De 26 à 50 funcionários">De 26 à 50 funcionários</option>
                  <option value="De 51 à 350 funcionários">De 51 à 350 funcionários</option>
                  <option value="De 351 à 999 funcionários">De 351 à 999 funcionário</option>
                  <option value="Acima de 1000 funcionários">Acima de 1000 funcionários</option>
                </select>
                {errors && errors['cf-employee-numbers'] ? (
                  <span className={styles.errorText}>{errors['cf-employee-numbers'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Quanto sua empresa investe mensalmente em marketing digital?"
                  aria-invalid={!!(errors && errors['cf-budget'])}
                  {...register('cf-budget', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    Quanto sua empresa investe mensalmente em marketing digital?
                  </option>
                  <option value="Ainda não invisto">Ainda não invisto</option>
                  <option value="Até R$5.000,00">Até R$5.000,00</option>
                  <option value="De R$5.000,00 à R$20.000,00">De R$5.000,00 à R$20.000,00</option>
                  <option value="De R$21.000,00 à R$50.000,00">De R$21.000,00 à R$50.000,00</option>
                  <option value="Acima de R$50.000,00">Acima de R$50.000,00</option>
                </select>

                {errors && errors['cf-budget'] ? (
                  <span className={styles.errorText}>{errors['cf-budget'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Cargo:"
                  aria-invalid={!!(errors && errors['cf-job-title'])}
                  {...register('cf-job-title', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    Cargo
                  </option>
                  <option value="Dono/CEO/Sócio">Dono/CEO/Sócio</option>
                  <option value="Diretor/Gerente/Coordenador">Diretor/Gerente/Coordenador</option>
                  <option value="Analista/Assistente">Analista/Assistente</option>
                  <option value="Freelancer/Consultor">Freelancer/Consultor</option>
                  <option value="Estudante/Estagiário">Estudante/Estagiário</option>
                  <option value="Outros">Outros</option>
                </select>
                {errors && errors['cf-job-title'] ? (
                  <span className={styles.errorText}>{errors['cf-job-title'].message}</span>
                ) : null}
              </Col>
              {/* Temp field */}
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Monthly Marketing Budget"
                  aria-invalid={!!(errors && errors['cf-industry'])}
                  {...register('cf-industry', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    {t('loginForm.industryDropdown.opt.default')}
                  </option>
                  {numbersArray.map((number, index) => {
                    const optionValue = t(`loginForm.industryDropdown.opt.${number}`)
                    return (
                      // eslint-disable-next-line
                      <option key={index} value={optionValue}>
                        {optionValue}
                      </option>
                    )
                  })}
                </select>

                {errors && errors['cf-industry'] ? (
                  <span className={styles.errorText}>{errors['cf-industry'].message}</span>
                ) : null}
              </Col>
              {/* Temp field */}
              <Col span={24}>
                <Button htmlType="submit" type="primary" block>
                  {domainFlow && isGooglePresent ? (
                    <> {t('loginForm.buttonText')}</>
                  ) : (
                    <>SEE AD RESULTS</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        ) : (
          <form
            // ref={$form}
            // method="post"
            // action="https://go.advanced.npdigital.com/l/932143/2022-10-28/451bj1"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <input type="hidden" {...register('cf-form-type')} />
            <input type="hidden" {...register('currency-code')} />
            <input type="hidden" {...register('currency-amount')} />
            <input type="hidden" {...register('google-ads-customer-id')} />
            <input type="hidden" {...register('report-id')} />
            <input type="hidden" {...register('report-unique-id')} />

            <input type="hidden" {...register('cf-company-name', { value: 'Not provided' })} />
            {/* <input
          type="hidden"
          {...register('cf-source-detail', {
            value: 'US - Mkt Campaigns - MQL - Tool - Ads Grader',
          })}
        /> */}
            <input type="hidden" {...register('cf-timezone')} />
            <input type="hidden" {...register('cf-ip', { value: `${country?.ip}` })} />
            <input type="hidden" {...register('ad-spend-month')} />
            <input type="hidden" {...register('wasted-spend-total')} />
            <input type="hidden" {...register('impression-share')} />
            <input type="hidden" {...register('ads-grader-score')} />
            <input type="hidden" {...register('cf-lang-geo')} />

            <input type="hidden" {...register('cf-source')} />

            <input type="hidden" {...register('cf-utm-source')} />
            <input type="hidden" {...register('cf-utm-medium')} />
            <input type="hidden" {...register('cf-utm-content')} />
            <input type="hidden" {...register('cf-utm-term')} />
            <input type="hidden" {...register('cf-ref')} />
            <input type="hidden" {...register('cf-ref-ext')} />
            <input type="hidden" {...register('cf-utm-campaign')} />

            <input type="hidden" {...register('cf-utm-recent-source')} />
            <input type="hidden" {...register('cf-utm-recent-medium')} />
            <input type="hidden" {...register('cf-utm-recent-content')} />
            <input type="hidden" {...register('cf-utm-recent-term')} />
            <input type="hidden" {...register('cf-utm-recent-campaign')} />
            <input type="hidden" {...register('cf-url-report-ads-grader')} />
            <Row gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  type="email"
                  placeholder={t('loginForm.placeholder.email')}
                  aria-invalid={!!errors && errors.email}
                  {...register('email', {
                    required: t('required'),
                    pattern: {
                      value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: t('errors.emailPattern'),
                    },
                  })}
                />

                {errors && errors.email ? (
                  <span className={styles.errorText}>{errors && errors.email.message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <PhoneInputWithCountry
                  className={styles.phoneField}
                  placeholder={t('loginForm.placeholder.phone')}
                  defaultCountry={country?.country}
                  name="cf-phone"
                  control={control}
                  international="true"
                  rules={{
                    required: t('required'),
                    validate: {
                      isValid: v => isPossiblePhoneNumber(v) === true || t('errors.phonePattern'),
                    },
                  }}
                />

                {errors && errors['cf-phone'] ? (
                  <span className={styles.errorText}>{errors['cf-phone'].message}</span>
                ) : null}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder={t('loginForm.placeholder.firstName')}
                  aria-invalid={!!(errors && errors['cf-fname'])}
                  // {...register('cf-fname', { required: t('required') })}
                  {...register('cf-fname', {
                    required: t('required'),
                    pattern: {
                      value: /^[^0-9]+$/,
                    },
                  })}
                  onKeyPress={firstNamehandleKeyPress}
                />

                {errors && errors['cf-fname'] ? (
                  <span className={styles.errorText}>{errors['cf-fname'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder={t('loginForm.placeholder.lastName')}
                  aria-invalid={!!(errors && errors['cf-lname'])}
                  // {...register('cf-lname', { required: t('required') })}
                  {...register('cf-lname', {
                    required: t('required'),
                    pattern: {
                      value: /^[^0-9]+$/,
                    },
                  })}
                  onKeyPress={lastNamehandleKeyPress}
                />
                {errors && errors['cf-lname'] ? (
                  <span className={styles.errorText}>{errors['cf-lname'].message}</span>
                ) : null}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <input
                  className={styles.fakeAntInput}
                  placeholder={t('loginForm.placeholder.website')}
                  aria-invalid={!!(errors && errors['cf-url'])}
                  {...register('cf-url', {
                    required: t('required'),
                    pattern: {
                      value: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                      message: t('errors.urlPattern'),
                    },
                  })}
                />
                {errors && errors['cf-url'] ? (
                  <span className={styles.errorText}>{errors['cf-url'].message}</span>
                ) : null}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Monthly Marketing Budget"
                  aria-invalid={!!(errors && errors['cf-budget'])}
                  {...register('cf-budget', { required: t('required') })}
                  onChange={handleMonthlyBudgetChange}
                >
                  <option value="" disabled selected>
                    {t('loginForm.monthly.opt.default')}
                  </option>
                  <option value="Under $750">{t('loginForm.monthly.opt.1')}</option>
                  <option value="$750 to $1,500">{t('loginForm.monthly.opt.2')}</option>
                  <option value="$1,500 to $5,000">{t('loginForm.monthly.opt.3')}</option>
                  <option value="$5,000 to $10,000">{t('loginForm.monthly.opt.4')}</option>
                  <option value="$10,000 to $25,000">{t('loginForm.monthly.opt.5')}</option>
                  <option value="$25,000 to $50,000">{t('loginForm.monthly.opt.6')}</option>
                  <option value="$50,000 to $100,000">{t('loginForm.monthly.opt.7')}</option>
                  <option value="Above $100,000">{t('loginForm.monthly.opt.8')}</option>
                </select>

                {errors && errors['cf-budget'] ? (
                  <span className={styles.errorText}>{errors['cf-budget'].message}</span>
                ) : null}
              </Col>

              {/* <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Annual Revenue"
                  aria-invalid={!!(errors && errors['cf-revenue'])}
                  {...register('cf-revenue', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    {t('loginForm.annualRev.opt.default')}
                  </option>
                  <option value="$0 to $500 thousand">{t('loginForm.annualRev.opt.1')}</option>
                  <option value="$500 thousand to $1 million">
                    {t('loginForm.annualRev.opt.2')}
                  </option>
                  <option value="$1 to $3 million">{t('loginForm.annualRev.opt.3')}</option>
                  <option value="$3 to $10 million">{t('loginForm.annualRev.opt.4')}</option>
                  <option value="$10 million to $50 million">
                    {t('loginForm.annualRev.opt.5')}
                  </option>
                  <option value="$50 million to $100 million">
                    {t('loginForm.annualRev.opt.6')}
                  </option>
                  <option value="Above $100 million">{t('loginForm.annualRev.opt.7')}</option>
                </select>

                {errors && errors['cf-revenue'] ? (
                  <span className={styles.errorText}>{errors['cf-revenue'].message}</span>
                ) : null}
              </Col> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Marketing team size"
                  aria-invalid={!!(errors && errors['cf-marketing-team-size'])}
                  {...register('cf-marketing-team-size', { required: t('required') })}
                  // onChange={handleMonthlyBudgetChange}
                >
                  <option value="" disabled selected>
                    {t('loginForm.marketingTeam.opt.default')}
                  </option>
                  <option value="I don’t have a marketing team ">
                    {t('loginForm.marketingTeam.opt.1')}
                  </option>
                  <option value="1 person">{t('loginForm.marketingTeam.opt.2')}</option>
                  <option value="2-5 people">{t('loginForm.marketingTeam.opt.3')}</option>
                  <option value="6-10 people">{t('loginForm.marketingTeam.opt.4')}</option>
                  <option value="10+ people">{t('loginForm.marketingTeam.opt.5')}</option>
                </select>

                {errors && errors['cf-marketing-team-size'] ? (
                  <span className={styles.errorText}>
                    {errors['cf-marketing-team-size'].message}
                  </span>
                ) : null}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Business Type"
                  aria-invalid={!!(errors && errors['cf-business-typ'])}
                  {...register('cf-business-typ', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    {t('loginForm.businessType.opt.default')}
                  </option>
                  <option value="Ecommerce">{t('loginForm.businessType.opt.1')}</option>
                  <option value="Professional Services">{t('loginForm.businessType.opt.2')}</option>
                  <option value="SaaS">{t('loginForm.businessType.opt.3')}</option>
                  <option value="Other">{t('loginForm.businessType.opt.4')}</option>
                </select>
                {errors && errors['cf-business-typ'] ? (
                  <span className={styles.errorText}>{errors['cf-business-typ'].message}</span>
                ) : null}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <select
                  className={styles.fakeAntInput}
                  placeholder="Monthly Marketing Budget"
                  aria-invalid={!!(errors && errors['cf-industry'])}
                  {...register('cf-industry', { required: t('required') })}
                >
                  <option value="" disabled selected>
                    {t('loginForm.industryDropdown.opt.default')}
                  </option>
                  {numbersArray2.map((number, index) => {
                    const optionValue = t(`loginForm.industryDropdown.opt.${number}`)
                    return (
                      // eslint-disable-next-line
                      <option key={index} value={optionValue}>
                        {optionValue}
                      </option>
                    )
                  })}
                </select>

                {errors && errors['cf-industry'] ? (
                  <span className={styles.errorText}>{errors['cf-industry'].message}</span>
                ) : null}
              </Col>
              {/* start of the conditional fields */}
              {showAdditionalFields && (
                <>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <select
                      className={styles.fakeAntInput}
                      placeholder="Challenges"
                      aria-invalid={!!(errors && errors['cf-challenges'])}
                      {...register('cf-challenges', { required: t('required') })}
                    >
                      <option value="" disabled selected>
                        {t('loginForm.challenges.opt.default')}
                      </option>
                      <option value="Need more awareness and traffic">
                        {t('loginForm.challenges.opt.1')}
                      </option>
                      <option value="Not enough leads">{t('loginForm.challenges.opt.2')}</option>
                      <option value="Leads not converting into sales">
                        {t('loginForm.challenges.opt.3')}
                      </option>
                      <option value="The current strategy is not working">
                        {t('loginForm.challenges.opt.4')}
                      </option>
                      <option value="A mix of the above">{t('loginForm.challenges.opt.5')}</option>
                    </select>
                    {errors && errors['cf-challenges'] ? (
                      <span className={styles.errorText}>{errors['cf-challenges'].message}</span>
                    ) : null}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <select
                      className={styles.fakeAntInput}
                      placeholder="Number of employees"
                      aria-invalid={!!(errors && errors['cf-employee-numbers'])}
                      {...register('cf-employee-numbers', { required: t('required') })}
                    >
                      <option value="" disabled selected>
                        {t('loginForm.numberOfEmployees.opt.default')}
                      </option>
                      <option value="1 - 5">{t('loginForm.numberOfEmployees.opt.1')}</option>
                      <option value="6 - 25">{t('loginForm.numberOfEmployees.opt.2')}</option>
                      <option value="26 - 100">{t('loginForm.numberOfEmployees.opt.3')}</option>
                      <option value="100+">{t('loginForm.numberOfEmployees.opt.4')}</option>
                    </select>
                    {errors && errors['cf-employee-numbers'] ? (
                      <span className={styles.errorText}>
                        {errors['cf-employee-numbers'].message}
                      </span>
                    ) : null}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <select
                      className={styles.fakeAntInput}
                      placeholder="Your Urgency for hiring help"
                      aria-invalid={!!(errors && errors['cf-urgency'])}
                      {...register('cf-urgency', { required: t('required') })}
                    >
                      <option value="" disabled selected>
                        {t('loginForm.urgency.opt.default')}
                      </option>
                      <option value="I need help IMMEDIATELY">
                        {t('loginForm.urgency.opt.1')}
                      </option>
                      <option value="I need to start working on it THIS QUARTER">
                        {t('loginForm.urgency.opt.2')}
                      </option>
                      <option value="I have 6 MONTHS to decide">
                        {t('loginForm.urgency.opt.3')}
                      </option>
                      <option value="I have more than A YEAR">
                        {t('loginForm.urgency.opt.4')}
                      </option>
                      <option value="Gathering information for DIY">
                        {t('loginForm.urgency.opt.5')}
                      </option>
                      <option value="Not a priority">{t('loginForm.urgency.opt.6')}</option>
                    </select>
                    {errors && errors['cf-urgency'] ? (
                      <span className={styles.errorText}>{errors['cf-urgency'].message}</span>
                    ) : null}
                  </Col>
                </>
              )}
              {/* end of the conditional fields */}
              <Col span={24}>
                <Button htmlType="submit" type="primary" block>
                  {domainFlow && isGooglePresent ? (
                    <> {t('loginForm.buttonText')}</>
                  ) : (
                    <>SEE AD RESULTS</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        )
      }
    </>
  )
}

export { LoginForm }
