import React, { useState } from 'react'
import { Form, Input, Button, Checkbox } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Text from '@components/Text/text'
import Modal from '@components/Modal'
import { axiosInstance } from '@services/requests'
import { notify } from '@utils/notify'
import { updateLocalStorage } from '@utils/helperFunctions'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { updateUserData } from '../../../store/actions'
import styles from './Register.module.css'
import RegistrationSuccesfulModal from '../RegistratonSuccesfulModal'

const validationSchema = Yup.object({
  firstName: Yup.string()
    .transform(value => value.trim()) // Trim spaces from start and end
    .min(3, 'First Name must be at least 3 characters')
    .matches(
      /^[a-zA-Z@.+\-_ ]*$/,
      'First Name can only contain letters, spaces, @, ., +, -, and _ characters'
    )
    .required('First Name is required'),
  lastName: Yup.string()
    .transform(value => value.trim()) // Trim spaces from start and end
    .min(3, 'Last Name must be at least 3 characters')
    .matches(
      /^[a-zA-Z@.+\-_ ]*$/,
      'Last Name can only contain letters, spaces, @, ., +, -, and _ characters'
    )
    .required('Last Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  terms: Yup.boolean().oneOf([true], 'You must agree to the Privacy Policy'),
  marketing: Yup.boolean().oneOf([true], 'You must agree to receive emails and phone calls'),
})

const Register = ({ onNavigate }) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleCancel = () => {
    setShowModal(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          confirmPassword: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          terms: false,
          marketing: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async values => {
          // Replace spaces with underscores in firstName and lastName
          const formattedFirstName = values.firstName.replace(/\s+/g, '_')
          const formattedLastName = values.lastName.replace(/\s+/g, '_')

          // Create the username by concatenating the formatted names
          const data = {
            username: `${formattedFirstName}_${formattedLastName}`,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            password: values.password,
          }

          setIsLoading(true)

          try {
            const res = await axiosInstance.post('/accounts/email-register/', data)
            if (res.status === 201) {
              setIsLoading(false)
              if (res.data.access_token) {
                const userData = { ...res.data }
                updateLocalStorage(userData)
                dispatch(updateUserData(userData))
                // history.push('/lead-form')
              } else {
                setShowModal(true)
              }
            }
          } catch (e) {
            setIsLoading(false)
            if (e?.response?.data?.email?.length) {
              notify(e.response.data.email[0], 'error')
            } else {
              notify('Something went wrong', 'error')
            }
          }
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            {/* First Name Field */}
            <Form.Item
              label={
                // eslint-disable-next-line
                <label htmlFor="firstName" className={styles.inputHeader}>
                  First Name <span className={styles.requiredAsterisk}>*</span>
                </label>
              }
            >
              <Field
                id="firstName"
                name="firstName"
                placeholder="Enter first name"
                as={Input}
                className={errors.firstName && touched.firstName ? styles.errorInput : ''}
              />
              <ErrorMessage name="firstName">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>

            {/* Last Name Field */}
            <Form.Item
              label={
                // eslint-disable-next-line
                <label htmlFor="lastName" className={styles.inputHeader}>
                  Last Name <span className={styles.requiredAsterisk}>*</span>
                </label>
              }
            >
              <Field
                id="lastName"
                name="lastName"
                placeholder="Enter last name"
                as={Input}
                className={errors.lastName && touched.lastName ? styles.errorInput : ''}
              />
              <ErrorMessage name="lastName">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>

            {/* Email Field */}
            <Form.Item
              label={
                // eslint-disable-next-line
                <label htmlFor="email" className={styles.inputHeader}>
                  Email <span className={styles.requiredAsterisk}>*</span>
                </label>
              }
            >
              <Field
                id="email"
                name="email"
                placeholder="Enter your email"
                as={Input}
                className={errors.email && touched.email ? styles.errorInput : ''}
              />
              <ErrorMessage name="email">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>

            {/* Password Field */}
            <Form.Item
              label={
                // eslint-disable-next-line
                <label htmlFor="password" className={styles.inputHeader}>
                  Password <span className={styles.requiredAsterisk}>*</span>
                </label>
              }
            >
              <Field
                id="password"
                name="password"
                placeholder="Enter your password"
                as={Input.Password}
                className={errors.password && touched.password ? styles.errorInput : ''}
              />
              <ErrorMessage name="password">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>

            {/* Confirm Password */}
            <Form.Item
              label={
                // eslint-disable-next-line
                <label htmlFor="confirmPassword" className={styles.inputHeader}>
                  Confirm Password <span className={styles.requiredAsterisk}>*</span>
                </label>
              }
            >
              <Field
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Re-enter your password"
                as={Input.Password}
                className={
                  errors.confirmPassword && touched.confirmPassword ? styles.errorInput : ''
                }
              />
              <ErrorMessage name="confirmPassword">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>

            {/* Terms and Privacy */}
            <Form.Item className={styles.noMargin}>
              <Field name="terms" type="checkbox" as={Checkbox} style={{ fontSize: 12 }}>
                I agree to the
                <Link to="/privacy" target="_blank" className={styles.privacyPolicy}>
                  &nbsp;Privacy Policy
                </Link>
                &nbsp;&
                <Link to="/terms" target="_blank" className={styles.privacyPolicy}>
                  &nbsp;Terms of service
                </Link>
              </Field>
              <ErrorMessage name="terms">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>
            <Form.Item className={styles.noMargin}>
              <Field name="marketing" type="checkbox" as={Checkbox} style={{ fontSize: 12 }}>
                I agree to receive emails and phone calls teaching me how to use Ads Grader and grow
                my traffic.
              </Field>
              <ErrorMessage name="marketing">
                {msg => <div className={styles.errorMessage}>{msg}</div>}
              </ErrorMessage>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className={styles.searchButton}
                htmlType="submit"
                block
                loading={isLoading}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>

      {/* Login Link */}
      <div style={{ textAlign: 'center' }}>
        <Text size={14}>
          Already have an account?{' '}
          <span
            role="button"
            tabIndex="0"
            className={styles.registerHere}
            onClick={() => onNavigate('login')}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                onNavigate('login')
              }
            }}
          >
            Log in
          </span>
        </Text>
      </div>

      {/* Modal */}
      <RegistrationSuccesfulModal
        visible={showModal}
        onClose={() => onNavigate('login')}
        onCancel={handleCancel}
      />
    </>
  )
}

export default Register
