import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { axiosInstance } from '@services/requests'
import { notify } from '@utils/notify'
import Modal from '@components/Modal'
import { updateLocalStorage } from '@utils/helperFunctions'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../../store/actions'
import ReSendEmail from './ReSendEmail'

const AcceptLoginInvite = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const userId = queryParams.get('user_id')
  const userToken = queryParams.get('user_token')

  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    // setIsModalVisible(false)
  }

  const handleVerifyUser = async () => {
    try {
      const response = await axiosInstance.get(
        `/accounts/email-verify/?user_id=${userId}&user_token=${userToken}`
      )
      const extractedData = {
        access_token: response?.data?.access_token,
        refresh_token: response?.data?.refresh_token,
        user: response?.data?.user,
        isGoogleUser: false,
      }
      const userData = extractedData
      updateLocalStorage(userData)
      dispatch(updateUserData(userData))
      history.push('/dashboard/creative-library')
    } catch (error) {
      if (error.response.status === 400) {
        if (error.response.data.non_field_errors[0] === 'Invalid or expired token.') {
          notify('Link expired', 'error')
          setIsModalVisible(true)
        } else if (error.response.data.non_field_errors[0] === 'User already registered.') {
          notify('User already registered', 'error')
          history.push('/login')
        }
      } else {
        notify('Something went wrong', 'error')
        history.push('/login')
      }
    }
  }
  const handleConnect = async () => {
    if (userId) {
      setLoading(true)
      try {
        const data = {
          user_id: userId,
        }
        const response = await axiosInstance.post('accounts/resend-verification-emails/', data)
        setIsModalVisible(false)
        setEmailModal(true)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notify('Something went wrong', 'error')
      }
    }
  }
  const handleCancel = () => {
    setEmailModal(false)
    history.push('/')
  }
  useEffect(() => {
    if (userId && userToken) {
      handleVerifyUser()
    }
    // eslint-disable-next-line
  }, [userId, userToken])
  return (
    <>
      {isModalVisible && (
        <ReSendEmail
          visible={isModalVisible}
          onClose={handleClose}
          onConnect={handleConnect}
          loading={loading}
        />
      )}
      {emailModal && (
        <Modal
          open={emailModal}
          heading="Verification Email Sent Successfully"
          description="Please verify your email to use our services without any delay."
          buttonText="Back to Login"
          onCancel={handleCancel}
        />
      )}
    </>
  )
}
export default AcceptLoginInvite
