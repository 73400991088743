import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Text } from '@components'
import styles from './FooterNewdesign.module.css'

const FooterNewDesign = () => {
  const temp = 0
  return (
    <div
      style={{
        background: '#26282C',
        height: 90,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 40px',
        // marginTop: 61,
      }}
    >
      <div className={styles.logo} id="header">
        <h3 className={styles.logoText} style={{ color: 'white' }}>
          Ads Grader
        </h3>
        <p className={`${styles.bold} ${styles.logoHelperText}`} style={{ color: 'white' }}>
          by <span className={styles.NP}>NP</span> digital
        </p>
      </div>
      {/* Right side of the footer */}
      <div style={{ display: 'flex', gap: '12px', marginRight: 200, alignItems: 'center' }}>
        <Text className={styles.allrights}>
          © 2025 <span style={{ fontWeight: 'bold' }}>Ads Grader.</span>
        </Text>
        <Link to="/privacy" target="_blank" className={styles.privacyPolicy}>
          Privacy Policy
        </Link>
        <Text style={{ color: 'white' }}>|</Text>

        <Link to="/terms" target="_blank" className={styles.privacyPolicy}>
          Terms of Service
        </Link>
      </div>
    </div>
  )
}

export default FooterNewDesign
