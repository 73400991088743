import { UPDATE_ROLE, CURRENT_REPORT_ID, USER_EMIAL } from '@utils/constants'

const initialState = {
  role: true,
  currentReportId: '',
  email: '',
}

export const userRole = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROLE:
      return { ...state, role: action.payload }
    case CURRENT_REPORT_ID:
      return { ...state, currentReportId: action.payload }
    case USER_EMIAL:
      return { ...state, email: action.payload }

    default:
      return state
  }
}
