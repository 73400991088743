import {
  ADS_LOCATION,
  ADD_BOARD,
  SET_BOARDS,
  SAVE_AD_TO_BOARD,
  RENAME_BOARD,
  DELETE_BOARD,
  BOARDS_SEARCH_KEYWORD,
  ADS_CHANNEL,
  ADS_FORMAT,
  ADS_SEARCH_KEYWORD,
  SPINNER,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  ADS_DATA,
  ADS_LOADER,
  CLEAR_STATE,
  TEMP_COUNT,
  ADS_PAGINATION,
  FOLLOWED_BRANDS_LIST,
  BOARD_DETAIL_DATA,
  ADS_DATA_FOR_NO_DATA_FOUND,
  BOARD_SEARCH,
  BOARDS_LIST,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  EMPTY_ADS_PREVIEW_DATA,
  TAGS,
  FILTERED_TAGS,
  CLEAR_BRANDS_FLITER,
  BOARD_LIST,
  BOARDS_TAG_FILTER,
  MORE_DATA_LOADER,
  BOARDS_LIST_COUNT,
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  API_DATA_BOARDS_LIST,
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES_LOADER,
  RELATED_QUERIES,
  SHARED_BOARDS,
  SHARED_AND_PRIVATE_BOARDS_COUNT,
  ACTIVE_KEY,
  SAVED_DESCRIPTION,
  INVITED_BOARDS_USERS,
  SEARCH_SUGGESTIONS,
  IS_META_STATUS,
  IS_GOOGLE_STATUS,
  FETCH_SPECTFIC_BOARD_DATA,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
  SPECIFIC_BRAND_DETAIL,
  BRAND_CHOSEN,
  META_OPTIONS,
  SEARCH_SUGGESTIONS_FOR_BRANDS,
  BRAND_CHOSEN_ID,
  MEDIA_TYPE,
  BRAND_STATUS,
  BRAND_STATUS_LOADER,
  FOLLOW_BRANDS_LOADER,
  INPUT_VALUES,
  MIX_GOOGLE_PAGINATION,
  MIX_META_PAGINATION,
  CHECKED_VALUES,
  BRANDS_TAGS_DATA,
  BRANDS_TAGS_FOR_GOOGLE,
  BRANDS_TAGS_FOR_META,
  PAGE_ID_AND_ADVERTISER_ID,
  TRIGGER_SEARCH,
  BRANDS_LIST,
  ACTIVE_ADS_COUNT,
  IN_ACTIVE_ADS_COUNT,
  LIVE_SEARCH,
  BRAND_TAG_FOR_ALL,
  BRAND_TAG_FOR_GOOGLE,
  BRAND_TAG_FOR_META,
  CLEAR_BRANDS_TAG_FLAG,
  META_BRANDS_LIST,
  GOOGLE_BRANDS_LIST,
  ALL_BRANDS_LIST,
  TRIGGER_UPDATE_FOLLOW_BRANDS,
  BRAND_OPTIONS,
  LANDING_PAGE_DATA,
} from '@utils/constants'

const initialState = {
  landingPageData: [],
  triggerUpdateMe: false,
  followedBrandsList: [],
  brandOptions: [],
  metaBrandsList: [],
  googleBrandsList: [],
  allBrandsList: [],
  clearBrandsTagFlag: false,
  brandTagForAll: [],
  brandTagForGoogle: [],
  brandTagForMeta: [],
  isLiveSearch: false,
  activeAdsCount: 0,
  inActiveAdsCount: 0,
  brandsList: [],
  triggerSeach: false,
  pageIdAndAdvertiserId: [],
  brandsTagForGoogle: [],
  brandsTagForMeta: [],
  homePageBrands: [],
  checkedValues: ['Active', 'Inactive'],
  mediaType: null,
  brandChosenId: undefined,
  serachSuggestionForBrands: [],
  brandChosen: false,
  metaOptions: [],
  specificBrandDetail: [],
  fetchSpecificBoardDataForTagsAndCount: [],
  fetchSpecificBoardData: [],
  isGoogleStatus: false,
  isMetaStatus: false,
  searchSuggestions: [],
  invitedBoardsUsers: [],
  savedDescription: [],
  activeKey: '',
  sharedAndPrivateBoardsCount: 0,
  sharedBoardsList: [],
  relatedQueriesLoader: false,
  relatedQueries: [],
  scoreAndIndustry: [],
  scoreAndIndustryLoader: false,
  apiDataBoardsList: [],
  hasMore: false,
  loading: false,
  boards: [],
  savedAds: {},
  searchKeyword: '',
  channels: 'All',
  formats: '',
  spinner: false,
  cleanedDataCount: '',
  adsPreviewData: [],
  adsPreviewDataForNoDataFound: [],
  tempCount: 0,
  adsPagination: '',
  boardDetailData: [],
  boardSearch: '',
  boardsList: [],
  homeState: true,
  adsNoData: false,
  tags: [],
  filteredTags: [],
  brandsFilter: false,
  boardList: [],
  boardsTagsFilter: [],
  moreDataLoader: false,
  boardListCount: 0,
  uniqueTags: [],
  allAdsData: [],
  filteredDiscoverData: [],
  location: '',
  brandStatus: [],
  brandStatusLoader: false,
  followBrandsLoader: false,
  inputValues: '',
  mixMetaPagination: '',
  mixGooglePagination: '',
}

export const boardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOARD:
      return {
        ...state,
        boards: [...state.boards, action.payload],
      }
    case PAGE_ID_AND_ADVERTISER_ID:
      return {
        ...state,
        pageIdAndAdvertiserId: action.payload,
      }
    case BRANDS_TAGS_DATA:
      return {
        ...state,
        homePageBrands: action.payload,
      }
    case BRAND_OPTIONS:
      return {
        ...state,
        brandOptions: action.payload,
      }
    case LANDING_PAGE_DATA:
      return {
        ...state,
        landingPageData: action.payload,
      }

    case TRIGGER_UPDATE_FOLLOW_BRANDS:
      return {
        ...state,
        triggerUpdateMe: action.payload,
      }

    case BRANDS_LIST:
      return {
        ...state,
        brandsList: action.payload,
      }
    case META_BRANDS_LIST:
      return {
        ...state,
        metaBrandsList: action.payload,
      }
    case GOOGLE_BRANDS_LIST:
      return {
        ...state,
        googleBrandsList: action.payload,
      }
    case ALL_BRANDS_LIST:
      return {
        ...state,
        allBrandsList: action.payload,
      }

    case LIVE_SEARCH:
      return {
        ...state,
        isLiveSearch: action.payload,
      }

    case ACTIVE_ADS_COUNT:
      return {
        ...state,
        activeAdsCount: action.payload,
      }
    case BRAND_TAG_FOR_GOOGLE:
      return {
        ...state,
        brandTagForGoogle: action.payload,
      }
    case BRAND_TAG_FOR_META:
      return {
        ...state,
        brandTagForMeta: action.payload,
      }
    case BRAND_TAG_FOR_ALL:
      return {
        ...state,
        brandTagForAll: action.payload,
      }

    case IN_ACTIVE_ADS_COUNT:
      return {
        ...state,
        inActiveAdsCount: action.payload,
      }
    case CLEAR_BRANDS_TAG_FLAG:
      return {
        ...state,
        clearBrandsTagFlag: action.payload,
      }

    case TRIGGER_SEARCH:
      return {
        ...state,
        triggerSeach: action.payload,
      }
    case BRANDS_TAGS_FOR_GOOGLE:
      return {
        ...state,
        brandsTagForGoogle: action.payload,
      }
    case BRANDS_TAGS_FOR_META:
      return {
        ...state,
        brandsTagForMeta: action.payload,
      }
    case SET_BOARDS:
      return {
        ...state,
        boards: action.payload,
      }
    case CHECKED_VALUES:
      return {
        ...state,
        checkedValues: action.payload,
      }
    case RENAME_BOARD: {
      const { oldName, newName } = action.payload
      const updatedBoards = state.boards.map(board => (board === oldName ? newName : board))
      const updatedSavedAds = {
        ...state.savedAds,
        [newName]: state.savedAds[oldName],
      }
      delete updatedSavedAds[oldName]
      return {
        ...state,
        boards: updatedBoards,
        savedAds: updatedSavedAds,
      }
    }

    case DELETE_BOARD: {
      const updatedBoards = state.boards.filter(board => board !== action.payload)
      const { [action.payload]: _, ...updatedSavedAds } = state.savedAds
      return {
        ...state,
        boards: updatedBoards,
        savedAds: updatedSavedAds,
      }
    }
    case ADS_CHANNEL:
      return { ...state, channels: action.payload }
    case BRAND_CHOSEN:
      return { ...state, brandChosen: action.payload }
    case SAVE_AD_TO_BOARD:
      // eslint-disable-next-line
      const { board, ad } = action.payload
      // eslint-disable-next-line
      const isAdAlreadySaved = state.savedAds[board]?.some(savedAd => savedAd.id === ad.id)

      if (isAdAlreadySaved) {
        return state // Return the current state if the ad is already saved
      }

      return {
        ...state,
        savedAds: {
          ...state.savedAds,
          [board]: [...(state.savedAds[board] || []), ad],
        },
      }
    case ADS_FORMAT:
      return { ...state, formats: action.payload }
    case SPINNER:
      return { ...state, spinner: action.payload }
    case ADS_HOME_STATE:
      return { ...state, homeState: action.payload }
    case CLEANED_DATA_COUNT:
      return { ...state, cleanedDataCount: action.payload }
    case HAS_MORE:
      return { ...state, hasMore: action.payload }
    case ADS_DATA:
      return { ...state, adsPreviewData: [...state.adsPreviewData, ...action.payload] }
    case ADS_LOADER:
      return { ...state, loading: action.payload }
    case TEMP_COUNT: {
      return { ...state, tempCount: action.payload }
    }
    case EMPTY_ADS_PREVIEW_DATA:
      return { ...state, adsPreviewData: [] }
    case ADS_PAGINATION:
      return { ...state, adsPagination: action.payload }

    case BOARD_DETAIL_DATA:
      return { ...state, boardDetailData: action.payload }
    case BOARD_SEARCH:
      return { ...state, boardSearch: action.payload }
    case BOARDS_LIST:
      return { ...state, boardsList: action.payload }
    // return { ...state, boardsList: [...state.boardsList, ...action.payload] }
    case ADS_NO_DATA:
      return { ...state, adsNoData: action.payload }
    case TAGS:
      return { ...state, tags: action.payload }
    case FILTERED_TAGS:
      return { ...state, filteredTags: action.payload }
    case CLEAR_BRANDS_FLITER:
      return { ...state, brandsFilter: action.payload }
    case BOARD_LIST:
      return { ...state, boardList: action.payload }
    case BOARDS_TAG_FILTER:
      return { ...state, boardsTagsFilter: action.payload }
    case MORE_DATA_LOADER:
      return { ...state, moreDataLoader: action.payload }
    case BOARDS_LIST_COUNT:
      return { ...state, boardListCount: action.payload }
    case SAVED_PAGE_UNIQUE_TAGS:
      return { ...state, uniqueTags: action.payload }
    case SAVED_PAGE_FILTER_ALL_DATA:
      return { ...state, filteredDiscoverData: action.payload }
    case SAVED_PAGE_ALL_DATA:
      return { ...state, allAdsData: action.payload }
    case API_DATA_BOARDS_LIST:
      return { ...state, apiDataBoardsList: action.payload }
    case SCORE_AND_INDUSTRY:
      return { ...state, scoreAndIndustry: action.payload }
    case SCORE_AND_INDUSTRY_LOADER:
      return { ...state, scoreAndIndustryLoader: action.payload }
    case RELATED_QUERIES_LOADER:
      return { ...state, relatedQueriesLoader: action.payload }
    case RELATED_QUERIES:
      return { ...state, relatedQueries: action.payload }
    case SHARED_BOARDS:
      return { ...state, sharedBoardsList: action.payload }
    case SHARED_AND_PRIVATE_BOARDS_COUNT:
      return { ...state, sharedAndPrivateBoardsCount: action.payload }
    case ACTIVE_KEY:
      return { ...state, activeKey: action.payload }
    case ADS_SEARCH_KEYWORD:
      return { ...state, searchKeyword: action.payload }
    case ADS_LOCATION:
      return { ...state, location: action.payload }
    case SAVED_DESCRIPTION:
      return { ...state, savedDescription: action.payload }
    case INVITED_BOARDS_USERS:
      return { ...state, invitedBoardsUsers: action.payload }
    case SEARCH_SUGGESTIONS:
      return { ...state, searchSuggestions: action.payload }
    case IS_META_STATUS:
      return { ...state, isMetaStatus: action.payload }
    case IS_GOOGLE_STATUS:
      return { ...state, isGoogleStatus: action.payload }
    case FETCH_SPECTFIC_BOARD_DATA:
      return { ...state, fetchSpecificBoardData: action.payload }
    case FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT:
      return { ...state, fetchSpecificBoardDataForTagsAndCount: action.payload }
    case SPECIFIC_BRAND_DETAIL:
      return { ...state, specificBrandDetail: action.payload }
    case BRAND_CHOSEN_ID:
      return { ...state, brandChosenId: action.payload }
    case META_OPTIONS:
      return { ...state, metaOptions: action.payload }
    case SEARCH_SUGGESTIONS_FOR_BRANDS:
      return { ...state, serachSuggestionForBrands: action.payload }
    case MEDIA_TYPE:
      return { ...state, mediaType: action.payload }
    case BRAND_STATUS:
      return { ...state, brandStatus: action.payload }
    case BRAND_STATUS_LOADER:
      return { ...state, brandStatusLoader: action.payload }
    case FOLLOW_BRANDS_LOADER:
      return { ...state, followBrandsLoader: action.payload }
    case INPUT_VALUES:
      return { ...state, inputValues: action.payload }
    case MIX_META_PAGINATION:
      return { ...state, mixMetaPagination: action.payload }
    case MIX_GOOGLE_PAGINATION:
      return { ...state, mixGooglePagination: action.payload }
    case FOLLOWED_BRANDS_LIST:
      return { ...state, followedBrandsList: action.payload }

    default:
      return state
  }
}
