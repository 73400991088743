import globalIcon from '../images/DiscoverAds/globalIcon.png'

const googleCountries = [
  { value: '0000', label: 'Anywhere', flag: globalIcon },
  { value: '2008', label: 'Albania', flag: 'https://flagcdn.com/al.svg' },
  { value: '2010', label: 'Antarctica', flag: 'https://flagcdn.com/aq.svg' },
  { value: '2012', label: 'Algeria', flag: 'https://flagcdn.com/dz.svg' },
  { value: '2016', label: 'American Samoa', flag: 'https://flagcdn.com/as.svg' },
  { value: '2020', label: 'Andorra', flag: 'https://flagcdn.com/ad.svg' },
  { value: '2024', label: 'Angola', flag: 'https://flagcdn.com/ao.svg' },
  { value: '2028', label: 'Antigua and Barbuda', flag: 'https://flagcdn.com/ag.svg' },
  { value: '2031', label: 'Azerbaijan', flag: 'https://flagcdn.com/az.svg' },
  { value: '2032', label: 'Argentina', flag: 'https://flagcdn.com/ar.svg' },
  { value: '2036', label: 'Australia', flag: 'https://flagcdn.com/au.svg' },
  { value: '2040', label: 'Austria', flag: 'https://flagcdn.com/at.svg' },
  { value: '2044', label: 'The Bahamas', flag: 'https://flagcdn.com/bs.svg' },
  { value: '2048', label: 'Bahrain', flag: 'https://flagcdn.com/bh.svg' },
  { value: '2050', label: 'Bangladesh', flag: 'https://flagcdn.com/bd.svg' },
  { value: '2051', label: 'Armenia', flag: 'https://flagcdn.com/am.svg' },
  { value: '2052', label: 'Barbados', flag: 'https://flagcdn.com/bb.svg' },
  { value: '2056', label: 'Belgium', flag: 'https://flagcdn.com/be.svg' },
  { value: '2060', label: 'Bermuda', flag: 'https://flagcdn.com/bm.svg' },
  { value: '2064', label: 'Bhutan', flag: 'https://flagcdn.com/bt.svg' },
  { value: '2068', label: 'Bolivia', flag: 'https://flagcdn.com/bo.svg' },
  { value: '2070', label: 'Bosnia and Herzegovina', flag: 'https://flagcdn.com/ba.svg' },
  { value: '2072', label: 'Botswana', flag: 'https://flagcdn.com/bw.svg' },
  { value: '2074', label: 'Bouvet Island', flag: 'https://flagcdn.com/bv.svg' },
  { value: '2076', label: 'Brazil', flag: 'https://flagcdn.com/br.svg' },
  { value: '2084', label: 'Belize', flag: 'https://flagcdn.com/bz.svg' },
  { value: '2086', label: 'British Indian Ocean Territory', flag: 'https://flagcdn.com/io.svg' },
  { value: '2090', label: 'Solomon Islands', flag: 'https://flagcdn.com/sb.svg' },
  { value: '2092', label: 'British Virgin Islands', flag: 'https://flagcdn.com/vg.svg' },
  { value: '2096', label: 'Brunei', flag: 'https://flagcdn.com/bn.svg' },
  { value: '2100', label: 'Bulgaria', flag: 'https://flagcdn.com/bg.svg' },
  { value: '2104', label: 'Myanmar (Burma)', flag: 'https://flagcdn.com/mm.svg' },
  { value: '2108', label: 'Burundi', flag: 'https://flagcdn.com/bi.svg' },
  { value: '2112', label: 'Belarus', flag: 'https://flagcdn.com/by.svg' },
  { value: '2116', label: 'Cambodia', flag: 'https://flagcdn.com/kh.svg' },
  { value: '2120', label: 'Cameroon', flag: 'https://flagcdn.com/cm.svg' },
  { value: '2124', label: 'Canada', flag: 'https://flagcdn.com/ca.svg' },
  { value: '2132', label: 'Cabo Verde', flag: 'https://flagcdn.com/cv.svg' },
  { value: '2136', label: 'Cayman Islands', flag: 'https://flagcdn.com/ky.svg' },
  { value: '2140', label: 'Central African Republic', flag: 'https://flagcdn.com/cf.svg' },
  { value: '2144', label: 'Sri Lanka', flag: 'https://flagcdn.com/lk.svg' },
  { value: '2148', label: 'Chad', flag: 'https://flagcdn.com/td.svg' },
  { value: '2152', label: 'Chile', flag: 'https://flagcdn.com/cl.svg' },
  { value: '2156', label: 'China', flag: 'https://flagcdn.com/cn.svg' },
  { value: '2158', label: 'Taiwan', flag: 'https://flagcdn.com/tw.svg' },
  { value: '2162', label: 'Christmas Island', flag: 'https://flagcdn.com/cx.svg' },
  { value: '2166', label: 'Cocos (Keeling) Islands', flag: 'https://flagcdn.com/cc.svg' },
  { value: '2170', label: 'Colombia', flag: 'https://flagcdn.com/co.svg' },
  { value: '2174', label: 'Comoros', flag: 'https://flagcdn.com/km.svg' },
  { value: '2175', label: 'Mayotte', flag: 'https://flagcdn.com/yt.svg' },
  { value: '2178', label: 'Republic of the Congo', flag: 'https://flagcdn.com/cg.svg' },
  { value: '2180', label: 'Democratic Republic of the Congo', flag: 'https://flagcdn.com/cd.svg' },
  { value: '2184', label: 'Cook Islands', flag: 'https://flagcdn.com/ck.svg' },
  { value: '2188', label: 'Costa Rica', flag: 'https://flagcdn.com/cr.svg' },
  { value: '2191', label: 'Croatia', flag: 'https://flagcdn.com/hr.svg' },
  { value: '2196', label: 'Cyprus', flag: 'https://flagcdn.com/cy.svg' },
  { value: '2203', label: 'Czechia', flag: 'https://flagcdn.com/cz.svg' },
  { value: '2204', label: 'Benin', flag: 'https://flagcdn.com/bj.svg' },
  { value: '2208', label: 'Denmark', flag: 'https://flagcdn.com/dk.svg' },
  { value: '2212', label: 'Dominica', flag: 'https://flagcdn.com/dm.svg' },
  { value: '2214', label: 'Dominican Republic', flag: 'https://flagcdn.com/do.svg' },
  { value: '2218', label: 'Ecuador', flag: 'https://flagcdn.com/ec.svg' },
  { value: '2222', label: 'El Salvador', flag: 'https://flagcdn.com/sv.svg' },
  { value: '2226', label: 'Equatorial Guinea', flag: 'https://flagcdn.com/gq.svg' },
  { value: '2231', label: 'Ethiopia', flag: 'https://flagcdn.com/et.svg' },
  { value: '2232', label: 'Eritrea', flag: 'https://flagcdn.com/er.svg' },
  { value: '2233', label: 'Estonia', flag: 'https://flagcdn.com/ee.svg' },
  { value: '2234', label: 'Faroe Islands', flag: 'https://flagcdn.com/fo.svg' },
  { value: '2238', label: 'Falkland Islands (Islas Malvinas)', flag: 'https://flagcdn.com/fk.svg' },
  {
    value: '2239',
    label: 'South Georgia and the South Sandwich Islands',
    flag: 'https://flagcdn.com/gs.svg',
  },
  { value: '2242', label: 'Fiji', flag: 'https://flagcdn.com/fj.svg' },
  { value: '2246', label: 'Finland', flag: 'https://flagcdn.com/fi.svg' },
  { value: '2250', label: 'France', flag: 'https://flagcdn.com/fr.svg' },
  { value: '2254', label: 'French Guiana', flag: 'https://flagcdn.com/gf.svg' },
  { value: '2258', label: 'French Polynesia', flag: 'https://flagcdn.com/pf.svg' },
  {
    value: '2260',
    label: 'French Southern and Antarctic Lands',
    flag: 'https://flagcdn.com/tf.svg',
  },
  { value: '2262', label: 'Djibouti', flag: 'https://flagcdn.com/dj.svg' },
  { value: '2266', label: 'Gabon', flag: 'https://flagcdn.com/ga.svg' },
  { value: '2268', label: 'Georgia', flag: 'https://flagcdn.com/ge.svg' },
  { value: '2270', label: 'The Gambia', flag: 'https://flagcdn.com/gm.svg' },
  { value: '2275', label: 'Palestine', flag: 'https://flagcdn.com/ps.svg' },
  { value: '2276', label: 'Germany', flag: 'https://flagcdn.com/de.svg' },
  { value: '2288', label: 'Ghana', flag: 'https://flagcdn.com/gh.svg' },
  { value: '2292', label: 'Gibraltar', flag: 'https://flagcdn.com/gi.svg' },
  { value: '2296', label: 'Kiribati', flag: 'https://flagcdn.com/ki.svg' },
  { value: '2300', label: 'Greece', flag: 'https://flagcdn.com/gr.svg' },
  { value: '2304', label: 'Greenland', flag: 'https://flagcdn.com/gl.svg' },
  { value: '2308', label: 'Grenada', flag: 'https://flagcdn.com/gd.svg' },
  { value: '2312', label: 'Guadeloupe', flag: 'https://flagcdn.com/gp.svg' },
  { value: '2316', label: 'Guam', flag: 'https://flagcdn.com/gu.svg' },
  { value: '2320', label: 'Guatemala', flag: 'https://flagcdn.com/gt.svg' },
  { value: '2324', label: 'Guinea', flag: 'https://flagcdn.com/gn.svg' },
  { value: '2328', label: 'Guyana', flag: 'https://flagcdn.com/gy.svg' },
  { value: '2332', label: 'Haiti', flag: 'https://flagcdn.com/ht.svg' },
  { value: '2334', label: 'Heard Island and McDonald Islands', flag: 'https://flagcdn.com/hm.svg' },
  { value: '2336', label: 'Vatican City', flag: 'https://flagcdn.com/va.svg' },
  { value: '2340', label: 'Honduras', flag: 'https://flagcdn.com/hn.svg' },
  { value: '2344', label: 'Hong Kong', flag: 'https://flagcdn.com/hk.svg' },
  { value: '2348', label: 'Hungary', flag: 'https://flagcdn.com/hu.svg' },
  { value: '2352', label: 'Iceland', flag: 'https://flagcdn.com/is.svg' },
  { value: '2356', label: 'India', flag: 'https://flagcdn.com/in.svg' },
  { value: '2360', label: 'Indonesia', flag: 'https://flagcdn.com/id.svg' },
  { value: '2368', label: 'Iraq', flag: 'https://flagcdn.com/iq.svg' },
  { value: '2372', label: 'Ireland', flag: 'https://flagcdn.com/ie.svg' },
  { value: '2376', label: 'Israel', flag: 'https://flagcdn.com/il.svg' },
  { value: '2380', label: 'Italy', flag: 'https://flagcdn.com/it.svg' },
  { value: '2384', label: "Cote d'Ivoire", flag: 'https://flagcdn.com/ci.svg' },
  { value: '2388', label: 'Jamaica', flag: 'https://flagcdn.com/jm.svg' },
  { value: '2392', label: 'Japan', flag: 'https://flagcdn.com/jp.svg' },
  { value: '2398', label: 'Kazakhstan', flag: 'https://flagcdn.com/kz.svg' },
  { value: '2400', label: 'Jordan', flag: 'https://flagcdn.com/jo.svg' },
  { value: '2404', label: 'Kenya', flag: 'https://flagcdn.com/ke.svg' },
  { value: '2410', label: 'South Korea', flag: 'https://flagcdn.com/kr.svg' },
  { value: '2414', label: 'Kuwait', flag: 'https://flagcdn.com/kw.svg' },
  { value: '2417', label: 'Kyrgyzstan', flag: 'https://flagcdn.com/kg.svg' },
  { value: '2418', label: 'Laos', flag: 'https://flagcdn.com/la.svg' },
  { value: '2422', label: 'Lebanon', flag: 'https://flagcdn.com/lb.svg' },
  { value: '2426', label: 'Lesotho', flag: 'https://flagcdn.com/ls.svg' },
  { value: '2428', label: 'Latvia', flag: 'https://flagcdn.com/lv.svg' },
  { value: '2430', label: 'Liberia', flag: 'https://flagcdn.com/lr.svg' },
  { value: '2434', label: 'Libya', flag: 'https://flagcdn.com/ly.svg' },
  { value: '2438', label: 'Liechtenstein', flag: 'https://flagcdn.com/li.svg' },
  { value: '2440', label: 'Lithuania', flag: 'https://flagcdn.com/lt.svg' },
  { value: '2442', label: 'Luxembourg', flag: 'https://flagcdn.com/lu.svg' },
  { value: '2446', label: 'Macao', flag: 'https://flagcdn.com/mo.svg' },
  { value: '2450', label: 'Madagascar', flag: 'https://flagcdn.com/mg.svg' },
  { value: '2454', label: 'Malawi', flag: 'https://flagcdn.com/mw.svg' },
  { value: '2458', label: 'Malaysia', flag: 'https://flagcdn.com/my.svg' },
  { value: '2462', label: 'Maldives', flag: 'https://flagcdn.com/mv.svg' },
  { value: '2466', label: 'Mali', flag: 'https://flagcdn.com/ml.svg' },
  { value: '2470', label: 'Malta', flag: 'https://flagcdn.com/mt.svg' },
  { value: '2474', label: 'Martinique', flag: 'https://flagcdn.com/mq.svg' },
  { value: '2478', label: 'Mauritania', flag: 'https://flagcdn.com/mr.svg' },
  { value: '2480', label: 'Mauritius', flag: 'https://flagcdn.com/mu.svg' },
  { value: '2484', label: 'Mexico', flag: 'https://flagcdn.com/mx.svg' },
  { value: '2492', label: 'Monaco', flag: 'https://flagcdn.com/mc.svg' },
  { value: '2496', label: 'Mongolia', flag: 'https://flagcdn.com/mn.svg' },
  { value: '2498', label: 'Moldova', flag: 'https://flagcdn.com/md.svg' },
  { value: '2499', label: 'Montenegro', flag: 'https://flagcdn.com/me.svg' },
  { value: '2500', label: 'Montserrat', flag: 'https://flagcdn.com/ms.svg' },
  { value: '2504', label: 'Morocco', flag: 'https://flagcdn.com/ma.svg' },
  { value: '2508', label: 'Mozambique', flag: 'https://flagcdn.com/mz.svg' },
  { value: '2512', label: 'Oman', flag: 'https://flagcdn.com/om.svg' },
  { value: '2516', label: 'Namibia', flag: 'https://flagcdn.com/na.svg' },
  { value: '2520', label: 'Nauru', flag: 'https://flagcdn.com/nr.svg' },
  { value: '2524', label: 'Nepal', flag: 'https://flagcdn.com/np.svg' },
  { value: '2528', label: 'Netherlands', flag: 'https://flagcdn.com/nl.svg' },
  { value: '2531', label: 'Curacao', flag: 'https://flagcdn.com/cw.svg' },
  { value: '2533', label: 'Aruba', flag: 'https://flagcdn.com/aw.svg' },
  { value: '2534', label: 'Sint Maarten', flag: 'https://flagcdn.com/sx.svg' },
  { value: '2535', label: 'Caribbean Netherlands', flag: 'https://flagcdn.com/bq.svg' },
  { value: '2540', label: 'New Caledonia', flag: 'https://flagcdn.com/nc.svg' },
  { value: '2548', label: 'Vanuatu', flag: 'https://flagcdn.com/vu.svg' },
  { value: '2554', label: 'New Zealand', flag: 'https://flagcdn.com/nz.svg' },
  { value: '2558', label: 'Nicaragua', flag: 'https://flagcdn.com/ni.svg' },
  { value: '2562', label: 'Niger', flag: 'https://flagcdn.com/ne.svg' },
  { value: '2566', label: 'Nigeria', flag: 'https://flagcdn.com/ng.svg' },
  { value: '2570', label: 'Niue', flag: 'https://flagcdn.com/nu.svg' },
  { value: '2574', label: 'Norfolk Island', flag: 'https://flagcdn.com/nf.svg' },
  { value: '2578', label: 'Norway', flag: 'https://flagcdn.com/no.svg' },
  { value: '2580', label: 'Northern Mariana Islands', flag: 'https://flagcdn.com/mp.svg' },
  {
    value: '2581',
    label: 'United States Minor Outlying Islands',
    flag: 'https://flagcdn.com/um.svg',
  },
  { value: '2583', label: 'Micronesia', flag: 'https://flagcdn.com/fm.svg' },
  { value: '2584', label: 'Marshall Islands', flag: 'https://flagcdn.com/mh.svg' },
  { value: '2585', label: 'Palau', flag: 'https://flagcdn.com/pw.svg' },
  { value: '2586', label: 'Pakistan', flag: 'https://flagcdn.com/pk.svg' },
  { value: '2591', label: 'Panama', flag: 'https://flagcdn.com/pa.svg' },
  { value: '2598', label: 'Papua New Guinea', flag: 'https://flagcdn.com/pg.svg' },
  { value: '2600', label: 'Paraguay', flag: 'https://flagcdn.com/py.svg' },
  { value: '2604', label: 'Peru', flag: 'https://flagcdn.com/pe.svg' },
  { value: '2608', label: 'Philippines', flag: 'https://flagcdn.com/ph.svg' },
  { value: '2612', label: 'Pitcairn Islands', flag: 'https://flagcdn.com/pn.svg' },
  { value: '2616', label: 'Poland', flag: 'https://flagcdn.com/pl.svg' },
  { value: '2620', label: 'Portugal', flag: 'https://flagcdn.com/pt.svg' },
  { value: '2624', label: 'Guinea-Bissau', flag: 'https://flagcdn.com/gw.svg' },
  { value: '2626', label: 'Timor-Leste', flag: 'https://flagcdn.com/tl.svg' },
  { value: '2630', label: 'Puerto Rico', flag: 'https://flagcdn.com/pr.svg' },
  { value: '2634', label: 'Qatar', flag: 'https://flagcdn.com/qa.svg' },
  { value: '2638', label: 'Reunion', flag: 'https://flagcdn.com/re.svg' },
  { value: '2642', label: 'Romania', flag: 'https://flagcdn.com/ro.svg' },
  { value: '2646', label: 'Rwanda', flag: 'https://flagcdn.com/rw.svg' },
  {
    value: '2654',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: 'https://flagcdn.com/sh.svg',
  },
  { value: '2659', label: 'Saint Kitts and Nevis', flag: 'https://flagcdn.com/kn.svg' },
  { value: '2660', label: 'Anguilla', flag: 'https://flagcdn.com/ai.svg' },
  { value: '2662', label: 'Saint Lucia', flag: 'https://flagcdn.com/lc.svg' },
  { value: '2663', label: 'Saint Martin', flag: 'https://flagcdn.com/mf.svg' },
  { value: '2666', label: 'Saint Pierre and Miquelon', flag: 'https://flagcdn.com/pm.svg' },
  { value: '2670', label: 'Saint Vincent and the Grenadines', flag: 'https://flagcdn.com/vc.svg' },
  { value: '2674', label: 'San Marino', flag: 'https://flagcdn.com/sm.svg' },
  { value: '2678', label: 'Sao Tome and Principe', flag: 'https://flagcdn.com/st.svg' },
  { value: '2682', label: 'Saudi Arabia', flag: 'https://flagcdn.com/sa.svg' },
  { value: '2686', label: 'Senegal', flag: 'https://flagcdn.com/sn.svg' },
  { value: '2690', label: 'Seychelles', flag: 'https://flagcdn.com/sc.svg' },
  { value: '2694', label: 'Sierra Leone', flag: 'https://flagcdn.com/sl.svg' },
  { value: '2702', label: 'Singapore', flag: 'https://flagcdn.com/sg.svg' },
  { value: '2703', label: 'Slovakia', flag: 'https://flagcdn.com/sk.svg' },
  { value: '2704', label: 'Vietnam', flag: 'https://flagcdn.com/vn.svg' },
  { value: '2705', label: 'Slovenia', flag: 'https://flagcdn.com/si.svg' },
  { value: '2706', label: 'Somalia', flag: 'https://flagcdn.com/so.svg' },
  { value: '2710', label: 'South Africa', flag: 'https://flagcdn.com/za.svg' },
  { value: '2716', label: 'Zimbabwe', flag: 'https://flagcdn.com/zw.svg' },
  { value: '2724', label: 'Spain', flag: 'https://flagcdn.com/es.svg' },
  { value: '2732', label: 'Western Sahara', flag: 'https://flagcdn.com/eh.svg' },
  { value: '2740', label: 'Suriname', flag: 'https://flagcdn.com/sr.svg' },
  { value: '2744', label: 'Svalbard and Jan Mayen', flag: 'https://flagcdn.com/sj.svg' },
  { value: '2748', label: 'Eswatini', flag: 'https://flagcdn.com/sz.svg' },
  { value: '2752', label: 'Sweden', flag: 'https://flagcdn.com/se.svg' },
  { value: '2756', label: 'Switzerland', flag: 'https://flagcdn.com/ch.svg' },
  { value: '2762', label: 'Tajikistan', flag: 'https://flagcdn.com/tj.svg' },
  { value: '2764', label: 'Thailand', flag: 'https://flagcdn.com/th.svg' },
  { value: '2768', label: 'Togo', flag: 'https://flagcdn.com/tg.svg' },
  { value: '2772', label: 'Tokelau', flag: 'https://flagcdn.com/tk.svg' },
  { value: '2776', label: 'Tonga', flag: 'https://flagcdn.com/to.svg' },
  { value: '2780', label: 'Trinidad and Tobago', flag: 'https://flagcdn.com/tt.svg' },
  { value: '2784', label: 'United Arab Emirates', flag: 'https://flagcdn.com/ae.svg' },
  { value: '2788', label: 'Tunisia', flag: 'https://flagcdn.com/tn.svg' },
  { value: '2792', label: 'Turkiye', flag: 'https://flagcdn.com/tr.svg' },
  { value: '2795', label: 'Turkmenistan', flag: 'https://flagcdn.com/tm.svg' },
  { value: '2796', label: 'Turks and Caicos Islands', flag: 'https://flagcdn.com/tc.svg' },
  { value: '2798', label: 'Tuvalu', flag: 'https://flagcdn.com/tv.svg' },
  { value: '2800', label: 'Uganda', flag: 'https://flagcdn.com/ug.svg' },
  { value: '2804', label: 'Ukraine', flag: 'https://flagcdn.com/ua.svg' },
  { value: '2807', label: 'North Macedonia', flag: 'https://flagcdn.com/mk.svg' },
  { value: '2818', label: 'Egypt', flag: 'https://flagcdn.com/eg.svg' },
  { value: '2826', label: 'United Kingdom', flag: 'https://flagcdn.com/gb.svg' },

  { value: '2831', label: 'Guernsey', flag: 'https://flagcdn.com/gg.svg' },
  { value: '2832', label: 'Jersey', flag: 'https://flagcdn.com/je.svg' },
  { value: '2833', label: 'Isle of Man', flag: 'https://flagcdn.com/im.svg' },
  { value: '2834', label: 'Tanzania', flag: 'https://flagcdn.com/tz.svg' },
  { value: '2840', label: 'United States', flag: 'https://flagcdn.com/us.svg' },
  { value: '2850', label: 'U.S. Virgin Islands', flag: 'https://flagcdn.com/vi.svg' },
  { value: '2854', label: 'Burkina Faso', flag: 'https://flagcdn.com/bf.svg' },
  { value: '2858', label: 'Uruguay', flag: 'https://flagcdn.com/uy.svg' },
  { value: '2860', label: 'Uzbekistan', flag: 'https://flagcdn.com/uz.svg' },
  { value: '2862', label: 'Venezuela', flag: 'https://flagcdn.com/ve.svg' },
  { value: '2876', label: 'Wallis and Futuna', flag: 'https://flagcdn.com/wf.svg' },
  { value: '2887', label: 'Yemen', flag: 'https://flagcdn.com/ye.svg' },
  { value: '2894', label: 'Zambia', flag: 'https://flagcdn.com/zm.svg' },
  { value: '20277', label: 'Canary Islands', flag: 'https://flagcdn.com/es.svg' },
]

const metaCountries = [
  { value: '0000', label: 'ALL' },
  { value: 'ar', label: 'Arabic [AR]' },
  { value: 'bn', label: 'Bengali [BN]' },
  { value: 'zh', label: 'Chinese [ZH]' },
  { value: 'en', label: 'English [EN]' },
  { value: 'fr', label: 'French [FR]' },
  { value: 'de', label: 'German [DE]' },
  { value: 'hi', label: 'Hindi [HI]' },
  { value: 'id', label: 'Indonesian [ID]' },
  { value: 'it', label: 'Italian [IT]' },
  { value: 'ja', label: 'Japanese [JA]' },
  { value: 'ko', label: 'Korean [KO]' },
  { value: 'pl', label: 'Polish [PL]' },
  { value: 'pt', label: 'Portuguese [PT]' },
  { value: 'ru', label: 'Russian [RU]' },
  { value: 'es', label: 'Spanish [ES]' },
  { value: 'sv', label: 'Swedish [SV]' },
  { value: 'th', label: 'Thai [TH]' },
  { value: 'tr', label: 'Turkish [TR]' },
  { value: 'vi', label: 'Vietnamese [VI]' },
]
const countryCodeToName = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (Democratic Republic)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GR: 'Greece',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  MK: 'North Macedonia',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

export { googleCountries, metaCountries, countryCodeToName }
