import React from 'react'
import { Modal, Button, Typography, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import styles from './Contect-account.module.css'

const { Text } = Typography

const ConnectGoogleAccountModal = ({ visible, onClose, onConnect, detail }) => {
  const temp = 0
  return (
    <Modal
      title={
        <div
          style={{
            textAlign: 'center',
            fontWeight: 700,
            fontSize: 24,
            marginTop: 30,
          }}
        >
          Connect Your Google Ads Account
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Text type="secondary" style={{ display: 'block', marginBottom: 20, textAlign: 'center' }}>
        To access the {detail} section, please log out and sign back in using your Google ads
        account.
      </Text>
      {/* <Tooltip
        placement="top"
        title={<span style={{ whiteSpace: 'nowrap' }}>Please Contact Support</span>}
        color="rgb(32, 32, 32)"
      >
        <div>
          <Button disabled type="primary" className={styles.searchButton} onClick={onConnect}>
            Connect Your Account
          </Button>
        </div>
      </Tooltip> */}

      <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onClose}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div>
    </Modal>
  )
}

export default ConnectGoogleAccountModal
