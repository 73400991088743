import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Menu, Modal, Row, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import {
  FILTERED_TAGS,
  TAGS,
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES,
  RELATED_QUERIES_LOADER,
  FOLLOW_BRANDS_LOADER,
  BRAND_STATUS,
  BRAND_STATUS_LOADER,
  ADS_DATA,
  ADS_HOME_STATE,
  ADS_LOADER,
  ADS_NO_DATA,
  ADS_PAGINATION,
  ADS_SEARCH_KEYWORD,
  CLEANED_DATA_COUNT,
  CLEAR_BRANDS_FLITER,
  HAS_MORE,
  IS_GOOGLE_STATUS,
  IS_META_STATUS,
  SPINNER,
  TEMP_COUNT,
  EMPTY_ADS_PREVIEW_DATA,
  CLEAR_STATE,
  INPUT_VALUES,
  LIVE_SEARCH,
  ADS_CHANNEL,
  CHECKED_VALUES,
  BRAND_CHOSEN,
  IN_ACTIVE_ADS_COUNT,
  ACTIVE_ADS_COUNT,
  ACTIVE_KEY,
  MIX_GOOGLE_PAGINATION,
  TRIGGER_UPDATE_FOLLOW_BRANDS,
  MIX_META_PAGINATION,
} from '@utils/constants'
import { notify } from '@utils/notify'
import { RequestService, bucketBaseURL, axiosInstance } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FollowIcon from '@images/DiscoverAds/MoreOptions/followBrandIcon.png'
import shareIcon from '../../../images/DiscoverAds/Modal/icons-share.png'
import Divider from '../../../images/DiscoverAds/Modal/Vector 6638.png'
import Next from '../../../images/DiscoverAds/Modal/next.png'
import Previous from '../../../images/DiscoverAds/Modal/previous.png'
import style from './adsModal.module.css'
import ShareModal from './ShareModal'
import ModalCard from './ModalCard'
import AdDetailCard from './AdDetailCard'

const AdsModal = ({
  modalOpen,
  handleOk,
  handleCancel,
  adsData,
  onPrevious,
  onNext,
  isFirst,
  isLast,
  boardsList,
}) => {
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  const dispatch = useDispatch()
  const searchKeyword = useSelector(state => state?.discoverAds?.inputValues)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const [allOptions, setAllOptions] = useState([])
  const [googleOptions, setGoogleOptions] = useState([])
  const adsFormats = useSelector(state => state?.discoverAds?.formats)
  const [metaOptions, setMetaOptions] = useState([])
  const [upgradeToProModal, setUpgradeToProModal] = useState(false)
  const location = useSelector(state => state?.discoverAds?.location)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [isModalOpen, setIsModalOpen] = useState(modalOpen)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const responseForBrandStatus = useSelector(state => state?.discoverAds?.brandStatus)
  const followBrandsLoader = useSelector(state => state?.discoverAds?.followBrandsLoader)
  const loaderForBrandsStatus = useSelector(state => state?.discoverAds?.brandStatusLoader)
  const brandChosen = useSelector(state => state?.discoverAds?.brandChosen)
  const history = useHistory()

  const getScoresAndIndustry = async id => {
    if (id?.additional_info?.ad_score) {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      const customResponse = {
        // eslint-disable-next-line
        id: id?._id,
        industry: id?.additional_info?.industry,
        reasoning: id?.additional_info?.reasoning,
        score: id?.additional_info?.ad_score,
      }
      dispatch({ type: SCORE_AND_INDUSTRY, payload: customResponse })
    } else {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: true })
      const data = {
        // eslint-disable-next-line
        ad_id: id?._id,
      }
      try {
        const response = await RequestService.post('/ads-preview/open-ai/', data)
        dispatch({ type: SCORE_AND_INDUSTRY, payload: response.data })
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      } catch (error) {
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      }
    }
  }
  const getTags = async id => {
    try {
      const response = await RequestService.get(`/save/tags/?ad_id=${id}`)
      dispatch({ type: TAGS, payload: response.data })
      dispatch({ type: FILTERED_TAGS, payload: response.data })
    } catch (error) {
      dispatch({ type: TAGS, payload: [] })
      dispatch({ type: FILTERED_TAGS, payload: [] })
    }
  }
  const handleRelatedQueriesData = async () => {
    dispatch({ type: RELATED_QUERIES, payload: [] })
    dispatch({ type: RELATED_QUERIES_LOADER, payload: true })
    const targetDomain = adsData?.search_term
    try {
      const response = await RequestService.get(
        `/ads-preview/competitors/?search_term=${targetDomain}`
      )
      const formattedData = response?.data
      dispatch({ type: RELATED_QUERIES, payload: formattedData?.google_ads[0] })
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    } catch (error) {
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    }
  }
  const handleBrandStatus = async () => {
    try {
      dispatch({ type: BRAND_STATUS_LOADER, payload: true })
      // setLoaderForBrandsStatus(true)
      const response = await RequestService.get(
        `/save/follow-brand-request/?brand_name=${
          adsData?.additional_info?.page_name || adsData?.advertiser
        }&page_id=${adsData?.additional_info.page_id}&advertiser_id=${adsData?.advertiser_id}`
      )
      dispatch({ type: BRAND_STATUS, payload: response?.data })
      // setResponseForBrandStatus(response?.data)
      dispatch({ type: BRAND_STATUS_LOADER, payload: false })
      // setLoaderForBrandsStatus(false)
    } catch (error) {
      dispatch({ type: BRAND_STATUS_LOADER, payload: false })
      // setLoaderForBrandsStatus(false)
    }
  }
  const hanldeFollowBrands = async () => {
    try {
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: true })
      // setFollowBrandsLoader(true)
      const data = {
        google_page_url: adsData?.origin?.provider === 'google' ? adsData?.ad_snapshot_url : '',
        meta_page_url:
          adsData?.origin?.provider === 'meta' ? adsData?.additional_info?.page_profile_uri : '',
        advertiser_id: adsData?.advertiser_id,
        page_id: adsData.additional_info.page_id,
        brand_name: adsData?.additional_info?.page_name || adsData?.advertiser,
        is_followed: true,
        brand: null,
      }
      const response = await RequestService.post('/save/follow-brands/', data)
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: true })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
      if (response.data.msg === 'Your request for following the brand is pending.') {
        message.success('Brand follow request sent successfully')
      } else {
        message.success('Follow successful')
      }
      handleBrandStatus()
    } catch (error) {
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
    }
  }

  const hanldeUnFollowBrands = async () => {
    try {
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: true })
      // setFollowBrandsLoader(true)
      await RequestService.delete(
        `/save/follow-brands/?brand_id&advertiser_id=${adsData?.advertiser_id}&page_id=${
          adsData.additional_info.page_id
        }&brand_name=${adsData?.additional_info?.page_name || adsData?.advertiser}`
      )
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: true })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
      message.success('Unfollow successful')
      handleBrandStatus()
    } catch (error) {
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    // eslint-disable-next-line
    if (adsData?._id) {
      // Fetch the scores and tags for the updated adsData when it changes
      getScoresAndIndustry(adsData)
      handleBrandStatus()
      // eslint-disable-next-line
      getTags(adsData._id)
      if (currentPath !== '/dashboard/creative-library') {
        handleRelatedQueriesData()
      }
    }
    // eslint-disable-next-line
  }, [adsData])
  const showModal = () => {
    setIsModalOpen(true)
  }

  const showShareModal = () => {
    setIsShareModalOpen(true)
  }

  const handleShareOk = () => {
    setIsShareModalOpen(false)
  }

  const handleShareCancel = () => {
    setIsShareModalOpen(false)
  }
  const handlePreviousClick = () => {
    if (!isFirst) {
      onPrevious()
    }
  }

  const handleNextClick = () => {
    if (!isLast) {
      onNext()
    }
  }

  const handleAdsApi = async () => {
    dispatch({ type: SPINNER, payload: true })
    dispatch({ type: ADS_LOADER, payload: true })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
    dispatch({
      type: MIX_META_PAGINATION,
      payload: '',
    })

    dispatch({
      type: MIX_GOOGLE_PAGINATION,
      payload: '',
    })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    dispatch({ type: CHECKED_VALUES, payload: ['Active', 'Inactive'] })
    dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: 0 })
    dispatch({ type: ACTIVE_ADS_COUNT, payload: 0 })
    dispatch({ type: LIVE_SEARCH, payload: false })
    try {
      dispatch({
        type: CLEAR_STATE,
        payload: [],
      })
      const brandStatusData = {
        brand_name: searchKeyword,
      }
      const brandStatus = await axiosInstance.post('save/brand-status/', brandStatusData)
      let response = []
      if (selectedChannel === 'Meta') {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${20}&creative_format=${
            adsFormats.length > 0 ? adsFormats.toString().toLowerCase().replace(/s$/, '') : 'all'
          }&channels=[${selectedChannel}]&ad_reached_countries=["${
            location === '0000' ? '' : location
            // eslint-disable-next-line
          }"]&is_brand=${brandStatus?.data?.page_id ? true : false}&advertiser_id=${
            brandStatus?.data?.page_id
          }`
        )
        dispatch({ type: IS_META_STATUS, payload: response?.data?.is_meta })
        if (response?.data?.is_meta) {
          dispatch({ type: LIVE_SEARCH, payload: true })
        }
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
      } else if (selectedChannel === 'Google') {
        let paginationCounnt = 30
        if (adsFormats === 'Videos' || adsFormats === 'all') {
          paginationCounnt = 7
        }
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${paginationCounnt}&creative_format=${adsFormats
            .toString()
            .toLowerCase()
            .replace(/s$/, '')}&channels=[${selectedChannel}]&region=${
            location === '0000' ? '' : location
            // eslint-disable-next-line
          }&is_brand=${brandStatus?.data?.google_advertiser_id ? true : false}&page_id=${
            brandStatus?.data?.google_advertiser_id
          }`
        )

        dispatch({ type: IS_GOOGLE_STATUS, payload: response?.data?.is_facebook })
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
      } else if (selectedChannel === 'All') {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?channels=[All]&search_term=${searchKeyword}&is_brand=${
            // eslint-disable-next-line
            brandStatus?.data?.google_advertiser_id || brandStatus?.data?.page_id ? true : false
          }&num=${20}&page_id=${brandStatus?.data?.google_advertiser_id}&advertiser_id=${
            brandStatus.data.page_id
          }&ad_reached_countries=["${location === '0000' ? '' : location}"]`
        )
      }
      dispatch({ type: ADS_LOADER, payload: false })
      if (response?.data?.is_meta_or_google) {
        dispatch({ type: LIVE_SEARCH, payload: true })
        const googleResults = response?.data?.google_ads?.facebook_ads?.data || []
        const metaResults = response?.data?.meta_ads?.facebook_ads?.data || []
        const maxLength = Math.max(googleResults.length, metaResults.length)
        let mixData = []
        const mixRawData = []

        for (let i = 0; i < maxLength; i += 1) {
          if (i < googleResults?.length) {
            mixRawData.push(googleResults[i])
          }
          if (i < metaResults.length) {
            mixRawData.push(metaResults[i])
          }
        }

        if (Array.isArray(mixRawData)) {
          mixData = mixRawData.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad?.advertiser_id ||
                brand.meta_page_id === ad?.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }

        if (mixData.length > 0) {
          dispatch({ type: ADS_NO_DATA, payload: false })
          dispatch({
            type: ADS_DATA,
            payload: mixData,
          })
          if (
            response?.data?.meta_ads?.facebook_ads?.paging?.next &&
            mixData.length > 0 &&
            selectedChannel === 'All'
          ) {
            dispatch({
              type: MIX_META_PAGINATION,
              payload: response?.data?.meta_ads?.facebook_ads?.paging?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          }
          if (
            response?.data?.google_ads?.facebook_ads?.paging?.next &&
            mixData.length > 0 &&
            selectedChannel === 'All'
          ) {
            dispatch({
              type: MIX_GOOGLE_PAGINATION,
              payload: response?.data?.google_ads?.facebook_ads?.paging?.next,
            })
            if (
              response?.data?.google_ads?.facebook_ads?.paging?.next ||
              response?.data?.meta_ads?.facebook_ads?.paging?.next
            )
              dispatch({
                type: HAS_MORE,
                payload: true,
              })
            else {
              dispatch({
                type: HAS_MORE,
                payload: false,
              })
            }
          }
        }
        if (mixData?.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
        }
      } else {
        const rawDataFromApi = response?.data?.result?.facebook_ads?.data
        let formattedData = []
        if (Array.isArray(rawDataFromApi)) {
          formattedData = rawDataFromApi.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad.advertiser_id ||
                brand.meta_page_id === ad.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }

        if (formattedData.length > 0) {
          if (formattedData.length >= 20) {
            dispatch({ type: TEMP_COUNT, payload: 20 })
          } else {
            dispatch({ type: TEMP_COUNT, payload: formattedData.length })
          }
        } else if (formattedData.length === 0) {
          dispatch({ type: TEMP_COUNT, payload: 0 })
        }
        if (formattedData?.length > 0) {
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
          dispatch({
            type: ADS_DATA,
            payload: formattedData,
          })
          dispatch({ type: ADS_NO_DATA, payload: false })
        }
        if (formattedData.length > 0) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData,
          })
          dispatch({ type: ADS_NO_DATA, payload: false })
        }
        if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Meta'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'All'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        if (
          response?.data?.result?.facebook_ads?.serpapi_pagination?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Google'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Google'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        if (formattedData?.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
        }
      }

      dispatch({ type: SPINNER, payload: false })
    } catch (error) {
      if (error?.response?.status === 429) {
        setUpgradeToProModal(true)
      }
      dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      dispatch({ type: ADS_NO_DATA, payload: true })
    }
  }
  const [shouldHandleSearchKeyword, setShouldHandleSearchKeyword] = useState(false)
  useEffect(() => {
    if (searchKeyword && shouldHandleSearchKeyword) {
      dispatch({ type: ADS_HOME_STATE, payload: false })
      // dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({ type: HAS_MORE, payload: false })
      setIsModalOpen(false)
      handleAdsApi()
      setShouldHandleSearchKeyword(false) // Reset the flag
    }
    // eslint-disable-next-line
  }, [searchKeyword, shouldHandleSearchKeyword])
  const handleSearchButton = () => {
    history.push('/dashboard/creative-library')
    dispatch({ type: INPUT_VALUES, payload: adsData?.additional_info?.page_name })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: adsData?.additional_info?.page_name })
    // dispatch({ type: ADS_CHANNEL, payload: 'All' })
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: BRAND_CHOSEN, payload: false })

    setIsModalOpen(true) // Open the modal
    setShouldHandleSearchKeyword(true) // Allow searchKeyword handling
  }

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="100%"
      footer={null}
      borderRadius="12px 12px 0px 0px"
    >
      <div className={style.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={style.closeButton} />
        </button>
      </div>
      <div className={style.modalContainer}>
        <div className={style.header}>
          <div className={style.heading}>
            {adsDate === localDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <img
                    src={adsData?.additional_info?.page_profile_picture_url}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <img
                    src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            )}
            {/* eslint-disable-next-line */}
            <div className={style.modalTitle} onClick={handleSearchButton}>
              <Text size={16} isBold className={style.pageName}>
                {adsData?.additional_info?.page_name}
              </Text>
            </div>
            <>
              {
                // eslint-disable-next-line
                followBrandsLoader || loaderForBrandsStatus ? (
                  <div style={{ textAlign: 'right' }}>
                    <Spin />
                  </div>
                ) : // eslint-disable-next-line
                !responseForBrandStatus.is_status ? (
                  <Button onClick={hanldeFollowBrands} className={style.follow_button}>
                    <div>Follow</div>
                  </Button>
                ) : responseForBrandStatus.is_status ? (
                  <>
                    {
                      // eslint-disable-next-line
                      responseForBrandStatus.message ===
                      'Brand exists in the FollowBrand table.' ? (
                        <Button
                          key="5"
                          onClick={hanldeUnFollowBrands}
                          className={style.follow_button}
                        >
                          <div>UnFollow</div>
                        </Button>
                      ) : // eslint-disable-next-line
                      responseForBrandStatus.message ===
                          'Brand exists in the FollowRequest table.' &&
                        responseForBrandStatus?.follow_request_status === 'pending' ? (
                        <Button key="6" className={style.follow_button}>
                          <div style={{ display: 'flex', gap: 8 }}>Request pending</div>
                        </Button>
                      ) : // eslint-disable-next-line
                      responseForBrandStatus.message ===
                          'Brand exists in the FollowRequest table.' &&
                        responseForBrandStatus.follow_request_status === 'approved' ? (
                        <Button
                          key="1"
                          onClick={hanldeFollowBrands}
                          className={style.follow_button}
                        >
                          <div style={{ display: 'flex', gap: 8 }}>Follow</div>
                        </Button>
                      ) : // eslint-disable-next-line
                      responseForBrandStatus.message ===
                          'Brand exists in the FollowRequest table.' &&
                        responseForBrandStatus.follow_request_status === 'disapproved' ? (
                        <Button key="5" style={{ opacity: 0.8 }} className={style.follow_button}>
                          <div style={{ display: 'flex', gap: 8 }}>Request Unsuccessful</div>
                        </Button>
                      ) : (
                        ''
                      )
                    }
                  </>
                ) : (
                  ''
                )
              }
            </>
          </div>
          <div className={style.headerButtonContainer}>
            {/* eslint-disable-next-line */}
            <img
              src={Previous}
              style={{
                cursor: isFirst ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isFirst ? 0.5 : 1,
              }}
              height={32}
              alt="previous"
              onClick={handlePreviousClick}
            />
            {/* eslint-disable-next-line */}
            <img
              src={Next}
              style={{
                cursor: isLast ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isLast ? 0.5 : 1,
              }}
              height={32}
              alt="next"
              onClick={handleNextClick}
            />
          </div>
        </div>
        <Row style={{ marginTop: 32 }} gutter={32}>
          {/* Left Part of Modal */}
          <Col span={12}>
            <ModalCard adsData={adsData} />
          </Col>
          {/* Right Part of Modal */}
          <Col span={12}>
            <AdDetailCard adsData={adsData} boardsList={boardsList} />
          </Col>
        </Row>
      </div>
      {isShareModalOpen && (
        <ShareModal
          //   adsData={modalData}
          modalOpen={isShareModalOpen}
          handleOk={handleShareOk}
          handleCancel={handleShareCancel}
        />
      )}
    </Modal>
  )
}

export default AdsModal
