/* eslint-disable import/named */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Card, Col, Row, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Text } from '@components'
import { axiosInstance } from '@services/requests'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import backgroundImage from '@images/leadFormBg.png'
import { delay, formatCurrency } from '../../utils'
import Footer from '../../components/Footer'
import styles from './leadForm.module.scss'
import { SecretService } from '../../services/secret'
import { LoginForm } from '../login-form'

const LeadFormPage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const { reportToken } = useParams()
  const [adSpend, setAdSpend] = useState(0)
  const [wastedSpendTotal, setWastedSpendTotal] = useState(0)
  const [adsGraderScore, setAdsGraderScore] = useState(0)
  const [impressionShare, setImpressionShare] = useState(0)
  const [showForm, setShowForm] = useState(true)
  const [progress, setProgress] = useState({ percent: 0, text: '' })
  const [overallSpend, setOverallSpend] = useState()
  const [adsGraderCustomerId, setAdsGraderCustomerId] = useState('')

  const [wholeReportData, setWholeReportData] = useState({})
  const history = useHistory()
  const customerId = useSelector(state => state?.customer?.id)

  const { t } = useTranslation()

  const reportPayload = useMemo(() => SecretService.decode(reportToken), [reportToken])
  return (
    <div className={styles.backgroundContainer}>
      <img className={styles.backgroundImage} src={backgroundImage} alt="backgroundImage" />
      <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Card className={styles.loginCard} hoverable>
          <LoginForm
            amount={overallSpend}
            currencyCode={reportPayload?.currency}
            adSpend={adSpend}
            impressionShare={impressionShare}
            adsGraderScore={adsGraderScore}
            wastedSpendTotal={wastedSpendTotal}
            googleAdsCustomerId={adsGraderCustomerId}
            reportId={wholeReportData?.id}
            reportUniqueId={wholeReportData?.unique_id}
            location={window?.location?.origin}
          />
        </Card>
      </div>
    </div>
  )
}

export default LeadFormPage
