import Text from '@components/Text/text'
import React, { useState } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import Divider from '../../../images/DiscoverAds/counterDivider.png'

const { Option } = Select
const AdsCounter = () => {
  const brandsFilter = useSelector(state => state?.discoverAds?.brandsFilter)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tempCount = useSelector(state => state?.discoverAds?.tempCount)

  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }} id="area">
      <div style={{ display: 'flex', gap: 11, marginTop: 15.5 }}>
        {
          // eslint-disable-next-line
          tempCount > 0 && !brandsFilter ? (
            <Text style={{ fontSize: 20 }}>
              {tempCount < 20 ? (
                <>
                  {' '}
                  <span style={{ fontWeight: 'bolder' }}>{tempCount} ads</span> match your search
                  criteria
                </>
              ) : (
                <>
                  {' '}
                  <span style={{ fontWeight: 'bolder' }}>{tempCount}+ ads</span> match your search
                  criteria
                </>
              )}
            </Text>
          ) : (
            ''
          )
        }

        {/* <img src={Divider} height={16} style={{ marginTop: 5 }} alt="divider" />
        <Text style={{ fontSize: 18, color: '#A3B0B3' }}>JANUARY 2054</Text> */}
      </div>
      {/* <Select
        disabled
        defaultValue="Sort by :Date"
        className="group-by-select"
        getPopupContainer={() => document.getElementById('area')}
        dropdownClassName="group-by-dropdown"
        style={{ width: 200 }}
      >
        <Option value="date">Date</Option>
        <Option value="popularity">Popularity</Option>
        <Option value="name">Name</Option>
      </Select> */}
    </div>
  )
}
export default AdsCounter
