import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { ADS_SEARCH_KEYWORD, INPUT_VALUES } from '@utils/constants'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Button } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.scss'
// eslint-disable-next-line
const urlPattern = /^(?![a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(?:\/[^\s]*)?$/i
const InputProceed = ({ isfooter }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const lang = localStorage.getItem('i18nextLng')
  const createValidationSchema = trans =>
    Yup.object({
      email: Yup.string()
        .required(trans('home.error2'))
        .matches(urlPattern, trans('home.error1'))
        .label('Website'),
    })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: createValidationSchema(t),
    onSubmit: values => {
      localStorage.setItem('url', JSON.stringify(values))
      dispatch({ type: INPUT_VALUES, payload: '' })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
      history.push('/start')
    },
  })
  useEffect(() => {
    const handleEvent = e => {
      if (e?.key === 'Enter') {
        formik.handleSubmit()
      }
    }
    document.addEventListener('keydown', handleEvent)
    return () => {
      document.removeEventListener('keydown', handleEvent)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const newValidationSchema = createValidationSchema(t)
    formik.setFormikState(prevState => ({
      ...prevState,
      validationSchema: newValidationSchema,
    }))
    formik.validateForm()
    // eslint-disable-next-line
  }, [lang])
  return (
    <>
      <form className={styles.containerInput} onSubmit={formik.handleSubmit}>
        <input
          placeholder="Enter your website"
          className={classNames([
            styles.inputEmail,
            { [styles.isNot]: formik?.errors?.email && formik?.touched?.email },
          ])}
          {...formik.getFieldProps('email')}
        />
        <Button htmlType="submit" className={styles.proceedButton} onClick={formik.handleSubmit}>
          Get Started
        </Button>
      </form>
      {formik?.errors?.email && formik?.touched?.email && (
        <p className={isfooter ? styles.isFooter : styles.error}>{formik.errors.email}</p>
      )}
    </>
  )
}
InputProceed.propTypes = {}
InputProceed.defaultProps = {}
export default InputProceed
