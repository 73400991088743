import { Tabs, Input, Select, Row, Col, AutoComplete, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  ADS_CHANNEL,
  ADS_FORMAT,
  ADS_LOCATION,
  ADS_SEARCH_KEYWORD,
  ADS_LOADER,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  SPINNER,
  ADS_NO_DATA,
  ADS_DATA,
  ADS_HOME_STATE,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  CLEAR_STATE,
  CLEAR_BRANDS_FLITER,
  IS_META_STATUS,
  IS_GOOGLE_STATUS,
  ACTIVE_ADS_COUNT,
  IN_ACTIVE_ADS_COUNT,
  BRAND_CHOSEN,
  INPUT_VALUES,
  TRIGGER_UPDATE_FOLLOW_BRANDS,
  MIX_GOOGLE_PAGINATION,
  MIX_META_PAGINATION,
  ACTIVE_KEY,
  CHECKED_VALUES,
  TRIGGER_SEARCH,
  LANDING_PAGE_DATA,
  PAGE_ID_AND_ADVERTISER_ID,
  BRANDS_LIST,
  BRAND_TAG_FOR_GOOGLE,
  BRAND_TAG_FOR_ALL,
  LIVE_SEARCH,
  BRAND_TAG_FOR_META,
  CLEAR_BRANDS_TAG_FLAG,
  META_BRANDS_LIST,
  GOOGLE_BRANDS_LIST,
  ALL_BRANDS_LIST,
  FOLLOWED_BRANDS_LIST,
} from '@utils/constants'
import Text from '@components/Text/text'
import UpgradeToPro from '@components/UpgradeToPro'
import { SearchOutlined } from '@ant-design/icons'
import ImageIcon from '@images/DiscoverAds/DropdownIcons/imageIcon.png'
import { notify } from '@utils/notify'
import { RequestService, axiosInstance } from '@services/requests'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import FilterArrow from '@images/DiscoverAds/SearchBar/filterArrow.png'
import GoogleIcon from '@images/DiscoverAds/SearchBar/GoogleIcon.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import AllIcon from '@images/DiscoverAds/SearchBar/AllIcon.png'
import { googleCountries, metaCountries } from '../../../data/discoverData'
import styles from './searchBar.module.css'

const { TabPane } = Tabs
const { Option } = Select

const SearchBar = () => {
  const dispatch = useDispatch()
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)
  const brandsTagForAllOrignal = useSelector(state => state?.discoverAds?.homePageBrands)
  const brandsTagForGoogleOriginal = useSelector(state => state?.discoverAds?.brandsTagForGoogle)
  const brandsTagForMetaOriginal = useSelector(state => state?.discoverAds?.brandsTagForMeta)
  const brandsList = useSelector(state => state?.discoverAds?.brandsList)
  const landingPageData = useSelector(state => state?.discoverAds?.landingPageData)
  // const [landingPageData, setLandingPageData] = useState([])
  const [upgradeToProModal, setUpgradeToProModal] = useState(false)
  const [metaOptions, setMetaOptions] = useState([])
  const [googleOptions, setGoogleOptions] = useState([])
  const [allOptions, setAllOptions] = useState([])
  const searchKeyword = useSelector(state => state?.discoverAds?.searchKeyword)
  const testData = useSelector(state => state?.discoverAds?.pageIdAndAdvertiserId)
  const triggerSeach = useSelector(state => state?.discoverAds?.triggerSeach)
  const [isRotated, setIsRotated] = useState(false)
  const [selectedOptionsFormat, setSelectedOptionsFormat] = useState()
  const [selectedLocation, setSelectedLocation] = useState([])
  const [isFilterActive, setIsFilterActive] = useState(true)
  const activeKey = useSelector(state => state?.discoverAds?.activeKey)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const inputValues = useSelector(state => state?.discoverAds?.inputValues)
  const location = useSelector(state => state?.discoverAds?.location)
  const adsFormats = useSelector(state => state?.discoverAds?.formats)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const moreDataLoader = useSelector(state => state?.discoverAds?.moreDataLoader)
  const allOptionPermanent = useSelector(state => state?.discoverAds?.allBrandsList)
  const googleOptionPermanent = useSelector(state => state?.discoverAds?.googleBrandsList)
  const metaOptionPermanent = useSelector(state => state?.discoverAds?.metaBrandsList)
  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  useEffect(() => {
    getFollowedBrandsList()
    // eslint-disable-next-line
  }, [])
  const handleOpenModal = () => {
    setUpgradeToProModal(true)
  }

  const handleCloseModal = () => {
    setUpgradeToProModal(false)
  }
  const handleSignUpModal = () => {
    setUpgradeToProModal(true)
  }
  const filterClick = () => {
    setIsFilterActive(!isFilterActive)
    setIsRotated(!isRotated)
  }
  const handleKeywordSearchForMeta = value => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
    const filteredOptions = metaOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))
    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }

    // Update meta options
    setMetaOptions(filteredOptions)
  }
  const handleInputChangeForMeta = value => {
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })

    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    const filteredOptions = metaOptionPermanent
      .filter(suggestion => suggestion.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))
    const options = []
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <Text
              style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}
              onClick={() => dispatch({ type: BRAND_CHOSEN, payload: false })}
            >
              Search this exact phrase
            </Text>
          </div>
        ),
        value: `${value}`,
      })
    }

    // Conditionally add the divider if there are advertiser matches
    if (filteredOptions.length > 0) {
      options.push({
        label: <Divider style={{ cursor: 'pointer', margin: '5px 0' }} />,
        value: 'divider',
        disabled: true,
      })
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1
            style={{
              fontSize: '18px',
              color: '#f16434',
              fontWeight: 'bold',
              margin: '4px 0',
              cursor: 'text',
            }}
          >
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setMetaOptions(options)
  }

  const handleKeywordSearchForAll = value => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
    const filteredOptions = allOptionPermanent
      .filter(suggestion => suggestion?.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.value,
        googleId: suggestion?.googleId,
        metaId: suggestion?.metaId,
      }))
    setAllOptions(filteredOptions)
    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
  }
  const handleInputChangeForAll = value => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter Google brand suggestions based on the input
    const filteredOptions = allOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        googleId: suggestion?.googleId,
        metaId: suggestion?.metaId,
      }))

    const options = []

    // Conditionally add "Search this exact phrase" option
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <Text style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}>
              Search this exact phrase
            </Text>
          </div>
        ),
        value: `${value}`,
      })

      // Conditionally add the divider if there are advertiser matches
      if (filteredOptions.length > 0) {
        options.push({
          label: <Divider style={{ cursor: 'pointer', margin: '5px 0' }} />, // Added cursor: 'pointer' for pointer style
          value: 'divider',
          disabled: true,
        })
      }
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1
            style={{
              fontSize: '18px',
              color: '#f16434',
              fontWeight: 'bold',
              margin: '4px 0',
              cursor: 'text',
            }}
          >
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setAllOptions(options)
  }

  const handleKeywordSearchForGoogle = value => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })
    dispatch({ type: INPUT_VALUES, payload: value })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter options based on input
    const filteredOptions = googleOptionPermanent
      .filter(suggestion => suggestion?.value?.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.value,
        id: suggestion?.id,
      }))

    if (filteredOptions.length === 1) {
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
    setGoogleOptions(filteredOptions)
  }

  const handleInputChangeForGoogle = value => {
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: [] })
    dispatch({ type: INPUT_VALUES, payload: value })
    dispatch({ type: BRAND_CHOSEN, payload: false })
    let enteredValue = value.trim()
    enteredValue = enteredValue.replace(/^https?:\/\//, '').replace(/^www\./, '')
    if (enteredValue.endsWith('/')) {
      const parts = enteredValue.split('/')
      if (parts.length === 2 || (parts.length === 3 && parts[2] === '')) {
        enteredValue = enteredValue.slice(0, -1) // Remove the last slash
      }
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })

    // Filter Google brand suggestions based on the input
    const filteredOptions = googleOptionPermanent
      .filter(suggestion => suggestion.value.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        label: suggestion?.value,
        value: suggestion?.value,
        id: suggestion?.id,
      }))

    const options = []

    // Conditionally add "Search this exact phrase" option
    if (value) {
      options.push({
        label: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined style={{ marginRight: 8 }} />
              <span>&quot;{value}&quot;</span>
            </div>
            <div style={{ fontStyle: 'italic', color: '#8c8c8c', marginTop: 4 }}>
              Search this exact phrase
            </div>
          </div>
        ),
        value: `${value}`,
      })

      // Conditionally add the divider only if there are advertiser matches
      if (filteredOptions.length > 0) {
        options.push({
          label: <Divider style={{ margin: '5px 0' }} />, // Adjusted space around the divider
          value: 'divider',
          disabled: true,
        })
      }
    }

    // Conditionally add "Advertisers" header and filtered options if available
    if (filteredOptions.length > 0) {
      options.push({
        label: (
          <h1 style={{ fontSize: '18px', color: '#f16434', fontWeight: 'bold', margin: '4px 0' }}>
            Advertisers
          </h1>
        ),
        value: 'advertisers-header',
        disabled: true,
      })
      options.push(...filteredOptions)
    }

    setGoogleOptions(options)
  }

  const handleSelectFormat = selectedValues => {
    setSelectedOptionsFormat(selectedValues)
    dispatch({
      type: ADS_FORMAT,
      payload: selectedValues?.includes('All') ? 'All' : selectedValues,
    })
  }
  const handleSelectChannels = async selectedValues => {
    dispatch({ type: ADS_NO_DATA, payload: false })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    if (selectedValues === '1') {
      dispatch({ type: ADS_CHANNEL, payload: 'Google' })
      dispatch({ type: BRAND_CHOSEN, payload: false })
    } else if (selectedValues === '2') {
      dispatch({ type: ADS_CHANNEL, payload: 'Meta' })
      dispatch({ type: BRAND_CHOSEN, payload: false })
    } else if (selectedValues === '3') {
      dispatch({ type: ADS_CHANNEL, payload: 'All' })
      dispatch({ type: BRAND_CHOSEN, payload: false })
    }
    if (searchKeyword) {
      dispatch({ type: TRIGGER_SEARCH, payload: true })
    }
    const newMetaOptions = metaOptionPermanent?.map(suggestion => ({
      label: suggestion?.value,
      value: suggestion?.value,
      id: suggestion?.id,
    }))

    const newGoogleOptions = googleOptionPermanent?.map(suggestion => ({
      label: suggestion?.value,
      value: suggestion?.value,
      id: suggestion?.id,
    }))
    const newAllOptions = allOptionPermanent?.map(suggestion => ({
      value: suggestion?.value,
      googleId: suggestion?.googleId,
      metaId: suggestion?.metaId,
    }))

    setMetaOptions(newMetaOptions)
    setGoogleOptions(newGoogleOptions)
    setAllOptions(newAllOptions)
    // dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    setSelectedOptionsFormat([]) // Clear ad formats
    setSelectedLocation([]) // Clear location
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ADS_HOME_STATE, payload: true })
  }

  const onChangeLocation = value => {
    dispatch({ type: ADS_LOCATION, payload: value })
    setSelectedLocation(value)
  }
  const clearState = () => {
    dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
    dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
  }
  const clearStateForInputs = () => {
    // dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({ type: HAS_MORE, payload: false })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
    dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
  }
  const handleAdsApi = async () => {
    dispatch({
      type: MIX_META_PAGINATION,
      payload: '',
    })

    dispatch({
      type: MIX_GOOGLE_PAGINATION,
      payload: '',
    })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    dispatch({ type: CHECKED_VALUES, payload: ['Active', 'Inactive'] })
    dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: 0 })
    dispatch({ type: ACTIVE_ADS_COUNT, payload: 0 })
    dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({
      type: CLEAR_STATE,
      payload: [],
    })
    dispatch({ type: SPINNER, payload: true })
    dispatch({ type: ADS_LOADER, payload: true })
    const data = {
      brand_name: searchKeyword,
    }
    const brandStatus = await axiosInstance.post('save/brand-status/', data)
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
    try {
      let response = []

      if (selectedChannel === 'Meta') {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${20}&creative_format=${
            adsFormats.length > 0 ? adsFormats.toString().toLowerCase().replace(/s$/, '') : 'all'
          }&channels=[${selectedChannel}]&ad_reached_countries=["${
            location === '0000' ? '' : location
            // eslint-disable-next-line
          }"]&is_brand=${brandStatus?.data?.page_id ? true : false}&advertiser_id=${
            brandStatus?.data?.page_id
          }`
        )
        dispatch({ type: IS_META_STATUS, payload: response?.data?.is_meta })
        if (response?.data?.is_meta) {
          dispatch({ type: LIVE_SEARCH, payload: true })
        }
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
      } else if (selectedChannel === 'Google') {
        let paginationCounnt = 30
        if (adsFormats === 'Videos' || adsFormats === 'all') {
          paginationCounnt = 7
        }
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${paginationCounnt}&creative_format=${
            adsFormats === 'All' ? '' : adsFormats.toString().toLowerCase().replace(/s$/, '')
          }&channels=[${selectedChannel}]&region=${
            location === '0000' ? '' : location
            // eslint-disable-next-line
          }&is_brand=${brandStatus?.data?.google_advertiser_id ? true : false}&page_id=${
            brandStatus?.data?.google_advertiser_id
          }`
        )

        dispatch({ type: IS_GOOGLE_STATUS, payload: response?.data?.is_facebook })
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
        if (response?.data?.is_facebook) {
          dispatch({ type: LIVE_SEARCH, payload: true })
        }
      } else if (selectedChannel === 'All') {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?channels=[All]&search_term=${searchKeyword}&is_brand=${
            // eslint-disable-next-line
            brandStatus?.data?.google_advertiser_id || brandStatus?.data?.page_id ? true : false
          }&num=${20}&page_id=${brandStatus?.data?.google_advertiser_id}&advertiser_id=${
            brandStatus.data.page_id
          }&ad_reached_countries=["${location === '0000' ? '' : location}"]`
        )
      }
      dispatch({ type: ADS_LOADER, payload: false })

      if (response?.data?.is_meta_or_google) {
        dispatch({ type: LIVE_SEARCH, payload: true })
        const googleResults = response?.data?.google_ads?.facebook_ads?.data || []
        const metaResults = response?.data?.meta_ads?.facebook_ads?.data || []
        const maxLength = Math.max(googleResults.length, metaResults.length)
        let mixData = []
        const mixRawData = []

        for (let i = 0; i < maxLength; i += 1) {
          if (i < googleResults?.length) {
            mixRawData.push(googleResults[i])
          }
          if (i < metaResults.length) {
            mixRawData.push(metaResults[i])
          }
        }

        if (Array.isArray(mixRawData)) {
          mixData = mixRawData.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad?.advertiser_id ||
                brand.meta_page_id === ad?.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        if (mixData.length > 0) {
          dispatch({ type: ADS_NO_DATA, payload: false })
          dispatch({
            type: ADS_DATA,
            payload: mixData,
          })
          if (
            response?.data?.meta_ads?.facebook_ads?.paging?.next &&
            mixData.length > 0 &&
            selectedChannel === 'All'
          ) {
            dispatch({
              type: MIX_META_PAGINATION,
              payload: response?.data?.meta_ads?.facebook_ads?.paging?.next,
            })
            dispatch({
              type: HAS_MORE,
              payload: true,
            })
          }
          if (
            response?.data?.google_ads?.facebook_ads?.paging?.next &&
            mixData.length > 0 &&
            selectedChannel === 'All'
          ) {
            dispatch({
              type: MIX_GOOGLE_PAGINATION,
              payload: response?.data?.google_ads?.facebook_ads?.paging?.next,
            })
            if (
              response?.data?.google_ads?.facebook_ads?.paging?.next ||
              response?.data?.meta_ads?.facebook_ads?.paging?.next
            )
              dispatch({
                type: HAS_MORE,
                payload: true,
              })
            else {
              dispatch({
                type: HAS_MORE,
                payload: false,
              })
            }
          }
        }
        if (mixData?.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
        }
      } else {
        const rawDataFromApi = response?.data?.result?.facebook_ads?.data
        let formattedData = []
        if (Array.isArray(rawDataFromApi)) {
          formattedData = rawDataFromApi.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad.advertiser_id ||
                brand.meta_page_id === ad.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }

        if (formattedData?.length > 0) {
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
          dispatch({
            type: ADS_DATA,
            payload: formattedData,
          })
          dispatch({ type: ADS_NO_DATA, payload: false })
        }
        if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Meta'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'All'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        if (
          response?.data?.result?.facebook_ads?.serpapi_pagination?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Google'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else if (
          response?.data?.result?.facebook_ads?.paging?.next &&
          formattedData.length > 0 &&
          selectedChannel === 'Google'
        ) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data?.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }

        if (formattedData?.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
        }
      }

      dispatch({ type: SPINNER, payload: false })
      dispatch({ type: TRIGGER_SEARCH, payload: false })
    } catch (error) {
      console.log('🚀 ~ handleAdsApi ~ error:', error)
      if (error?.response?.status === 429) {
        setUpgradeToProModal(true)
      }
      dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      dispatch({ type: ADS_NO_DATA, payload: true })
      dispatch({ type: TRIGGER_SEARCH, payload: false })
    }
  }

  const handleSearchButton = () => {
    clearState()
    if (inputValues.trim() !== '') {
      dispatch({ type: CHECKED_VALUES, payload: ['Active', 'Inactive'] })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: searchKeyword })
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({
        type: HAS_MORE,
        payload: false,
      })
      handleAdsApi()
    } else {
      notify('Search Keyword cannot be empty', 'error')
    }
  }
  useEffect(() => {
    if (triggerSeach && searchKeyword) {
      handleAdsApi()
    }
    // eslint-disable-next-line
  }, [triggerSeach])

  const getbrands = async () => {
    try {
      const response = await axiosInstance.get('/save/brands-v1/?is_combined=true')

      const newAllOptions = response.data?.all_brands?.map(suggestion => ({
        value: suggestion?.brand_name,
        googleId: suggestion?.google_advertiser_id,
        metaId: suggestion?.meta_page_id,
      }))
      dispatch({ type: ALL_BRANDS_LIST, payload: newAllOptions })
      setAllOptions(newAllOptions)
      const newGoogleOptions = response.data?.google_brands?.map(suggestion => ({
        label: suggestion?.brand_name,
        value: suggestion?.brand_name,
        id: suggestion?.google_advertiser_id,
      }))
      dispatch({ type: GOOGLE_BRANDS_LIST, payload: newGoogleOptions })
      setGoogleOptions(newGoogleOptions)
      const newMetaOptions = response.data?.meta_brands?.map(suggestion => ({
        label: suggestion?.brand_name,
        value: suggestion?.brand_name,
        id: suggestion?.meta_page_id,
      }))
      dispatch({ type: META_BRANDS_LIST, payload: newMetaOptions })
      setMetaOptions(newMetaOptions)
    } catch (error) {
      // console.log('🚀 ~ searchSuggestions ~ error:', error)
    }
  }

  useEffect(() => {
    if (
      allOptionPermanent.length <= 0 ||
      googleOptionPermanent.length <= 0 ||
      metaOptionPermanent.length <= 0
    ) {
      getbrands()
    } else {
      setAllOptions(allOptionPermanent)
      setGoogleOptions(googleOptionPermanent)
      setMetaOptions(metaOptionPermanent)
    }
    // eslint-disable-next-line
  }, [allOptionPermanent, googleOptionPermanent, metaOptionPermanent])

  const getLandingPageAds = async () => {
    if (landingPageData?.length <= 0) {
      dispatch({ type: LIVE_SEARCH, payload: true })
      dispatch({ type: SPINNER, payload: true })
      dispatch({ type: ADS_LOADER, payload: true })
      let brandsData = []
      try {
        brandsData = await RequestService.get('save/followed-brands-status/')
        dispatch({ type: FOLLOWED_BRANDS_LIST, payload: brandsData?.data?.data })
      } catch (error) {
        console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
      }

      try {
        const response = await RequestService.get(
          'ads-preview/competitors-prod/?is_creative_library=True'
        )
        const facebookAds = response?.data?.result?.facebook_ads?.data || []
        const top50FacebookAdsRaw = facebookAds
        let top50FacebookAds = []
        const googleAds = response?.data?.result?.google_ads?.data || []
        const top50GoogleAdsRaw = googleAds
        let top50GoogleAds = []
        const combinedAds = response?.data?.result?.combined_ads?.data || []

        let sortedCombinedAds = []
        const sortedCombinedAdsRaw = combinedAds

        // Filter unique ads
        if (Array.isArray(sortedCombinedAdsRaw)) {
          sortedCombinedAds = sortedCombinedAdsRaw.map(ad => {
            const isFollowed = brandsData?.data?.data.some(
              brand =>
                brand.google_advertiser_id === ad?.advertiser_id ||
                brand.meta_page_id === ad?.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        if (Array.isArray(top50FacebookAdsRaw)) {
          top50FacebookAds = top50FacebookAdsRaw.map(ad => {
            const isFollowed = brandsData?.data?.data.some(
              brand => brand.meta_page_id === ad.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        if (Array.isArray(top50GoogleAdsRaw)) {
          top50GoogleAds = top50GoogleAdsRaw.map(ad => {
            const isFollowed = brandsData?.data?.data.some(
              brand => brand.google_advertiser_id === ad.advertiser_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        dispatch({
          type: LANDING_PAGE_DATA,
          payload: {
            combined_ads: { data: sortedCombinedAds },
            facebook_ads: { data: top50FacebookAds },
            google_ads: { data: top50GoogleAds },
          },
        })
        // setLandingPageData({
        //   combined_ads: { data: sortedCombinedAds },
        //   facebook_ads: { data: top50FacebookAds },
        //   google_ads: { data: top50GoogleAds },
        // })
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        if (selectedChannel === 'All') {
          dispatch({ type: ADS_DATA, payload: sortedCombinedAds })
          const activeCountLoop = sortedCombinedAds.filter(
            item =>
              item?.additional_info?.is_active === true ||
              item?.additional_info?.is_active === 'true'
          ).length

          const inactiveCountLoop = sortedCombinedAds.filter(
            item =>
              item?.additional_info?.is_active === false ||
              item?.additional_info?.is_active === 'false'
          ).length
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
        } else if (selectedChannel === 'Meta') {
          dispatch({ type: ADS_DATA, payload: top50FacebookAds })
          const activeCountLoop = top50FacebookAds.filter(
            item =>
              item?.additional_info?.is_active === true ||
              item?.additional_info?.is_active === 'true'
          ).length

          const inactiveCountLoop = top50FacebookAds.filter(
            item =>
              item?.additional_info?.is_active === false ||
              item?.additional_info?.is_active === 'false'
          ).length
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
        } else if (selectedChannel === 'Google') {
          dispatch({ type: ADS_DATA, payload: top50GoogleAds })
          const activeCountLoop = top50GoogleAds.filter(
            item =>
              item?.additional_info?.is_active === true ||
              item?.additional_info?.is_active === 'true'
          ).length

          const inactiveCountLoop = top50GoogleAds.filter(
            item =>
              item?.additional_info?.is_active === false ||
              item?.additional_info?.is_active === 'false'
          ).length
          dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
          dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
        }
      } catch (error) {
        console.error(error)
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
      }
    } else {
      const combinedAds = landingPageData?.combined_ads?.data
      const facebookAds = landingPageData?.facebook_ads?.data
      const googleAds = landingPageData?.google_ads?.data

      if (selectedChannel === 'All') {
        dispatch({ type: ADS_DATA, payload: combinedAds })
        const activeCountLoop = combinedAds.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        ).length

        const inactiveCountLoop = combinedAds.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        ).length
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
      } else if (selectedChannel === 'Meta') {
        dispatch({ type: ADS_DATA, payload: facebookAds })
        const activeCountLoop = facebookAds.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        ).length

        const inactiveCountLoop = facebookAds.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        ).length
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
      } else if (selectedChannel === 'Google') {
        dispatch({ type: ADS_DATA, payload: googleAds })
        const activeCountLoop = googleAds.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        ).length

        const inactiveCountLoop = googleAds.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        ).length
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: inactiveCountLoop })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: activeCountLoop })
      }
    }
  }

  useEffect(() => {
    if (!searchKeyword) {
      getLandingPageAds()
    }
    // eslint-disable-next-line
  }, [selectedChannel, searchKeyword])

  return (
    <>
      <div className={styles.searchBar}>
        <Row align="middle" justify="space-between">
          <Col flex="auto">
            <Tabs
              defaultActiveKey={activeKey}
              className="search-tabs"
              onChange={key => {
                if (!loading && !moreDataLoader) {
                  // setActiveKey(key)
                  dispatch({ type: ACTIVE_KEY, payload: key })
                  handleSelectChannels(key)
                }
              }}
            >
              <TabPane
                tab={
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={AllIcon} alt="all-icon" height={24} style={{ marginTop: '1px' }} />
                    <span
                      style={{
                        color: activeKey === '3' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '3' ? 'bold' : 'normal', // Set bold for active tab
                        marginTop: '2.85px',
                      }}
                      className={styles.tabTitle}
                    >
                      All Ads
                    </span>
                  </div>
                }
                key="3"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
              <TabPane
                tab={
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={GoogleIcon} alt="google-icon" width={23} height={23} />
                    <span
                      style={{
                        color: activeKey === '1' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '1' ? 'bold' : 'normal', // Set bold for active tab
                      }}
                      className={styles.tabTitle}
                    >
                      Google Ads
                    </span>
                  </div>
                }
                key="1"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
              <TabPane
                tab={
                  <div style={{ display: 'flex' }}>
                    <img src={MetaIcon} alt="meta-icon" height={24} style={{ marginTop: '1px' }} />
                    <span
                      style={{
                        color: activeKey === '2' ? '#26282C' : '#8F8F8F',
                        fontWeight: activeKey === '2' ? 'bold' : 'normal', // Set bold for active tab
                        marginTop: '2.85px',
                      }}
                      className={styles.tabTitle}
                    >
                      Meta Ads
                    </span>
                  </div>
                }
                key="2"
                disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
              />
            </Tabs>
          </Col>
        </Row>

        <div style={{ display: isFilterActive ? 'block' : 'none' }}>
          <Row gutter={12} className={styles.parentDiv}>
            <Col span={selectedChannel === 'All' ? 20 : 10} className={styles.inputContainer}>
              {
                // eslint-disable-next-line
                selectedChannel === 'Meta' ? (
                  <div className={styles.input} id="MetaAutoComplete">
                    <AutoComplete
                      getPopupContainer={() => document.getElementById('MetaAutoComplete')}
                      options={metaOptions}
                      onSelect={value => {
                        handleKeywordSearchForMeta(value) // Handle the selected value
                        dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                      }}
                      onSearch={value => handleInputChangeForMeta(value)}
                      style={{ width: '100%' }}
                      value={inputValues}
                    >
                      <Input
                        allowClear
                        id="keywordSearchInput"
                        className={styles.inputText}
                        placeholder="Search by keyword, brand, or URL."
                        prefix={
                          <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                        }
                        onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                        onChange={e => {
                          if (e.target.value === '') {
                            clearStateForInputs()
                            dispatch({
                              type: EMPTY_ADS_PREVIEW_DATA,
                            })
                            dispatch({
                              type: ADS_DATA,
                              payload: landingPageData?.facebook_ads?.data,
                            })
                          }
                        }}
                      />
                    </AutoComplete>
                  </div>
                ) : // eslint-disable-next-line
                selectedChannel === 'Google' ? (
                  <div className={styles.input} id="GoogleAutoComplete">
                    <AutoComplete
                      getPopupContainer={() => document.getElementById('GoogleAutoComplete')}
                      options={googleOptions}
                      onSelect={value => {
                        handleKeywordSearchForGoogle(value) // Handle the selected value
                        dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                      }}
                      onSearch={value => handleInputChangeForGoogle(value)}
                      style={{ width: '100%' }}
                      value={inputValues}
                    >
                      <Input
                        allowClear
                        id="keywordSearchInput"
                        className={styles.inputText}
                        placeholder="Search by keyword, brand, or URL."
                        prefix={
                          <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                        }
                        onChange={e => {
                          if (e.target.value === '') {
                            clearStateForInputs()
                            dispatch({
                              type: EMPTY_ADS_PREVIEW_DATA,
                            })
                            if (selectedChannel === 'All') {
                              dispatch({
                                type: ADS_DATA,
                                payload: landingPageData?.combined_ads?.data,
                              })
                            } else if (selectedChannel === 'Meta') {
                              dispatch({
                                type: ADS_DATA,
                                payload: landingPageData?.facebook_ads?.data,
                              })
                            } else if (selectedChannel === 'Google') {
                              dispatch({
                                type: ADS_DATA,
                                payload: landingPageData?.google_ads?.data,
                              })
                            }
                          }
                        }}
                        onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                      />
                    </AutoComplete>
                  </div>
                ) : selectedChannel === 'All' ? (
                  <div className={styles.input} id="AllAutoComplete">
                    <AutoComplete
                      getPopupContainer={() => document.getElementById('AllAutoComplete')}
                      options={allOptions}
                      onSelect={value => {
                        handleKeywordSearchForAll(value) // Handle the selected value
                        dispatch({ type: BRAND_CHOSEN, payload: true }) // Set BRAND_CHOSEN to true
                      }}
                      onSearch={value => handleInputChangeForAll(value)}
                      style={{ width: '100%' }}
                      value={inputValues}
                    >
                      <Input
                        allowClear
                        id="keywordSearchInput"
                        className={styles.inputText}
                        placeholder="Search by keyword, brand, or URL."
                        prefix={
                          <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                        }
                        onChange={e => {
                          if (e.target.value === '') {
                            clearStateForInputs()
                            dispatch({
                              type: EMPTY_ADS_PREVIEW_DATA,
                            })
                            dispatch({
                              type: ADS_DATA,
                              payload: landingPageData?.combined_ads?.data,
                            })
                          }
                        }}
                        onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                      />
                    </AutoComplete>
                  </div>
                ) : (
                  ''
                )
              }
            </Col>
            {selectedChannel === 'All' ? (
              ''
            ) : (
              <>
                <Col span={5} style={{ position: 'relative' }} id="area">
                  <Select
                    disabled={selectedChannel === 'All'}
                    id="location"
                    showSearch
                    value={location || undefined}
                    placeholder={
                      selectedChannel === 'Google' ? 'Location: Anywhere' : 'Language: All'
                    }
                    optionFilterProp="children"
                    onChange={onChangeLocation}
                    dropdownClassName="ads-preview-dropdown"
                    getPopupContainer={() => document.getElementById('area')}
                    style={{
                      display: 'block',
                      fontSize: 14,
                    }}
                    filterOption={(input, option) => {
                      const label = option.children[1] // Access the label part of the children
                      return label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                  >
                    {selectedChannel === 'Meta' &&
                      metaCountries.map(country => (
                        <Option
                          key={country.value}
                          value={country.value}
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            lineHeight: '16px',
                          }}
                        >
                          {country.flag ? (
                            <img
                              src={country.flag}
                              alt="flag"
                              width={24}
                              height={24}
                              style={{ marginRight: 8 }}
                            />
                          ) : (
                            ''
                          )}

                          {country.label}
                        </Option>
                      ))}

                    {selectedChannel === 'Google' &&
                      googleCountries.map(country => (
                        <Option
                          key={country.value}
                          value={country.value}
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            lineHeight: '16px',
                          }}
                        >
                          {country.flag ? (
                            <img
                              src={country.flag}
                              alt="flag"
                              width={24}
                              height={24}
                              style={{ marginRight: 8 }}
                            />
                          ) : (
                            ''
                          )}
                          {country.label}
                        </Option>
                      ))}
                  </Select>
                </Col>
                <Col span={5}>
                  {
                    <Select
                      disabled={selectedChannel === 'All'}
                      placeholder="Ad Formats: All formats"
                      style={{
                        display: 'block',
                        fontSize: 14,
                        borderColor: '#E8EBEC !important',
                      }}
                      dropdownClassName="ads-preview-dropdown"
                      onChange={handleSelectFormat}
                      showArrow
                      value={adsFormats || undefined}
                      showSearch={false}
                      getPopupContainer={() => document.getElementById('area')}
                      optionLabelProp="label"
                    >
                      <Option value="All">
                        <div className={styles.options}>
                          <span className={styles.checkbox}>All</span>
                        </div>
                      </Option>

                      {selectedChannel === 'Google' && (
                        <Option value="Text">
                          <div className={styles.options}>
                            {/* <img src={ImageIcon} alt="imageIcon" /> */}
                            <span className={styles.checkbox}>Text</span>
                          </div>
                        </Option>
                      )}
                      <Option value="Videos">
                        <div className={styles.options}>
                          {/* <img src={ImageIcon} alt="imageIcon" /> */}
                          <span className={styles.checkbox}>Videos</span>
                        </div>
                      </Option>
                      <Option value="Images">
                        <div className={styles.options}>
                          {/* <img src={ImageIcon} alt="imageIcon" /> */}
                          <span className={styles.checkbox}>Images</span>
                        </div>
                      </Option>
                    </Select>
                  }
                </Col>
              </>
            )}

            <Col span={selectedChannel === 'All' ? 4 : 4}>
              <button
                type="submit"
                className={styles.searchButton}
                onClick={handleSearchButton}
                disabled={loading || moreDataLoader}
              >
                SEARCH
              </button>
            </Col>
          </Row>
        </div>
      </div>
      {upgradeToProModal ? (
        <UpgradeToPro isModalVisible={upgradeToProModal} onCancel={handleCloseModal} />
      ) : (
        ''
      )}
    </>
  )
}
export default SearchBar
