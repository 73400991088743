import React, { useState } from 'react'
import { Modal, Button, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { axiosInstance } from '@services/requests'
import { notify } from '@utils/notify'
import { useSelector } from 'react-redux'
import styles from './verificationEmail.module.css'

const { Text } = Typography

const VerificationEmailModal = ({ visible, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const email = useSelector(state => state?.role?.email)
  const handleResendEmail = async () => {
    if (email) {
      setIsLoading(true)
      try {
        const data = {
          user_email: email,
        }
        const response = await axiosInstance.post('accounts/resend-verification-emails/', data)
        setIsLoading(false)
        notify('Email sent successfully', 'success')
      } catch (error) {
        setIsLoading(false)
        notify('Something went wrong', 'error')
      }
    }
  }

  return (
    <Modal
      title={
        <>
          <div
            style={{
              textAlign: 'center',
              marginTop: 30,
              fontSize: 24,
              fontWeight: '600',
            }}
          >
            Account not verified
          </div>
          <div className={styles.divider} />
        </>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Text style={{ display: 'block', marginBottom: 20, textAlign: 'center' }}>
        It looks like you haven’t verified your email yet. Please check your inbox and verify your
        email to continue.
      </Text>
      <Button
        className={styles.searchButton}
        type="primary"
        htmlType="submit"
        loading={isLoading}
        onClick={handleResendEmail}
      >
        Resend Email
      </Button>
      <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onClose}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div>
    </Modal>
  )
}

export default VerificationEmailModal
