import { combineReducers } from 'redux'
import { LOGOUT_USER } from '@utils/constants'
import { userReducer } from './userReducer'
import { customerReducer } from './customerReducer'
import { reportReducer } from './reportReducer'
import { userRole } from './userRole'
import { errorCustomer } from './errorCustomerReducer'
import { reportDashboard } from './reportDashboardReducer'
import { generateReport } from './generateReportReducer'
import { currentReportReducer } from './currentReportReducer'
import { sampleReportReducer } from './sampleReportReducer/sampleReportReducer'
import adsPreviewReducer from './adsPreviewReducer'
import { learningCenterReducer } from './learningCenterReducer'
import { learningCenterMessageFromModal } from './learningCenterMessageFromModal'
import { weeklyReport } from './weeklyReportReducer'
import { extraInsights } from './extraInsightsReducer'
import { boardsReducer } from './discoverAdsReducer'
import { UnAuthDiscoverAdsReducer } from './UnAuthDiscoverAdsReducer'

const appreducer = combineReducers({
  user: userReducer,
  customer: customerReducer,
  report: reportReducer,
  role: userRole,
  errorCustomer,
  reportDashboard,
  generateReport,
  currentReportReducer,
  sampleReport: sampleReportReducer,
  adsPreview: adsPreviewReducer,
  learningCenter: learningCenterReducer,
  learningCenterModalMessage: learningCenterMessageFromModal,
  weeklyReport,
  extraInsights,
  discoverAds: boardsReducer,
  UnAuthDiscoverAds: UnAuthDiscoverAdsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // eslint-disable-next-line
    state = undefined
  }
  return appreducer(state, action)
}

export default rootReducer
