import { Text } from '@components'
import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Input, Button, AutoComplete, Form, Spin, message, Divider } from 'antd'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { BRAND_OPTIONS, ADS_CHANNEL, FOLLOWED_BRANDS_LIST } from '@utils/constants'
import BrandsList from '@components/DiscoverAds/BrandsList'
import { RequestService } from '@services/requests'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import styles from './brands.module.css'

const { TabPane } = Tabs

const FollowingBrands = () => {
  const dispatch = useDispatch()

  const brandOptions = useSelector(state => state?.discoverAds?.brandOptions)
  const [isModalVisible, setModalVisible] = useState(false)
  const [facebookUrl, setFacebookUrl] = useState('')
  const [googleUrl, setGoogleUrl] = useState('')
  const [brandName, setBrandName] = useState('')
  const [brandSearchInput, setBrandSearchInput] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [manualValidation, setManualValidation] = useState({ name: '', fbUrl: '', gUrl: '' })
  const [brandsList, setBrandsList] = useState([])
  const [filteredBrands, setFilteredBrands] = useState(brandsList)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [addingBrand, setAddingBrand] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState('1')
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)

  const openAddBrandModal = () => {
    setModalVisible(true)
    setActiveTabKey('1')
  }

  const getbrands = async () => {
    try {
      const response = await RequestService.get('/save/brands/?is_combined=true')
      const newAllOptions = response?.data?.all_brands?.map(suggestion => ({
        value: suggestion?.brand_name,
        googleId: suggestion?.google_advertiser_id,
        metaId: suggestion?.meta_page_id,
        isFollowed: suggestion?.is_followed,
        id: suggestion?.id,
        key: `${suggestion?.brand_name}-${suggestion?.id}`, // Ensure unique key
        label: (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {suggestion?.brand_name}
            {suggestion?.is_followed && <CheckOutlined style={{ color: '#f16434' }} />}
          </div>
        ),
        disabled: suggestion?.is_followed, // Disable if already followed
      }))
      dispatch({ type: BRAND_OPTIONS, payload: newAllOptions })
    } catch (error) {
      // console.error('Error fetching brands:', error)
    }
  }

  const fetchFollowedBrands = async () => {
    try {
      setLoading(true)
      const response = await RequestService.get('/save/follow-brands/')
      const formattedBrands = response?.data.map((brand, index) => ({
        key: `${index + 1}`,
        name: brand?.brand_name,
        adCount: brand?.image_count + brand?.video_count + brand?.text_count,
        images: brand?.image_count,
        videos: brand?.video_count,
        text: brand?.text_count,
        lastUpdated: brand?.updated_at ? dayjs(brand?.updated_at).format('MMM D, YYYY') : null,
        pageId: brand?.page_id,
        googleId: brand?.google_advertiser_id,
        id: brand?.brand_id,
        logo: brand?.profile_picture_url,
      }))
      setBrandsList(formattedBrands)
      setFilteredBrands(formattedBrands)
    } finally {
      setLoading(false)
    }
  }
  const handleAddBrand = async () => {
    if (!brandSearchInput.trim()) {
      setValidationMessage('Please enter a brand name.')
      return
    }

    const selectedBrand = brandOptions?.find(brand => brand.value === brandSearchInput)
    if (!selectedBrand) {
      setValidationMessage('Your brand is not found in the list.Please add manually.')
      return
    }

    try {
      const data = {
        brand: selectedBrand?.id,
        is_followed: true,
      }
      setAddingBrand(true)
      await RequestService.post('/save/follow-brands/', data)
      fetchFollowedBrands()
      message.success('Brand Followed Successfully')
      getbrands()
    } finally {
      setAddingBrand(false)
      // setModalVisible(false)
      setBrandSearchInput('')
      setValidationMessage('')
    }
  }

  const handleAddBrandManually = async () => {
    // Validate Brand Name
    if (!brandSearchInput.trim()) {
      setManualValidation(prev => ({ ...prev, name: 'Brand name is required.' }))
      return
    }

    // Validate that at least one of the URLs is provided
    if (!facebookUrl.trim() && !googleUrl.trim()) {
      setManualValidation(prev => ({
        ...prev,
        fbUrl: 'URL is required.',
        // gUrl: 'At least one URL is required.',
      }))
      return
    }

    // Validate individual URLs
    if (facebookUrl.trim() && !facebookUrl.startsWith('https://www.facebook.com/')) {
      setManualValidation(prev => ({
        ...prev,
        fbUrl: 'Please enter a valid Facebook Page URL.',
      }))
      return
    }

    if (googleUrl.trim() && !googleUrl.startsWith('https://adstransparency.google.com/')) {
      setManualValidation(prev => ({
        ...prev,
        gUrl: 'Please enter a valid Google Transparency URL.',
      }))
      return
    }

    try {
      const data = {
        brand_name: brandSearchInput,
        meta_page_url: facebookUrl || null,
        google_advertiser_url: googleUrl || null,
      }
      setAddingBrand(true)
      await RequestService.post('/save/add-brand-manually/', data)
      message.success('Brand request sent successfully')
    } catch (error) {
      message.error('Failed to add brand. Please try again.')
    } finally {
      setAddingBrand(false)
      setFacebookUrl('')
      setGoogleUrl('')
      setBrandSearchInput('')
      setManualValidation({ name: '', fbUrl: '', gUrl: '' })
    }
  }

  const handleModalClose = () => {
    setModalVisible(false)
    setValidationMessage('')
    setManualValidation({ name: '', fbUrl: '', gUrl: '' })
    setBrandSearchInput('')
    setFacebookUrl('')
    setGoogleUrl('')
    setBrandName('')
  }

  useEffect(() => {
    fetchFollowedBrands()
  }, [])
  const handleBrandsSearch = event => {
    const value = event.target.value.toLowerCase() // Get input value
    setSearchTerm(value) // Update state for input value

    const filtered = brandsList.filter(
      brand => brand.name.toLowerCase().includes(value) // Case-insensitive filter
    )

    setFilteredBrands(filtered) // Update filtered list
  }

  useEffect(() => {
    if (brandOptions?.length <= 0) {
      getbrands()
    }
    // eslint-disable-next-line
  }, [brandOptions])
  useEffect(() => {
    dispatch({ type: ADS_CHANNEL, payload: 'All' })
    // eslint-disable-next-line
  }, [])
  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  useEffect(() => {
    if (!followedBrandsList) {
      getFollowedBrandsList()
    }
    // eslint-disable-next-line
  }, [followedBrandsList])

  const handleOk = () => {
    if (activeTabKey === '1') {
      handleAddBrand()
    } else if (activeTabKey === '2') {
      handleAddBrandManually()
    }
  }
  useEffect(() => {
    if (!brandSearchInput) {
      setValidationMessage('')
    }
  }, [brandSearchInput])
  return (
    <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <h1 className={styles.title} style={{ margin: 0 }}>
          Followed Brands
        </h1>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* width: '25%' */}
          <Input
            allowClear
            id="keywordSearchInput"
            className={styles.inputText}
            placeholder="Search brand"
            prefix={<img src={keywordSearchIcon} height={16} width={16} alt="search-icon" />}
            style={{ flex: 1, marginRight: '15px' }}
            onChange={handleBrandsSearch}
            value={searchTerm}
          />
          <Button type="primary" className={styles.createBoardBtn} onClick={openAddBrandModal}>
            + ADD BRAND
          </Button>
        </div>
      </div>

      <Modal
        title={
          // <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <div style={{ textAlign: 'end' }}>
              <CloseOutlined onClick={handleModalClose} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 22 }}>Add Brand</div>
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleModalClose}
        okText="Add"
        cancelText="Cancel"
        confirmLoading={addingBrand}
      >
        <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
          <TabPane tab="Search" key="1">
            <Form.Item validateStatus={validationMessage ? 'error' : ''} help={validationMessage}>
              <AutoComplete
                style={{ width: '100%' }}
                options={[
                  ...brandOptions,
                  {
                    value: '__manual_add__', // Unique value for custom option
                    label: (
                      <div style={{ color: '#f16434', fontWeight: 'bold' }}>
                        You can also add brand manually
                      </div>
                    ),
                  },
                ]}
                placeholder="Search brand name"
                value={brandSearchInput}
                onChange={value => {
                  if (value === '__manual_add__') {
                    // Open the modal and switch to the "Add Manually" tab
                    setActiveTabKey('2') // Switch to "Add Manually" tab
                    return
                  }
                  setBrandSearchInput(value)

                  // Clear error message when input is valid
                  setValidationMessage('')
                }}
                onSearch={inputValue => {
                  const matchingOptions = brandOptions.filter(option =>
                    option.value.toLowerCase().includes(inputValue.toLowerCase())
                  )

                  // Show error if no matching options exist (excluding custom option)
                  if (matchingOptions.length === 0 && inputValue !== '') {
                    setValidationMessage('Your brand is not found in the list.Please add manually.')
                  } else {
                    setValidationMessage('')
                  }
                }}
                filterOption={(inputValue, option) => {
                  // Ensure the custom option is always shown
                  if (option.value === '__manual_add__') {
                    return true
                  }
                  return option.value.toLowerCase().includes(inputValue.toLowerCase())
                }}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Add Manually" key="2">
            <Form.Item
              validateStatus={manualValidation.name ? 'error' : ''}
              help={manualValidation.name}
            >
              <Text>Brand Name</Text>
              <Input
                placeholder="Enter Brand Name"
                value={brandSearchInput}
                onChange={e => {
                  setBrandSearchInput(e.target.value)
                  setManualValidation(prev => ({ ...prev, name: '' }))
                }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={manualValidation.fbUrl ? 'error' : ''}
              help={manualValidation.fbUrl}
            >
              <Text>Facebook Page URL</Text>
              <Input
                placeholder="https://www.facebook.com/ads/library/?view_all_page_id=..."
                value={facebookUrl}
                onChange={e => {
                  setFacebookUrl(e.target.value)
                  setManualValidation(prev => ({ ...prev, fbUrl: '' }))
                }}
                // suffix={<ArrowDownOutlined />}
              />
            </Form.Item>
            {/* <Form.Item
              validateStatus={manualValidation.gUrl ? 'error' : ''}
              help={manualValidation.gUrl}
            >
              <Text>Google Transparency URL</Text>
              <Input
                placeholder="https://adstransparency.google.com/advertiser/AR02288030..."
                value={googleUrl}
                onChange={e => {
                  setGoogleUrl(e.target.value)
                  setManualValidation(prev => ({ ...prev, gUrl: '' }))
                }}
              />
            </Form.Item> */}
          </TabPane>
        </Tabs>
      </Modal>

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin />
        </div>
      ) : (
        <BrandsList brands={filteredBrands} setBrands={setFilteredBrands} />
      )}
    </div>
  )
}

export default FollowingBrands
