import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import ConnectGoogleAccountModal from '@pages/dashboard/Connect-account-modal'
import { logoutUser } from '../../../store/actions'

const DropdownMenu = ({ children, setCustomers }) => {
  const isCustomers = localStorage.getItem('isCustomers')
  const isInviterCustomers = localStorage.getItem('isInviterCustomers')
  const session = localStorage.getItem('np_ads_grader:session')
  const parsedData = JSON.parse(session)
  const provider = parsedData?.user
  let isGooglePresent = false
  if (provider?.social_accounts) {
    isGooglePresent = provider?.social_accounts.some(account => account?.provider === 'google')
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const userData = JSON.parse(localStorage.getItem('np_ads_grader:session'))
  const userEmail = userData?.user.email
  let items = []
  if (
    userEmail?.includes('npaccel.com') ||
    userEmail?.includes('npdigital.com') ||
    userEmail?.includes('neilpatel.com') ||
    userEmail?.includes('ubersuggest.com') ||
    userEmail?.includes('grayphite.com')
  ) {
    items = [
      {
        label: `Admin`,
        key: 'admin',
      },
      {
        label: `${t('settingsText')}`,
        key: 'settings',
      },
      {
        label: `${t('dashboardText')}`,
        key: 'dashboard',
      },
      {
        label: `${t('logoutText')}`,
        key: 'logOut',
      },
    ]
  } else {
    items = [
      {
        label: `${t('settingsText')}`,
        key: 'settings',
      },
      {
        label: `${t('dashboardText')}`,
        key: 'dashboard',
      },
      {
        label: `${t('logoutText')}`,
        key: 'logOut',
      },
    ]
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
  }

  const handleConnect = () => {}
  const handleMenuClick = async ({ key }) => {
    if (key === 'logOut') {
      dispatch(logoutUser())
      setCustomers([])
      history.push('/')
      localStorage.removeItem(' domain_flow')
      localStorage.removeItem('customer')
      localStorage.removeItem('isInviterCustomers')
      localStorage.removeItem('report-generating')
      localStorage.removeItem('selectAccount')
      localStorage.removeItem('loginThroughWebLink')
      localStorage.removeItem('inviteFrom')
      localStorage.removeItem('showPopover')
      localStorage.removeItem('copied_report_unique_id')
      localStorage.removeItem('url')
      localStorage.removeItem('leadForm')
      localStorage.removeItem('showForm')
      localStorage.removeItem('term')
      localStorage.removeItem('content')
      localStorage.removeItem('source')
      localStorage.removeItem('?medium')
      localStorage.removeItem('campaign')
      localStorage.removeItem('_gl')
      localStorage.removeItem('ads_grader_saved_utms')
      localStorage.removeItem('isSearchFeature')
      localStorage.removeItem('profilePic')
      localStorage.removeItem('share_Baord_Link')
      localStorage.removeItem('public_status')
      localStorage.removeItem('board_detail_id')
    } else if (key === 'dashboard') {
      if (
        !isGooglePresent &&
        (isInviterCustomers === 'false' || !isInviterCustomers) &&
        (isCustomers === 'false' || !isCustomers)
      ) {
        setIsModalVisible(true)
      } else {
        history.push('/dashboard')
      }
    } else if (key === 'admin') {
      // es-lint-disabled-next-line
      if (window.location.host === 'adsgrader.com') {
        window.open('https://np-ads-grader-api--prod.herokuapp.com/admin', '_blank')
      } else if (window.location.host === 'adsgrader-stage.netlify.app') {
        window.open('https://np-ads-grader-api.herokuapp.com/admin', '_blank')
      } else if (window.location.host === 'np-ads-grader-dev.netlify.app') {
        window.open('https://np-ads-grader-api.herokuapp.com/admin', '_blank')
      }
    } else {
      history.push(`/${key}`)
    }
  }

  return (
    <>
      <Dropdown
        style={{ cursor: 'pointer' }}
        menu={{ items, onClick: handleMenuClick }}
        trigger={['click']}
      >
        <Space>
          {children}
          <DownOutlined />
        </Space>
      </Dropdown>
      {isModalVisible && (
        <ConnectGoogleAccountModal
          visible={isModalVisible}
          onClose={handleClose}
          onConnect={handleConnect}
          detail="dashboard"
        />
      )}
    </>
  )
}

export default DropdownMenu
