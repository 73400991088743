import React, { useEffect, useState } from 'react'
import { Button, Carousel } from 'antd'
import LocationIcon from '@images/HomePage/Location.png'
import axios from 'axios'
import TopAd1 from '@images/DiscoverAds/TopAdsInRegion/TopAd1.png'
import TopAd2 from '@images/DiscoverAds/TopAdsInRegion/TopAd2.png'
import TopAd3 from '@images/DiscoverAds/TopAdsInRegion/TopAd3.png'
import TopAd4 from '@images/DiscoverAds/TopAdsInRegion/TopAd4.png'
import TopAd5 from '@images/DiscoverAds/TopAdsInRegion/TopAd5.png'
import TopAd6 from '@images/DiscoverAds/TopAdsInRegion/TopAd6.png'
import TopAd8 from '@images/DiscoverAds/TopAdsInRegion/TopAd8.png'
import TopAd7 from '@images/DiscoverAds/TopAdsInRegion/TopAd7.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CreateYourAccount from '@components/CreateYourAccount'
import {
  ADS_LOADER,
  TRIGGER_SEARCH,
  ADS_PAGINATION,
  SPINNER,
  ADS_NO_DATA,
  ADS_DATA,
  HAS_MORE,
  API_HITS_COUNT,
  ADS_SEARCH_KEYWORD,
  CLEAR_BRANDS_FLITER,
  EMPTY_ADS_PREVIEW_DATA,
  INPUT_VALUES,
  ACTIVE_KEY,
  ADS_CHANNEL,
  ACTIVE_ADS_COUNT,
  IN_ACTIVE_ADS_COUNT,
  CLEAR_STATE,
} from '@utils/constants'
import { axiosInstance } from '@services/requests'
import { useDispatch } from 'react-redux'
import { countryCodeToName } from '../../../../data/discoverData'
import styles from './TopAdsInRegion.module.css'

const TopAdsInRegion = () => {
  const [spin, setSpin] = useState(false)
  const dispatch = useDispatch()
  const [signUpModal, setSignUpModal] = useState(false)
  const getIP = async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      return data
    } catch (e) {
      return null
    }
  }
  const history = useHistory()
  const handleAdsApi = async () => {
    setSpin(true)
    dispatch({ type: ACTIVE_KEY, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: 'Burger' })
    dispatch({ type: INPUT_VALUES, payload: 'Burger' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
    dispatch({ type: ADS_CHANNEL, payload: 'All' })

    const data = await getIP()
    try {
      dispatch({
        type: CLEAR_STATE,
        payload: [],
      })
      dispatch({ type: SPINNER, payload: true })
      dispatch({ type: ADS_LOADER, payload: true })
      let response = []

      response = await axiosInstance.get(
        `ads-preview/competitors-prod-v1/?channels=[All]&search_term=burger&is_brand=${false}&num=${20}&page_id=''&advertiser_id=''&ip_address=${
          data?.ip
        }&ad_reached_countries=[""]`
      )
      dispatch({ type: API_HITS_COUNT, payload: response?.data?.result?.facebook_ads?.count })
      history.push('/discover-ads-landing-page')

      dispatch({ type: ADS_LOADER, payload: false })
      const formattedData = response?.data?.result?.facebook_ads?.data

      if (formattedData.length > 0) {
        dispatch({ type: IN_ACTIVE_ADS_COUNT, payload: response?.data?.inactive_count })
        dispatch({ type: ACTIVE_ADS_COUNT, payload: response?.data?.active_count })
        dispatch({
          type: ADS_DATA,
          payload: formattedData,
        })
        dispatch({ type: ADS_NO_DATA, payload: false })
      }

      if (response?.data?.result?.facebook_ads?.paging?.next && formattedData.length > 0) {
        dispatch({
          type: ADS_PAGINATION,
          payload: response?.data?.result?.facebook_ads?.paging?.next,
        })
        dispatch({
          type: HAS_MORE,
          payload: true,
        })
      }

      if (formattedData?.length === 0) {
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
      dispatch({ type: SPINNER, payload: false })
      setSpin(false)
    } catch (error) {
      setSpin(false)
      if (
        error.response.status === 429 &&
        error.response.data.msg === 'Daily search limit exceeded.'
      ) {
        dispatch({ type: TRIGGER_SEARCH, payload: true })
        setSignUpModal(true)
      }
      dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      dispatch({ type: ADS_NO_DATA, payload: true })
    }
  }
  const [countryName, setCountryName] = useState('')

  const getLocation = async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      const name = countryCodeToName[data?.country] || 'Unknown'
      setCountryName(name)
    } catch (e) {
      console.error('Error fetching location data:', e)
    }
  }

  useEffect(() => {
    getLocation()
  }, [])
  const handleOpenModal = () => {
    setSignUpModal(true)
  }

  const handleCloseModal = () => {
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    setSignUpModal(false)
  }
  const handleSignUpModal = () => {
    setSignUpModal(true)
  }

  const adImages = [TopAd1, TopAd2, TopAd3, TopAd4, TopAd5, TopAd6, TopAd7, TopAd8]

  return (
    <>
      <div className={styles.topAdsHeader}>
        <div className={styles.topAdsTitle}>
          Top <span className={styles.keyword}>”burger”</span> ads, worldwide!
        </div>
        <div className={styles.locationContainer}>
          <img src={LocationIcon} alt="location" className={styles.locationIcon} />
          <div className={styles.locationText}>
            <span className={styles.boldText}>Current Location:</span> {countryName}
          </div>
        </div>
      </div>
      <div className={styles.adsContainer}>
        <Carousel
          autoplay
          autoplaySpeed={1500}
          dots={false}
          slidesToShow={7} // Show 3 ads at a time
          slidesToScroll={2}
          infinite
          // style={{ margin: '10px 10px' }}
        >
          {adImages.map((ad, index) => (
            <div
              className={styles.carouselItem}
              role="button"
              tabIndex="0"
              onClick={handleAdsApi}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  // handleNavigation('register')
                }
              }}
            >
              <img src={ad} alt={`Ad ${index + 1}`} className={styles.adImage} />
            </div>
          ))}
        </Carousel>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button
          loading={spin}
          style={{ marginTop: 64, marginBottom: 35, fontWeight: 'bold' }}
          onClick={handleAdsApi}
        >
          VIEW ALL ADS
        </Button>
      </div>
      {signUpModal && (
        <CreateYourAccount isModalVisible={signUpModal} onCancel={handleCloseModal} />
      )}
    </>
  )
}

export default TopAdsInRegion
