/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Collapse, Spin } from 'antd'
import { useSelector } from 'react-redux'
import {
  AD_RELEVANCE_SECTION,
  CLICK_THROUGH_RATE_SECTION,
  COST_EVALUATION_SECTION,
  GOOGLE_ADS_BEST_PRACTICES_SECTION,
  IMPRESSION_SHARE_SECTION,
  KEYWORDS_MATCH_TYPE_SECTION,
  PERFORMANCE_SECTION,
  QUALITY_SCORE_SECTION,
  WASTED_SPEND_SECTION,
  ADS_PERFORMANCE_GRADE,
} from 'utils/constants'
import { RequestService } from 'services/requests'
import { useTranslation } from 'react-i18next'
import Calculator from 'pages/calculator'
import Benchmark from '@pages/benchmark'
import LearningCenter from '@pages/learning-center'
import { LS_KEY } from '@utils/constants'
import ReportHeader from '@components/Report/ReportHeader'
import { report } from '@pages/report-demo/demo-data'
import { scrollToElement } from '../../utils/helperFunctions'
import ReportPageTemporary from '.'

import styles from './sampleReport.module.scss'

const { Panel } = Collapse

const generateRoles = ['Admin', 'Account Manager', 'Owner']

const SampleReport = () => {
  const customer = useSelector(state => state.customer)
  const updatedReport = useSelector(state => state?.report)
  const [selectedTab, setSelectedTab] = useState('')
  const [reports, setReports] = useState([])
  const [currentReport, setCurrentReport] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(customer?.id ?? '')
  const [showGenerateButton, setShowGenerateButton] = useState(false)
  const [generateReportLoading, setGenerateReportLoading] = useState(false)
  const [currentSection, setCurrentSection] = useState(null)
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isBenchmarkOpen, setIsBenchmarkOpen] = useState(false)
  const [isLearningCenterOpen, setIsLearningCenterOpen] = useState(false)

  const [reportCustomerId, setReportCustomerId] = useState('')
  const [copiedName, setCopiedName] = useState('')
  const [copiedCustomerId, setCopiedCustomerId] = useState('')
  const [open, setOpen] = useState(['1'])

  const [loadError, setLoadError] = useState(false)

  const token = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
  const history = useHistory()
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')

  const sections = [
    { label: `${t('report.adsperformancegrade')}`, key: ADS_PERFORMANCE_GRADE },
    { label: `${t('report.performanceMetrics')}`, key: PERFORMANCE_SECTION },
    { label: `${t('report.tabs.costEvolution.title')}`, key: COST_EVALUATION_SECTION },
    { label: `${t('report.tabs.spend.title')}`, key: WASTED_SPEND_SECTION },
    { label: `${t('report.tabs.adRelevance.title')}`, key: AD_RELEVANCE_SECTION },
    { label: `${t('report.tabs.qualityScore.title')}`, key: QUALITY_SCORE_SECTION },
    { label: `${t('report.tabs.adComparisons.ctr')}`, key: CLICK_THROUGH_RATE_SECTION },
    { label: `${t('report.tabs.keywordsMatchType.title')}`, key: KEYWORDS_MATCH_TYPE_SECTION },
    { label: `${t('report.tabs.impShare.title')}`, key: IMPRESSION_SHARE_SECTION },
    { label: `${t('report.tabs.googleAdsBest.title')}`, key: GOOGLE_ADS_BEST_PRACTICES_SECTION },
    // { label: `${t('report.tabs.adsCalculator')}`, key: ADS_BEDGET_COST_CACULATOR },
  ]

  useEffect(() => {
    setIsLoading(true)
    setCopiedName(report?.data?.customer_descriptive_name)
    setCopiedCustomerId(report?.data?.customer_id)
    setReportCustomerId(report?.data?.customer_id)
    setCurrentReport(report?.data)
    setSelectedTab(report?.data?.id)
    setIsLoading(false)

    // ....................................................................................................................
    if (token) {
      setReports([])
      // setCurrentReport(null)
      setSelectedTab(report?.data?.id)
      setCurrentSection(null)
    }
    // ..............................................................................................................

    // eslint-disable-next-line
  }, [])

  const handleSelectUser = customerId => {
    setSelectedUserId(customerId)
  }

  const handleSectionClick = sectionId => {
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
    if (!shareBoardLink) {
      history.push('/dashboard')
    } else if (shareBoardLink) {
      history.push('/dashboard/boards')
    }
    setTimeout(() => {
      const el = document.getElementById(sectionId)
      scrollToElement(el)
      setCurrentSection(sectionId)
    }, 500)
  }
  const handleOpenConsulting = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      // Base URL with common parameters
      let url =
        'https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting'
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `&cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
      }

      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log('🚀 ~ getBenchmarks ~ e:', e)
    }
  }

  useEffect(() => {
    const loginThroughWebLinkLocalstorage = JSON.parse(localStorage.getItem('loginThroughWebLink'))
    if (loginThroughWebLinkLocalstorage) {
      const leadTest = JSON.parse(localStorage.getItem('lead_form'))
      if (leadTest && !shareBoardLink) {
        history.push('/dashboard')
      } else if (leadTest && shareBoardLink) {
        history.push('/dashboard/boards')
      }
      const selectaccountFromLocalstorage = JSON.parse(localStorage.getItem('selectAccount'))
      if (selectaccountFromLocalstorage) {
        if (selectaccountFromLocalstorage) {
          const customerFromLocalStorage = JSON.parse(localStorage.getItem('customer'))
          if (customerFromLocalStorage) {
            const generatingFromLocalstorage = JSON.parse(localStorage.getItem('report-generating'))
            if (!generatingFromLocalstorage && !leadTest) {
              history.push(`/generating/${selectaccountFromLocalstorage}`)
            }
          }
        }
      } else {
        // eslint-disable-next-line
        if (!JSON.parse(localStorage.getItem('errorCustomer')) && !leadTest) {
          history.push('/select-account')
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isCalculatorOpen, isBenchmarkOpen])

  const handleBenchMark = e => {
    e.preventDefault()
    setOpen([])
    history.push('/dashboard/benchmarks')
    setIsBenchmarkOpen(true)
    setIsCalculatorOpen(false)
    setIsLearningCenterOpen(false)
  }
  const handleCalculator = e => {
    e.preventDefault()
    setOpen([])
    history.push('/dashboard/calculator')
    setIsCalculatorOpen(true)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
  }
  const handleLearningCenter = e => {
    e.preventDefault()
    setOpen([])
    history.push('/dashboard/learning-center')
    setIsLearningCenterOpen(true)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
  }
  return (
    <div className={styles.container}>
      <div id="report_sample" className={`${styles.reportContainer} customScrollbar`}>
        {/* eslint-disable-next-line */}
        {selectedTab === 'Dashboard' ? (
          <div>{t('dashboardText')}</div>
        ) : // eslint-disable-next-line
        isCalculatorOpen ? (
          <div ref={containerRef}>
            <Calculator />
          </div>
        ) : // eslint-disable-next-line
        isBenchmarkOpen ? (
          <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
            <Benchmark />
          </div>
        ) : // eslint-disable-next-line
        isLearningCenterOpen ? (
          <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
            <LearningCenter />
          </div>
        ) : (
          // eslint-disable-next-line
          <div ref={containerRef}>
            {!isLoading && (
              <ReportHeader
                reports={reports}
                // setCurrentReport={setCurrentReport}
                currentReport={report?.data}
              />
            )}
            {selectedTab && !isLoading && (
              <>
                <ReportPageTemporary
                  selectedReportData={currentReport}
                  setCurrentSection={setCurrentSection}
                  reportDashboardPage="true"
                  copiedReportName={copiedName}
                  copiedCustomerId={copiedCustomerId}
                />
              </>
            )}
            {!selectedTab && !isLoading && loadError && (
              <div className={styles.selectReport}>
                <h1 className={styles.selectReportHeading}>{t('loadReportErrorText')}</h1>
                {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
              </div>
            )}
            {!selectedTab && !isLoading && !loadError && (
              <div className={styles.selectReport}>
                <h1 className={styles.selectReportHeading}>{t('noReportText')}</h1>
                {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
              </div>
            )}
            {isLoading && (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SampleReport
