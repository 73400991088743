import React from 'react'
import { Text } from '@components'
import RegisterIcon from '@images/HomePage/HowItWorks/RegisterYourself.png'
import KeywordIcon from '@images/HomePage/HowItWorks/KeywordSearch.png'
import ResultsIcon from '@images/HomePage/HowItWorks/GetResults.png'
import styles from './HowItworkd.module.css'

const HowItWorks = () => {
  const temp = 0
  return (
    <>
      <Text className={styles.keyFeatures}>
        <span style={{ color: '#F16434' }}> How</span> it Works?
      </Text>
      <Text className={styles.keyDetial}>
        See how our creative library can inspire and inform your success
      </Text>
      <div style={{ marginTop: 64 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <img src={RegisterIcon} alt="register-icon" />
            <Text isBold size={24} className={styles.heading}>
              Register Yourself
            </Text>
            <Text size={20} className={styles.detail}>
              Simply get yourself registered with a very simple process.
            </Text>
          </div>
          <div className={styles.lines}>---------------</div>
          <div>
            <img src={KeywordIcon} alt="register-icon" />
            <Text isBold size={24} className={styles.heading}>
              Search for a keyword
            </Text>
            <Text size={20} className={styles.detail}>
              Enter a keyword to find relevant ads, creative examples, or insights instantly.
            </Text>
          </div>
          <div className={styles.lines}>---------------</div>
          <div>
            <img src={ResultsIcon} alt="register-icon" />
            <Text isBold size={24} className={styles.heading}>
              Get results!
            </Text>
            <Text size={20} className={styles.detail}>
              View tailored ads and creatives that match your keyword.
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}
export default HowItWorks
