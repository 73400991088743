import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useRedirectStore } from '../../hooks/use-redirect-store'
import KeyFeatureCard from './components/KeyFeatureCard'
import { LS_KEY } from '../../utils/constants'
import FooterNewDesign from './components/FooterNewDesign'
import TopAdsInRegion from './components/TopAdsInRegion'
import HowItWorks from './components/HowItWorks'
import RunningGoogleAds from './components/RunningGoogleAds'
import DriveSuccess from './components/DriveSuccess'

const HomePage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  useRedirectStore({ autoRedirect: true })
  const { t } = useTranslation()
  const history = useHistory()
  const localStorageData = JSON.parse(localStorage.getItem(LS_KEY))

  useEffect(() => {
    const accessToken = localStorageData?.access_token
    if (accessToken) {
      history.push('/dashboard/creative-library')
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <DriveSuccess />
      <div style={{ marginTop: 160 }}>
        <KeyFeatureCard />
      </div>
      {/* <div style={{ marginTop: 160 }}>
        <HowItWorks />
      </div> */}
      <div style={{ background: '#F5F5F5', marginTop: 61, padding: '50px 0' }}>
        <RunningGoogleAds />
      </div>

      <div>
        <FooterNewDesign />
      </div>
    </>
  )
}

export default HomePage
