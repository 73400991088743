import React from 'react'
import { Modal, Button, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import styles from './RegistrationModal.module.css'

const { Text } = Typography

const RegistrationSuccesfulModal = ({ visible, onClose, onCancel }) => {
  const temp = 0
  return (
    <Modal
      title={
        <>
          <div
            style={{
              textAlign: 'center',
              marginTop: 30,
              fontSize: 24,
              fontWeight: '600',
            }}
          >
            Registration Successful
          </div>
          <div className={styles.divider} />
        </>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Text style={{ display: 'block', marginBottom: 20, textAlign: 'center' }}>
        Please Login to continue using our services.
      </Text>

      <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onCancel}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div>
      <Button
        type="primary"
        className={styles.searchButton}
        onClick={onClose}
        style={{ width: '100%' }}
      >
        Proceed
      </Button>
    </Modal>
  )
}

export default RegistrationSuccesfulModal
