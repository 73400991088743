import React from 'react'
import { Layout, Row } from 'antd'

import DiscoverAdsFeature from './discover-ads-feature'

const { Sider, Header, Content } = Layout

const UnauthenticatedDiscoverAds = () => {
  const temp = 0
  return (
    <div style={{ padding: 80 }}>
      <DiscoverAdsFeature />
    </div>
  )
}

export default UnauthenticatedDiscoverAds
